//IMPORT ACTIONS
import {
	CREATE_ACTIVITY,
	GET_ACTIVITE,
	SET_ACTIVITY_FORM_ACTION,
	GET_ALL_ACTIVITIES,
	GET_ALL_ACTIVITIES_LIST,
	DELETE_ACTIVITY_ID,
	SET_ACTIVITY,
	CLEAR_ACTIVITY,
	CLEAR_ACTIVITY_LIST,
	IMPORTATION_DONE,
} from "../actions/types";

const initialState = {
	activites: [],
	activiteActuelle: {},
	success: "",
	action: "",
};

const activitesReducer = (state = initialState, action) => {
	switch (action.type) {
		case DELETE_ACTIVITY_ID:
			return state;
		case GET_ACTIVITE:
			return {...state, activiteActuelle: action.payload};
		case CLEAR_ACTIVITY:
			return {...state, activiteActuelle: {}};
		case CLEAR_ACTIVITY_LIST:
			return {...state, activites: []};
		case GET_ALL_ACTIVITIES:
			return {...state, activites: action.payload};
		case GET_ALL_ACTIVITIES_LIST:
			return {...state, ...action.payload};
		case IMPORTATION_DONE:
			return {...state, activites: action.payload};
		case SET_ACTIVITY:
			return {...state, activiteActuelle: action.payload};
		case SET_ACTIVITY_FORM_ACTION:
			return {...state, action: action.payload};
		case CREATE_ACTIVITY:
			return {...state, activiteActuelle: action.payload};
		default:
			return state;
	}
};

export default activitesReducer;
