import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  RechercherEtablissementsParRegroupement,
  SetSelectionEtablissement
} from '../../store/actions/etablissementsActions';
import { ModifierActivite } from '../../store/actions/activitesActions';
import VisibilitySelect from './VisibilitySelect';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

class ActiviteModifierModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityList: [],
      regroupementsList: [],
      etablissementsList: [],

      selectedProgramme: '',
      selectedActivity: '',
      selectedTypeRegroupement: '',
      selectedRegroupement: '',
      selectedEtablissement: '',
      selectedRegion: '',
      redirect: false,
      changed: false,
    };
  }
  componentDidMount() {
    //GET ALL ACTIVITY TYPE
    document.addEventListener(
      visibilityChange,
      this.handleVisibilityChange,
      false
    );
    const updatedState = { ...this.state, activite: { ...this.props.activite } };
    this.setState(updatedState, () => {

      //Fill form with info
      this.fillWithInitialInfo();
    });
  }
  componentWillUnmount() {
    document.removeEventListener(
      visibilityChange,
      this.handleVisibilityChange
    );
  }

  componentDidUpdate(prevProps, prevState) {


    if (
      this.props.etablissements.selectionEtablissement !==
      prevProps.etablissements.selectionEtablissement
    ) {
      this.setState({ selectedEtablissement: this.props.etablissements.selectionEtablissement });
    }

    if (prevProps.etablissements !== this.props.etablissements) {
      const etablissementsList = this.props.etablissements.etablissements;

      this.setState(
        {
          etablissementsList,
        }
      );
    }

  }

  fillWithInitialInfo = () => {
    //* CHOIX DU PROGRAMME */
    const { programme, etablissement, region } = this.props.activite;
    this.props.RechercherEtablissementsParRegroupement(
      etablissement.regroupement.code_regroupement
    );

    if (this.props.activite.etablissements.length == 0 && this.props.activite.etablissement) {
      let etablissements = [];
      etablissements.push(this.state.activite.etablissement);
      let activite = { ...this.state.activite, etablissements }
      this.setState({ activite })
    }

    let etablissementAffiche = this.props.activite.etablissements[0] || this.state.activite.etablissement
    this.props.SetSelectionEtablissement(etablissementAffiche);

    this.setState(
      {
        selectedProgramme: programme.programme_code,
        selectedTypeRegroupement: etablissement.regroupement.type,
        selectedRegroupement: etablissement.regroupement.code_regroupement,
        selectedEtablissement: etablissement._id,
        selectedRegion: region._id,
      },
      () => {
        //ACTIVITE
        const activityList = this.getActivityList(programme.programme_code);
        const selectedActivity = programme._id;

        //REGROUPEMENT ET ETABLISSEMENT

        const regroupementsList = this.getRegroupementsList(
          etablissement.regroupement.type
        );
        this.setState({ activityList, selectedActivity, regroupementsList });
      }
    );
  };

  handleVisibilityChange = () => {
    if (document[hidden]) {
      return null;
    } else {
      this.changeSelectionTypeRegroupement(
        this.state.selectedTypeRegroupement
      );
    }
  };

  // ========== HANDLING FUNCTIONS ============= //
  onHandleSelect = e => {
    switch (e.currentTarget.name) {
      case 'programme':
        return this.changeSelectionProgramme(e.currentTarget.value);
      case 'activite':
        return this.changeSelectionActivite(e.currentTarget.value);
      case 'regroupementType':
        return this.changeSelectionTypeRegroupement(e.currentTarget.value);
      case 'regroupement':
        return this.changeSelectionRegroupement(e.currentTarget.value);
      case 'etablissement':
        return this.changeSelectionEtablissement(e.currentTarget.value);
      case 'region':
        return this.changeSelectionRegion(e.currentTarget.value);
      default:
        return this.onHandleInput(e);
    }
  };

  onHandleInput = e => {
    const { activite } = this.state;
    activite[e.currentTarget.name] = e.currentTarget.value;
    this.setState({ activite });
  };

  onHandleNumberChange = e => {
    const { value, min } = e.currentTarget;
    const { activite } = this.state;

    activite[e.currentTarget.name] = Math.max(+min, +value);
    this.setState({ activite });
  };

  onHandleDateInput = e => {
    const { activite } = this.state;
    if (!moment.utc(e.target.value).isValid()) {
      activite[e.currentTarget.name] = '';
    } else {
      activite[e.currentTarget.name] = moment
        .utc(e.currentTarget.value)
        .format('YYYY-MM-DD');
    }
    this.setState({ activite });
  };

  onHandleCheck = e => {
    const { activite } = this.state;
    activite[e.currentTarget.name] = e.currentTarget.checked;
    this.setState({ activite });
  };

  onHandleParticipants = e => {
    const { activite } = this.state;
    activite[e.currentTarget.name] = e.currentTarget.value;

    this.setState({ activite }, () => {
      const { activite } = this.state;
      const { nb_garcons = 0, nb_filles = 0 } = activite || {};
      activite.nb_total = parseInt(nb_garcons) + parseInt(nb_filles);
      this.setState({ activite });
    });
  };

  // ========== VIEW SECTIONS ============= //
  // PROGRAM & ACTIVITY
  changeSelectionProgramme = programme => {
    this.setState({ selectedProgramme: programme }, () => {
      const activityList = this.getActivityList(programme);
      this.setState({ activityList });
      activityList.length > 0 &&
        this.changeSelectionActivite(activityList[0]._id);
      this.reinitNbEquipes();
    });
  };

  changeSelectionActivite = id => {
    const activite = this.state.activite;
    activite.programme = id;
    this.setState({ selectedActivity: id, activite });
  };

  getActivityList = code => {
    const { typesActivites } = this.props.programmes;

    return typesActivites.filter(a => {
      return a.programme_code.toString() === code.toString();
    });
  };

  selectionActivite = () => {
    const { programmes } = this.props.programmes;
    const { activityList, selectedProgramme, selectedActivity } = this.state;

    const progOptions = programmes.map((p, index) => {
      return (
        <option key={index} value={p.programme_code}>{p.programme_nom}</option>
      );
    });
    const actOptions = activityList.map((a, index) => {
      return <option key={index} value={a._id}>{a.type_activite_nom}</option>;
    });

    return (
      <div className="row">
        <div className="col-md-12">
          <h3>Choix du programme</h3>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Programme</label>
            <select
              className="custom-select"
              name="programme"
              id="programme"
              value={selectedProgramme}
              onChange={this.onHandleSelect}
            >
              {progOptions}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Activité</label>
            <select
              className="custom-select"
              name="activite"
              id="activite"
              value={selectedActivity}
              onChange={this.onHandleSelect}
            >
              {actOptions}
            </select>
          </div>
        </div>
      </div>
    );
  };

  // ACTIVITY DESCRIPTION
  selectionDescription = () => {
    const {
      no_reference = '',
      date_debut = '',
      date_fin = '',
      duree = 0,
      nb_activites = 0,
      nb_visiteurs = 0,
      thematique = '',
      nom_animateur = '',
      visibilite = ""
    } = this.state.activite || {};
    const { selectedProgramme } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>Description de l'activité</h3>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="">No de référence</label>
            <input
              type="text"
              className="form-control"
              name="no_reference"
              id="no_reference"
              value={no_reference}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        {/*----------- */}
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Date de début</label>
            <input
              type="date"
              className="form-control"
              name="date_debut"
              id="date_debut"
              value={moment.utc(date_debut).format('YYYY-MM-DD')}
              onChange={this.onHandleDateInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Date de fin</label>
            <input
              type="date"
              className="form-control"
              name="date_fin"
              id="date_fin"
              value={moment.utc(date_fin).format('YYYY-MM-DD')}
              onChange={this.onHandleDateInput}
            />
          </div>
        </div>

        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="">Durée en heures d'une activité</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="duree"
              id="duree"
              value={duree}
              onChange={this.onHandleNumberChange}
            />
          </div>
        </div>

        {/*----------- */}
        <div className="col-md-4">
          {this.selectionNiveau()}
        </div>

        <div className="col-md-4">
          {this.selectionLangue()}
        </div>

        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Nombre d'activités</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_activites"
              id="nb_activites"
              value={nb_activites}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        {/*----------- */}
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Nombre de visiteurs</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_visiteurs"
              id="nb_visiteurs"
              value={nb_visiteurs}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <VisibilitySelect
          name="visibilite"
          label="Visibilité"
          onChange={this.onHandleInput}
          id="visibilite"
          addclass="col-md-6"
          value={visibilite}
        />
        {/**--------- */}
        {(selectedProgramme.toString() === '20' ||
          selectedProgramme.toString() === '90' ||
          selectedProgramme.toString() === '100') &&
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="thematique">
                Thématique
              </label>

              <input
                type="text"
                className="form-control"
                name="thematique"
                id="thematique"
                value={thematique}
                onChange={this.onHandleInput}
              />
            </div>
          </div>}
        {(selectedProgramme.toString() === '20' ||
          selectedProgramme.toString() === '60' ||
          selectedProgramme.toString() === '70' ||
          selectedProgramme.toString() === '90' ||
          selectedProgramme.toString() === '100') &&
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="nom_animateur">
                {selectedProgramme === '70'
                  ? "Nom de l'innovateur (ou organisme)"
                  : "Nom de l'animateur"}
              </label>

              <input
                type="text"
                className="form-control"
                name="nom_animateur"
                id="nom_animateur"
                value={nom_animateur}
                onChange={this.onHandleInput}
              />
            </div>
          </div>}
      </div>
    );
  };

  selectionNiveau = () => {
    const { niveaux } = this.props.niveauxScolaires;
    const { niveau_scolaire = '' } = this.state.activite || {};

    const niveauxOptions = niveaux.map((n, index) => {
      return <option key={index} value={n._id}>{n.niveau}</option>;
    });
    return (
      <div className="form-group">
        <label htmlFor="">Niveau scolaire</label>
        <select
          className="custom-select"
          name="niveau_scolaire"
          id="niveau_scolaire"
          value={niveau_scolaire && niveau_scolaire._id}
          onChange={this.onHandleSelect}
        >
          {niveauxOptions}
        </select>
      </div>
    );
  };

  selectionLangue = () => {
    const langues = [
      { value: 'francais', name: 'Français' },
      { value: 'anglais', name: 'Anglais' },
      { value: 'autre', name: 'Autre' },
    ];
    const options = langues.map(l => {
      return <option key={l.value} value={l.value}>{l.name}</option>;
    });

    const { langue = 'francais' } = this.state.activite || {};

    return (
      <div className="form-group">
        <label htmlFor="">Langue</label>
        <select
          className="custom-select"
          name="langue"
          id="langue"
          value={langue}
          onChange={this.onHandleSelect}
        >
          {options}
        </select>
      </div>
    );
  };

  // CONTACT INFO
  selectionContact = () => {
    const {
      responsable_courriel = '',
      responsable_prenom = '',
      responsable_nom = '',
      responsable_tel1 = '',
      responsable_tel2 = '',
    } = this.state.activite || {};
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>Personne contact au lieu de l'activité</h3>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Prénom</label>
            <input
              type="text"
              className="form-control"
              name="responsable_prenom"
              id="responsable_prenom"
              value={responsable_prenom}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Nom</label>
            <input
              type="text"
              className="form-control"
              name="responsable_nom"
              id="responsable_nom"
              value={responsable_nom}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        {/*----------- */}
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Courriel</label>
            <input
              type="text"
              className="form-control"
              name="responsable_courriel"
              id="responsable_courriel"
              value={responsable_courriel}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Tel 1</label>
            <input
              type="text"
              className="form-control"
              name="responsable_tel1"
              id="responsable_tel1"
              value={responsable_tel1}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Tel 2</label>
            <input
              type="text"
              className="form-control"
              name="responsable_tel2"
              id="responsable_tel2"
              value={responsable_tel2}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
      </div>
    );
  };

  // REGIONAL ORG
  selectionOrganisme = () => {
    const { regions } = this.props.auth.utilisateur;
    const { selectedRegion = '' } = this.state;

    const regionsOptions = regions.map((r, index) => {
      return <option key={index} value={r._id}>{r.nom}</option>;
    });
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>Lieu de l'activité</h3>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="">Organisme régional</label>
            <select
              className="custom-select"
              name="region"
              id="region"
              value={selectedRegion}
              onChange={this.onHandleSelect}
            >
              {regionsOptions}
            </select>
          </div>
        </div>
      </div>
    );
  };

  changeSelectionRegion = id => {
    const activite = this.state.activite;
    activite.region = id;

    this.setState({ selectedRegion: id }, () => {
      this.changeSelectionTypeRegroupement(
        this.state.selectedTypeRegroupement
      );
    });
  };

  selectEtablissement = e => {
    const id = e.target.selectedOptions[0].value;
    const etablissement = this.props.etablissements.etablissements.find((etablissement) => {
      return etablissement._id === id;
    })
    this.setState({ selection_etablissement: e.target.selectedOptions[0].value });
    this.props.SetSelectionEtablissement(etablissement);
  };
  // LOCATION
  selectionEtablissement = () => {
    const { typesRegroupement, selectionEtablissement } = this.props.etablissements;
    const {
      selectedTypeRegroupement,
      selectedRegroupement,
      regroupementsList,
      etablissementsList,
    } = this.state;

    const typesRegOptions = typesRegroupement.map((tr, index) => {
      return <option key={index} value={tr.type}>{tr.type}</option>;
    });
    const regroupementsOptions = regroupementsList.map((r, index) => {
      return <option key={index} value={r.code_regroupement}>{r.nom}</option>;
    });
    const etablissementsOptions = etablissementsList.map((e, index) => {
      return (
        <option
          key={index}
          value={e._id}
        >{`${e.nom} | ${e.contact_ville}`}</option>
      );
    });

    return (
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Types de regroupement</label>
            <select
              className="custom-select"
              name="regroupementType"
              id="regroupementType"
              value={selectedTypeRegroupement}
              onChange={this.onHandleSelect}
            >
              {typesRegOptions}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Regroupement</label>
            <select
              className="custom-select"
              name="regroupement"
              id="regroupement"
              value={selectedRegroupement}
              onChange={this.onHandleSelect}
            >
              {regroupementsOptions}
            </select>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Établissement</label>
            <select
              className="custom-select"
              name="etablissement"
              id="etablissement"
              value={selectionEtablissement._id}
              onChange={this.selectEtablissement}
            >
              {etablissementsOptions}
            </select>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <div
            className="btn btn-reseau"
            onClick={this.ajouterEtablissementAListe}
          >
            Ajouter le lieu à la liste
          </div>
        </div>
        <div className="col-md-12 row">
          <h5 className="my-2">Liste des établissements sélectionnés</h5>
          <div className="liste-etablissements col-md-12">
            {this.renderListeEtablissements()}
          </div>
        </div>
      </div>
    );
  };
  ajouterEtablissementAListe = () => {
    const { selectionEtablissement } = this.props.etablissements;
    const { etablissements } = this.state && this.state.activite;
    if (!etablissements.includes(selectionEtablissement)) {
      etablissements.push(selectionEtablissement);
      let activite = { ...this.state.activite, etablissements }
      this.setState({ activite })
    } else {
      console.log("élément déjà dans la liste");
    }
  }
  supprimerEtablissementListe = (e) => {
    const parent = e.target.closest(".etablissements-btn");
    const codeOrganisme = parent.id;

    let { etablissements } = this.state && this.state.activite;
    etablissements = etablissements.filter((element) => {
      return element.code_organisme !== codeOrganisme
    })

    let activite = { ...this.state.activite, etablissements }
    this.setState({ activite })
  }
  renderListeEtablissements = () => {
    if (!this.state.activite) {
      return
    }

    const { etablissements = [] } = this.state.activite;

    const elementsListe = etablissements.map((etablissement, index) => {
      return (<div key={index} id={etablissement.code_organisme} className="etablissements-btn pills" title={etablissement.region_admin}>{etablissement.nom} (#{etablissement.code_organisme})<span className="remove-btn fas fa-times-circle ml-2" onClick={this.supprimerEtablissementListe}></span></div>)
    })

    return elementsListe.length > 0 ? elementsListe : "Aucun élément sélectionné"
  }
  getRegroupementsList = type => {
    const { regroupements } = this.props.etablissements;
    return regroupements.filter(r => {
      return (
        r.type.toLowerCase() === type.toLowerCase() &&
        r.region.some(this.containsRegion)
      );
    });
  };

  containsRegion = region => {
    return region._id === this.state.selectedRegion;
  };

  changeSelectionTypeRegroupement = type => {
    this.setState({ selectedTypeRegroupement: type, changed: true }, () => {
      const regroupementsList = this.getRegroupementsList(type);
      const newCode =
        regroupementsList.length > 0 && regroupementsList[0].code_regroupement;
      this.props.RechercherEtablissementsParRegroupement(newCode);
      this.setState({ regroupementsList, selectedRegroupement: newCode });
    });
  };

  changeSelectionRegroupement = code => {
    this.setState({ selectedRegroupement: code, changed: true }, () => {
      this.props.RechercherEtablissementsParRegroupement(code);
    });
  };

  changeSelectionEtablissement = id => {
    const activite = this.state.activite;
    activite.etablissement = id;
    this.setState({ selectedEtablissement: id, activite });
  };

  // PARTICIPANTS
  selectionParticipants = () => {
    const { nb_enseignants = 0, adaptation_scolaire = false } = this.state
      .activite || {};
    return (
      <div className="row">
        <div className="col-md-12">
          <h3>Informations sur les participants</h3>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="">Nombre d'enseignants</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_enseignants"
              id="nb_enseignants"
              value={nb_enseignants}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="checkbox"
              name="adaptation_scolaire"
              id="adaptation_scolaire"
              checked={adaptation_scolaire}
              onChange={this.onHandleCheck}
            />
            <label className="form-check-label" htmlFor="adaptation_scolaire">
              Adaptation scolaire
            </label>
          </div>
        </div>
        {/*----------- */}
      </div>
    );
  };

  selectionGarconsFilles = () => {
    const { nb_garcons = 0, nb_filles = 0, nb_total = 0 } = this.state
      .activite || {};

    return (
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Nombre de garçons</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_garcons"
              id="nb_garcons"
              value={nb_garcons}
              onChange={this.onHandleParticipants}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Nombre de filles</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_filles"
              id="nb_filles"
              value={nb_filles}
              onChange={this.onHandleParticipants}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label htmlFor="">Nombre total de participants</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_total"
              id="nb_total"
              value={nb_total}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
      </div>
    );
  };

  selectionEquipes = () => {
    const { selectedProgramme } = this.state;
    if (['1', '3'].indexOf(selectedProgramme.toString()) !== -1) {
      return this.selectionDuo();
    } else if (['2'].indexOf(selectedProgramme.toString()) !== -1) {
      return this.selectionQuatuor();
    } else {
      return null;
    }
  };

  selectionDuo = () => {
    const { nb_solo = 0, nb_duo = 0 } = this.state.activite || {};

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="nb_solo">Nombre d'équipes solo</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_solo"
              id="nb_solo"
              value={nb_solo}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="nb_duo">Nombre d'équipes duo</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_duo"
              id="nb_duo"
              value={nb_duo}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
      </div>
    );
  };

  selectionQuatuor = () => {
    const { nb_solo = 0, nb_duo = 0, nb_trio = 0, nb_quatuor = 0 } = this.state
      .activite || {};

    return (
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="nb_solo">Nombre d'équipes solo</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_solo"
              id="nb_solo"
              value={nb_solo}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="nb_duo">Nombre d'équipes duo</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_duo"
              id="nb_duo"
              value={nb_duo}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="nb_trio">Nombre d'équipes trio</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_trio"
              id="nb_trio"
              value={nb_trio}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="nb_quatuor">Nombre d'équipes quatuor</label>
            <input
              type="number"
              min="0"
              className="form-control"
              name="nb_quatuor"
              id="nb_quatuor"
              value={nb_quatuor}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
      </div>
    );
  };

  reinitNbEquipes = () => {
    const { activite } = this.state;
    ['nb_solo', 'nb_duo', 'nb_trio', 'nb_quatuor'].map(elem => {
      activite[elem] = 0;
      return true;
    });
    activite.thematique = '';
    activite.nom_animateur = '';
    this.setState({ activite });
  };

  //COMMENTAIRES
  selectionNotes = () => {
    const { notes = '' } = this.state.activite || {};
    return (
      <div className="row">
        <div className="col-md-12">
          <div className="form-group">
            <label htmlFor="notes">Notes</label>
            <textarea
              className="form-control"
              name="notes"
              id="notes"
              rows="3"
              maxLength="125"
              value={notes}
              onChange={this.onHandleInput}
            />
          </div>
        </div>
      </div>
    );
  };

  //SEND UPDATE FORM
  updateForm = () => {
    this.props.ModifierActivite(this.state.activite);
  };
  //***************************** */
  render() {
    const { modification_date = '', modification_auteur = {} } = this.state.activite || {};
    const { prenom = '', nom = '' } = modification_auteur;

    return (
      <Fragment>
        <button
          type="button"
          className="btn btn-primary d-none"
          data-toggle="modal"
          data-target="#modalModifier"
          id="modalModifier-btn"
        />
        <div
          className="modal fade"
          id="modalModifier"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="modalModifierLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content ">
              <div className="modal-header">
                <h2 className="modal-title text-center" id="modalModifierLabel">
                  Modifier une activité
                </h2>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="closeModalBtn"
                  onClick={this.props.clearModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <hr />
              <div className="modal-body" id="modalModifier-body">
                <div className='row'>
                  <p className='col-md-12'><small>Dernière modification par {prenom} {nom}, le {moment.utc(modification_date).format('YYYY-MM-DD')}</small></p>
                </div>

                {/* SELECTION DE L'ACTIVITÉ */}
                {this.selectionActivite()}

                {/* DESCRIPTION DE L'ACTIVITÉ */}
                {this.selectionDescription()}

                <hr />

                {/* PERSONNE CONTACT */}
                {this.selectionContact()}

                <hr />

                {/* ORGANISME RÉGIONAL */}
                {this.selectionOrganisme()}

                {/* ÉTABLISSEMENT */}
                {this.selectionEtablissement()}

                <hr />
                {/* PARTICIPANTS */}
                {this.selectionParticipants()}
                {this.selectionGarconsFilles()}
                {this.selectionEquipes()}

                <hr />
                {this.selectionNotes()}
                {/*----------- */}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={this.updateForm}
                >
                  Enregistrer le changement
                </button>

                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                  onClick={this.props.clearModal}
                >
                  Annuler
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  message: state.message,
  errors: state.errors,
  regions: state.regions,
  programmes: state.programmes,
  niveauxScolaires: state.niveauxScolaires,
  etablissements: state.etablissements,
  activites: state.activites,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  RechercherEtablissementsParRegroupement,
  SetSelectionEtablissement,
  ModifierActivite,
})(ActiviteModifierModal);
