import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import {
  ModifierActivite,
  ResetActivite,
  RechercherActiviteParId,
} from '../../store/actions/activitesActions';
import {
  RechercherProgrammesTous,
  RechercherTypesParProgramme,
  SetSelectionProgramme,
  ClearSelectionProgramme,
  SetSelectionActivite,
  ClearSelectionActivite,
  ResetProgramme,
} from '../../store/actions/programmesActions';
import { RechercherRegionsTous } from '../../store/actions/regionsActions';
import { ClearMessage } from '../../store/actions/messageActions';
import { ClearErrors } from '../../store/actions/errorActions';

import moment from 'moment';

class ActiviteModifierForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activite: '',
      adaptation_scolaire: false,
      date_debut: moment().utc().format('DD-MM-YYYY'),
      date_fin: '',
      duree: 0,
      etablissement: '',
      etablissements: [],
      evaluation_recue: false,
      langue: 'francais',
      nb_activites: 1,
      nb_enseignants: 1,
      nb_garcons: 0,
      nb_filles: 0,
      nb_total: 0,
      nb_solo: 0,
      nb_duo: 0,
      nb_trio: 0,
      nb_quatuor: 0,
      nb_visiteurs: 0,
      no_reference: '',
      nom_animateur: '',
      niveau_scolaire: '',
      notes: '',
      premiere_participation: false,
      prix: 0,
      programme: '',
      region: '',
      regroupement: '',
      responsable_prenom: '',
      responsable_nom: '',
      responsable_courriel: '',
      responsable_tel1: '',
      responsable_tel2: '',
      thematique: '',
      type_regroupement: '',
      redirect: false,
    };
  }

  componentDidMount = () => {
    //Resets form action
    this.props.ResetActivite();
    //sets activite from redux
    this.props.RechercherActiviteParId(this.props.match.params[0]);
    this.props.RechercherProgrammesTous();
    this.props.RechercherRegionsTous();
  };

  componentWillUnmount = () => {
    this.props.ResetActivite();
    this.props.ClearErrors();
    this.props.ClearMessage();
  };

  componentDidUpdate = prevProps => { };

  onChange = e => {
    if (e.target.type === 'checkbox') {
      this.setState({ [e.target.name]: e.target.checked });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onDateChange = e => {
    this.setState({
      [e.target.name]: moment.utc(e.target.value).format('YYYY-MM-DD'),
    });
  };

  onSubmit = e => {
    e.preventDefault();
  };

  EnregistrerModification = () => { };

  render = () => {
    //REDIRECT
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/liste-activites" />;
    }

    return <div className="form-activite" />;
  };
}
const mapStateToProps = state => ({
  etablissements: state.etablissements,
  regions: state.regions,
  programmes: state.programmes,
  responsables: state.responsables,
  auth: state.auth,
  errors: state.errors,
  activites: state.activites,
  message: state.message,
});
export default connect(mapStateToProps, {
  ModifierActivite,
  ResetActivite,
  RechercherActiviteParId,
  RechercherProgrammesTous,
  RechercherRegionsTous,
  RechercherTypesParProgramme,
  SetSelectionProgramme,
  ClearSelectionProgramme,
  SetSelectionActivite,
  ClearSelectionActivite,
  ResetProgramme,
  ClearErrors,
  ClearMessage,
})(withRouter(ActiviteModifierForm));
