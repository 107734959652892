import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Nav from "./Nav";
class Header extends Component {
	render() {
		const isAuthenticated = this.props.auth.isAuthenticated;
		const isAdmin = this.props.auth.utilisateur.isAdmin;
		return (
			<div className="mb-3">
				<header className="App-header">
					<Link to="/accueil" className="app-logo-container">
						<img
							src="/images/LogoTechnoscience_avecSlogan_Blanc.png"
							alt=""
							className="App-logo"
						/>
					</Link>
					<div>
						<h1 className="text-center main">Application Statistiques</h1>
						{isAuthenticated && (
							<div className="row">
								<div className="col-md-12 text-center">
									<p>
										<small>
											Connecté en tant que{" "}
											{this.props.auth.utilisateur.prenom}{" "}
											{this.props.auth.utilisateur.nom}
											{isAdmin && " | Administrateur"}
										</small>
									</p>
								</div>
							</div>
						)}
					</div>
				</header>
				<Nav />
			</div>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth
});

export default connect(mapStateToProps)(Header);
