import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import {
    RechercherEcolesParRegions,
    SetSelectionEtablissement,
    ClearSelectionEtablissement,
    RechercherEtablissementsParRegroupement
} from "../../store/actions/etablissementsActions";
import {
    RechercherRegroupementDistinct,
    RechercherParTypeParRegions,
    RechercherRegroupementParId,
    SetTypeRegroupement,
    ClearSelectionRegroupement,
    SetSelectionRegroupement
} from "../../store/actions/regroupementsActions";
import isEmpty from "../../validation/isEmpty";

class EcolesSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recherche: "",
            rechercheAffichee: [],
            type_regroupement: "",
            selection_regroupement: "",
            selection_etablissement: "",
            error: this.props.error,
            popup: false
        };
    }

    componentDidMount = () => {
        this.props.ClearSelectionRegroupement();
        this.props.ClearSelectionEtablissement();
        this.props.RechercherRegroupementDistinct();
        if (this.props.etablissementSelectionne !== null && this.props.etablissementSelectionne !== undefined) {
            this.props.RechercherRegroupementParId(this.props.etablissementSelectionne.regroupement);
        }
    };

    FiltrerListe = () => {};

    componentDidUpdate = (prevProps, prevState) => {
        //IF MODIFING EXISTING ACTIVITY
        if (
            this.props.etablissements.regroupementActuel !== prevProps.etablissements.regroupementActuel &&
            !isEmpty(this.props.etablissements.regroupementActuel)
        ) {
            this.setState({ type_regroupement: this.props.etablissements.regroupementActuel.type });
            this.props.SetTypeRegroupement(this.props.etablissements.regroupementActuel.type);
            this.props.RechercherParTypeParRegions(this.props.etablissements.regroupementActuel.type, this.props.auth.utilisateur.regions);
        }

        if (this.props.etablissementSelectionne !== prevProps.etablissementSelectionne) {
            this.props.RechercherRegroupementParId(this.props.etablissementSelectionne.regroupement);
            this.props.SetSelectionRegroupement(this.props.etablissementSelectionne.regroupement);
        }

        if (this.props.etablissements.selectionTypeRegroupement !== prevProps.etablissements.selectionTypeRegroupement) {
            this.props.RechercherParTypeParRegions(this.props.etablissements.selectionTypeRegroupement, this.props.auth.utilisateur.regions);
        }

        if (this.props.etablissements.selectionRegroupement !== prevProps.etablissements.selectionRegroupement) {
            document.querySelector("#etablissement_select").disabled = document.querySelector("#etablissement_select") !== null ? false : "disabled";
            this.props.RechercherEtablissementsParRegroupement(this.props.etablissements.selectionRegroupement);
        }
        //If only one option, automatically select it
        if (prevProps.etablissements.regroupements !== this.props.etablissements.regroupements) {
            if (!isEmpty(this.props.etablissements.regroupementActuel)) {
                this.setState({ selection_regroupement: this.props.etablissements.regroupementActuel.code_regroupement });
                this.props.SetSelectionRegroupement(this.props.etablissements.regroupementActuel.code_regroupement);
            }
        }

        if (prevProps.etablissements.etablissements !== this.props.etablissements.etablissements) {
            if (!isEmpty(this.props.etablissements.regroupementActuel)) {
                this.setState({ selection_etablissement: this.props.etablissementSelectionne._id });
                this.props.SetSelectionEtablissement(this.props.etablissementSelectionne._id);
            }
        }
        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    };

    onChange = e => {
        this.setState({ recherche: e.target.value });
    };

    SelectType = e => {
        this.setState({ type_regroupement: e.target.value });
        this.props.SetTypeRegroupement(e.target.value);
    };

    SelectRegroupement = e => {
        this.props.ClearSelectionEtablissement();
        this.setState({ selection_regroupement: e.target.value });
        this.props.SetSelectionRegroupement(e.target.value);
    };

    SelectEtablissement = e => {
        const id = e.target.selectedOptions[0].value;
        const etablissement = this.props.etablissements.etablissements.find((etablissement) => {
            return etablissement._id === id;
        })
        this.setState({ selection_etablissement: e.target.selectedOptions[0].value });
        this.props.SetSelectionEtablissement(etablissement);
    };

    render() {
        const typesListe = this.props.etablissements.typesRegroupement.map((type, index) => {
            let nom = type.type;
            nom = nom.charAt(0).toUpperCase() + nom.substring(1);
            return (
                <option key={index} value={type.type}>
                    {nom}
                </option>
            );
        });

        const regroupementListe = this.props.etablissements.regroupements.map((element, index) => {
            return (
                <option key={element._id + index} value={element.code_regroupement}>
                    {element.nom}
                </option>
            );
        });

        const etablissementListe = this.props.etablissements.etablissements.map((element, index) => {
            return (
                <option key={element._id + index} value={element._id}>
                    {element.nom + "  ||  " + element.contact_ville}
                </option>
            );
        });

        return (
            <div className="form-group row mb-3 mt-3">
                {/*this.state.popup==="true" && <Redirect to="/ajouter-etablissement" />*/}
                <div className="col md-4">
                    <label htmlFor="regroupement_type_select">
                        <span className="lead">Catégorie</span>
                    </label>
                    <select id="regroupement_type_select" className="form-control" onChange={this.SelectType} value={this.state.type_regroupement}>
                        <option value="">Choisir un type de regroupement</option>
                        {typesListe}
                    </select>
                </div>
                <div className="col md-4">
                    <label htmlFor="regroupement_select">
                        <span className="lead">Regroupement</span>
                    </label>
                    <select
                        id="regroupement_select"
                        className="form-control"
                        onChange={this.SelectRegroupement}
                        value={this.state.selection_regroupement}
                        disabled={this.props.etablissements.regroupements.length === 0 && "disabled"}
                    >
                        <option value="" />
                        {regroupementListe}
                    </select>
                </div>
                <div className="col md-4">
                    <label htmlFor="etablissement_select">
                        <span className="lead">Établissement</span>
                    </label>
                    <select
                        id="etablissement_select"
                        className={classnames("form-control custom-select", {
                            "is-invalid": this.props.error
                        })}
                        onChange={this.SelectEtablissement}
                        value={this.state.selection_etablissement}
                        disabled="disabled"
                    >
                        <option value="" />
                        {etablissementListe}
                    </select>
                    {this.props.error && <div className="invalid-feedback">{this.props.error}</div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    regroupements: state.regroupements,
    etablissements: state.etablissements,
    auth: state.auth
});

export default connect(
    mapStateToProps,
    {
        RechercherEcolesParRegions,
        RechercherRegroupementDistinct,
        RechercherParTypeParRegions,
        RechercherEtablissementsParRegroupement,
        RechercherRegroupementParId,
        SetTypeRegroupement,
        SetSelectionRegroupement,
        SetSelectionEtablissement,
        ClearSelectionEtablissement,
        ClearSelectionRegroupement
    }
)(EcolesSelect);
