import React, {Component, Fragment} from 'react';
import DoughnutChart from './Template/DougnutChart';
import fileSaver from 'file-saver';
import canvasToBlob from 'canvas-toBlob';
export default class Participants extends Component {
  constructor (props) {
    super (props);
    this.state = {
      nb_total: 0,
      data: {
        labels: ['Filles', 'Garçons'],
        datasets: [
          {
            label: 'Nombre de filles/garçons',
            data: [19, 12],
            backgroundColor: ['rgb(34, 108, 172)', 'rgb(255, 152, 42)'],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        showTooltips: false,
        layout: {
          padding: 10,
        },
        plugins: {
          datalabels: {
            color: '#FFF',
            anchor: 'start',
            align: 'end',
            clamp: false,
            offset: 30,
            backgroundColor: context => {
              return context.dataset.backgroundColor;
            },
            clip: false,
            font: {
              size: 11,
              weight: 'bolder',
            },
            padding: 6,
            borderRadius: 25,
            borderWidth: 2,
            borderColor: '#FFF',
            textAlign: 'center',
            formatter: (value, context) => {
              return (
                context.chart.data.labels[context.dataIndex] +
                ':' +
                value +
                '\n' +
                (value / this.state.nb_total * 100).toFixed (0) +
                '%'
              );
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.updateData ();
  };
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.activites !== this.props.activites) {
      this.updateData ();
    }
  }
  convertToUTC = date => {
    return Date.UTC (
      date.getUTCFullYear (),
      date.getUTCMonth (),
      date.getUTCDate (),
      date.getUTCHours (),
      date.getUTCMinutes (),
      date.getUTCSeconds ()
    );
  };
  saveImage = () => {
    const canvas = document.querySelector ('#participants');
    canvas.toBlobHD (blob => {
      fileSaver.saveAs (blob, 'nbParticipantsParGenre.png');
    });
  };
  updateData = () => {
    let nb_garcons = 0;
    let nb_filles = 0;
    let nb_participants = 0;
    this.props.activites.map (activite => {
      nb_garcons += activite.nb_garcons;
      nb_filles += activite.nb_filles;
      nb_participants += activite.nb_total;
      return true;
    });

    const labels = ['Filles', 'Garçons'];
    const dataParGenre = [nb_filles, nb_garcons];

    const dataToUpdate = this.state.data;

    dataToUpdate.labels = labels;
    dataToUpdate.datasets[0].data = dataParGenre;

    this.setState ({data: dataToUpdate, nb_total: nb_participants});
  };
  render () {
    return (
      <Fragment>
        <h4 className="text-center">Participants</h4>
        <h6 className="text-center mb-3">
          Nombre total de participants: {this.state.nb_total}
          <span className="ml-5 btn btn-reseau" onClick={this.saveImage}>
            <i className=" fas fa-camera-retro" />
          </span>
        </h6>
        <DoughnutChart
          id="participants"
          data={this.state.data}
          options={this.state.options}
        />
      </Fragment>
    );
  }
}
