import {
  GET_ALL_PROGRAMMES,
  GET_TYPES_PROGRAMME,
  GET_ALL_TYPES_ACTIVITE,
  GET_ERRORS,
  SET_SELECTION_PROGRAMME,
  SET_SELECTION_ACTIVITE,
  CLEAR_SELECTION_PROGRAMME,
  CLEAR_SELECTION_ACTIVITE,
  RESET_PROGRAMME,
} from './types';
import axios from 'axios';

export const RechercherProgrammesTous = () => dispatch => {
  axios
    .get ('/api/programme/programme-distinct')
    .then (programmesListe => {
      dispatch ({type: GET_ALL_PROGRAMMES, payload: programmesListe.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
    });
};

export const RechercherTypeActivitesTous = () => dispatch => {
  axios
    .get ('/api/programme/tous')
    .then (programmesListe => {
      dispatch ({type: GET_ALL_TYPES_ACTIVITE, payload: programmesListe.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
    });
};

export const RechercherTypesParProgramme = programme => dispatch => {
  axios
    .get ('/api/programme/type-activite-par-programme', {
      params: {programmeCode: programme},
    })
    .then (typesListe => {
      dispatch ({type: GET_TYPES_PROGRAMME, payload: typesListe.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
    });
};

export const SetSelectionProgramme = (code, nom) => dispatch => {
  dispatch ({
    type: SET_SELECTION_PROGRAMME,
    payload: {code, nom},
  });
  dispatch ({
    type: CLEAR_SELECTION_ACTIVITE,
  });
  dispatch (RechercherTypesParProgramme (code));
};

export const ClearSelectionProgramme = () => dispatch => {
  dispatch ({
    type: CLEAR_SELECTION_PROGRAMME,
  });
};

export const SetSelectionActivite = id => dispatch => {
  dispatch ({
    type: SET_SELECTION_ACTIVITE,
    payload: id,
  });
};
export const SetProgrammeParNom = nom => dispatch => {
  axios
    .get ('/api/programme/rechercher', {params: {nom: nom}})
    .then (programme => {
      dispatch (
        SetSelectionProgramme (
          programme.programme_code,
          programme.programme_nom
        )
      );
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
    });
};

export const ClearSelectionActivite = () => dispatch => {
  dispatch ({
    type: CLEAR_SELECTION_ACTIVITE,
  });
};

export const ResetProgramme = () => dispatch => {
  dispatch ({
    type: RESET_PROGRAMME,
  });
};
