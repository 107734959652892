import React, {Component} from 'react';
import {connect} from 'react-redux';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import isEmpty from '../../validation/isEmpty';
import XLSX from 'xlsx';
import RappProgramme from './RappProgramme';
import {
  RechercherJeunesParPalier,
  SetExportationFormat,
  ExportationDone,
} from '../../store/actions/exportationsActions';
import {RechercherProgrammesTous} from '../../store/actions/programmesActions';
import {RechercherRegionsTous} from '../../store/actions/regionsActions';
import {ClearErrors} from '../../store/actions/errorActions';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

class ExpRapports extends Component {
  componentDidMount = () => {};
  componentWillUnmount = () => {
    this.props.ClearErrors ();
  };

  ExportJeunesParPalier = format => {
    this.props.SetExportationFormat (format);
    this.props.RechercherJeunesParPalier ();
  };

  FormatPDFJeunesParPalier = data => {
    const content = [];

    data.content.map (elem => {
      content.push ({text: elem.nom});
      content.push ({
        columns: [
          {width: '*', text: 'Nombre de garçons\n' + elem.garcons},
          {width: '*', text: 'Nombre de filles\n' + elem.filles},
          {width: '*', text: 'Nombre total de participants\n' + elem.total},
        ],
      });
      return true;
    });

    let docDefinition = {
      footer: function (currentPage, pageCount) {
        return currentPage.toString () + ' of ' + pageCount;
      },
      content: content,
    };
    this.SendPDF (docDefinition);
  };

  SendXSLX = data => {
    const file = this.ObjectToXLSX (data);
    this.Download (file, data.nom);
  };

  Download = (file, nom) => {
    const blob = new Blob ([file], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL (blob);
    const a = document.createElement ('a');
    a.setAttribute ('hidden', '');
    a.setAttribute ('href', url);
    a.setAttribute ('download', nom + '.xlsx');
    document.body.appendChild (a);
    a.click ();
    document.body.removeChild (a);
  };

  ObjectToXLSX = data => {
    //CREATE WORKBOOK
    const wb = XLSX.utils.book_new ();
    wb.Props = {
      Title: data.nom,
      Subject: 'Rapport statistiques',
      Author: 'Réseau Technoscience',
      CreatedDate: new Date (),
    };
    //CREATE WORKSHEET
    wb.SheetNames.push (data.nom);

    //ADD DATA TO SHEET
    const ws = XLSX.utils.json_to_sheet (data.content);

    //INCLUDE WORKSHEET TO WORKBOOK
    wb.Sheets[data.nom] = ws;

    //CREATE FILE
    const wbout = XLSX.write (wb, {bookType: 'xlsx', type: 'binary'});

    //CONVERT TO BINARY TO SEND
    const buf = new ArrayBuffer (wbout.length);
    const view = new Uint8Array (buf);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt (i) & 0xff;
    }
    return buf;
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.exportations !== this.props.exportations) {
      if (
        isEmpty (this.props.exportations.data) ||
        isEmpty (this.props.exportations.format)
      ) {
        return;
      }

      const exportation = this.props.exportations;
      switch (exportation.data.nom) {
        case 'Jeunes par palier':
          if (exportation.format === 'pdf')
            this.FormatPDFJeunesParPalier (exportation.data);
          if (exportation.format === 'xlsx') this.SendXSLX (exportation.data);
          break;
        default:
          break;
      }
    }
  };

  SendPDF = docDefinition => {
    pdfMake
      .createPdf (docDefinition)
      .download (`${Date ()} Rapport ${docDefinition.header}`, () => {
        return;
        //this.props.ExportationDone();
      });
  };

  render () {
    return (
      <div className="container">
        <div className="row mt-3">
          <h1>Exportations et rapports</h1>
        </div>
        <RappProgramme />

        {/*<div className="row mt-3">
                    <div className="col md-12 mb-3">
                        <h3>Autres rapports automatisés</h3>
                        <h4>Participants par paliers</h4>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col md-3">
                        <button className="btn btn-reseau btn-block" onClick={() => this.ExportJeunesParPalier("pdf")}>
                            Format PDF
                        </button>
                    </div>
                    <div className="col md-3">
                        <button className="btn btn-reseau btn-block" onClick={() => this.ExportJeunesParPalier("xlsx")}>
                            Format Excel
                        </button>
                    </div>
                    <div className="col md-6" />
                </div>*/}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  exportations: state.exportations,
  regions: state.regions,
  programmes: state.programmes,
  activites: state.activites,
});
export default connect (mapStateToProps, {
  RechercherJeunesParPalier,
  SetExportationFormat,
  ExportationDone,
  RechercherProgrammesTous,
  RechercherRegionsTous,
  ClearErrors,
}) (ExpRapports);
