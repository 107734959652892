import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { RechercherEcolesPubliquesTous } from "../../store/actions/etablissementsActions";
import { SetSpinner } from "../../store/actions/authActions";
import excelExportUtils from "../../utils/excelExportUtils";
import isEmpty from "../../validation/isEmpty";
class listeEcolesPubliques extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listeEtablissement: this.props.etablissements.etablissements,
			region: "",
			etablissementsFiltres: [],
			etablissementsNiveaux: [],
			etablissementsTries: [],
			etablissementsAffiches: [],
			niveaux: {
				prescolaire: true,
				primaire: true,
				secondaire: true,
				collegial: true,
				universitaire: true,
				adulte: true,
				professionnel: true
			},
			activeFilter: true

		};
	}

	ExportList = () => {
		const expData = {};
		expData.content = this.state.etablissementsTries.map(etablissement => {
			return {
				is_active: etablissement.is_active,
				region_admin: etablissement.region_admin,
				regroupement_code: etablissement.regroupement.code_regroupement,
				regroupement_type: etablissement.regroupement.type,
				regroupement_nom: etablissement.regroupement.nom,
				_id: etablissement._id,
				nom: etablissement.nom,
				code_organisme: etablissement.code_organisme,
				type_etablissement: etablissement.type_etablissement,
				contact_tel: etablissement.contact_tel,
				contact_adresse: etablissement.contact_adresse,
				contact_ville: etablissement.contact_ville,
				contact_province: etablissement.contact_province,
				contact_code_postal: etablissement.contact_code_postal,
				caract_niv_prescolaire: etablissement.caract_niv_prescolaire,
				caract_niv_primaire: etablissement.caract_niv_primaire,
				caract_niv_secondaire: etablissement.caract_niv_secondaire,
				caract_niv_collegial: etablissement.caract_niv_collegial,
				caract_niv_universitaire: etablissement.caract_niv_universitaire,
				caract_niv_professionnel: etablissement.caract_niv_professionnel,
				caract_niv_adulte: etablissement.caract_niv_adulte,
				caract_lang_francais: etablissement.caract_lang_francais,
				caract_lang_anglais: etablissement.caract_lang_anglais,
				caract_lang_autre: etablissement.caract_lang_autre,
				caract_nb_eleves_total: etablissement.caract_nb_eleves_total,
				indice_def_rang_decile_imse: etablissement.indice_def_rang_decile_imse,
				indice_def_imse: etablissement.indice_def_imse,
				indice_def_maj: etablissement.indice_def_maj
			};
		});

		if (isEmpty(expData.content)) {
			console.log("vide");
			//TODO: Error msg
			return;
		}
		const date = new Date();

		expData.name = `Écoles publiques - ${date.getDate()} ${date.getMonth() +
			1} ${date.getFullYear()}`;
		expData.subject = "Liste des écoles publiques";
		excelExportUtils.SendXSLX(expData);
	};

	componentDidMount = () => {
		this.props.RechercherEcolesPubliquesTous(this.props.auth.utilisateur.regions);
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (this.props.etablissements.etablissements !== prevProps.etablissements.etablissements) {
			this.setState({ listeEtablissement: this.props.etablissements.etablissements });
		}
	};

	SetSelect = e => {
		this.setState({ region: e.target.value }, () => {
			this.FilterEcoles();
		});
	};

	CheckNiveau = e => {
		const niveaux = this.state.niveaux;
		niveaux[e.target.name] = e.target.checked;
		this.setState({ niveaux });
		this.FilterEcoles();
	};

	FilterEcoles = () => {
		const etablissementsFiltres = this.state.listeEtablissement.filter(ecole => {
			return ecole.regroupement.region.includes(this.state.region);
		});

		this.setState({ etablissementsFiltres }, () => {
			this.setState({ etablissementsTries: etablissementsFiltres });
		});
	};

	FilterNiveaux = () => {
		const etablissementsFiltres = this.state.etablissementsFiltres.map(ecole => {
			for (let niveau in this.state.niveaux) {
				if (this.state.niveaux[niveau] === ecole["caract_niv_" + niveau]) {
					return ecole;
				}
			}
			return null;
		});

		this.setState({ etablissementsFiltres }, () => {
			this.setState({ etablissementsTries: etablissementsFiltres });
		});
	};

	ToggleDisplay = e => {
		this.button = e.currentTarget;
		this.button.classList.toggle("btn-outline-reseau", !this.state.activeFilter);
		this.button.classList.toggle("btn-info", this.state.activeFilter);
		this.setState({ activeFilter: !this.state.activeFilter });
	}


	Sort = e => {
		const order = e.target.dataset.order;
		const etablissementsFiltres = this.state.etablissementsFiltres;
		etablissementsFiltres.sort(this.Compare(e.target.name, order));
		this.setState({ etablissementsTries: etablissementsFiltres });
		e.target.dataset.order *= -1;
	};

	Compare = (property, order) => {
		return function (a, b) {
			if (a.is_active == false && b.is_active !== false) {
				return 1;
			}
			if (a.is_active !== false && b.is_active == false) {
				return -1;
			}

			if (property === "nom" || property === "contact_ville") {
				return a[property].localeCompare(b[property]) * order;
			}



			const elemA = a[property] !== null ? a[property] : null;
			const elemB = b[property] !== null ? b[property] : null;

			return (elemA === null) - (elemB === null) || (elemA - elemB) * order;
		};
	};

	OnSubmit = e => {
		e.preventDefault();
	};

	render() {
		const error = this.props.errors;
		const listeRegions = this.props.auth.utilisateur.regions.map((region, index) => {
			return (
				<option key={region._id} value={region._id}>
					{region.nom}
				</option>
			);
		});

		const listeEcoles = this.state.etablissementsTries.map((ecole, index) => {
			//Si le filter est actif et que l'école n'est pas active, on la cache
			//Si le filter est inactif et que l'école est active, on la cache
			let classActive = "";
			if (this.state.activeFilter && !ecole.is_active) {
				classActive = "d-none";
			} else if (!this.state.activeFilter && ecole.is_active) {
				classActive = "d-none";
			}

			const messageInactif = ecole.is_active !== false ? "" : <em> - École inactive</em>;

			return (
				<div className={"card " + classActive} key={"card" + ecole._id} data-active={ecole.is_active !== false}>
					<div
						className="card-header p-0 d-flex flew-row align-items-center justify-content-between"
						id={index}
					>
						<h5 className="mb-0 ">
							<button
								className="btn btn-link collapsed"
								type="button"
								data-toggle="collapse"
								data-target={"#ecole" + ecole._id}
								aria-expanded="true"
								aria-controls={"ecole" + ecole._id}
							>
								<small className={ecole.is_active !== false ? "" : "text-muted"}>
									<strong>{ecole.nom}</strong> - {ecole.contact_ville}
									&emsp;&emsp;|&emsp;&emsp;Commission scolaire{" "}
									{ecole.regroupement.nom}
									{ecole.is_active !== false ? "" : messageInactif}
								</small>
							</button>
						</h5>
						<small>
							<i className="fas fa-user" />{" "}
							{ecole.caract_nb_eleves_total !== null ? (
								<small className="px-2 font-weight-light">
									{ecole.caract_nb_eleves_total}
								</small>
							) : (
								<small className="px-2 font-weight-light">N/A</small>
							)}
							<small>
								<strong>IMSE</strong>
							</small>
							{ecole.indice_def_imse !== null ? (
								<small className="px-2 font-weight-light">
									{ecole.indice_def_imse}
								</small>
							) : (
								<small className="px-2 font-weight-light">N/A</small>
							)}
							{ecole.indice_def_rang_decile_imse !== null ? (
								<small className="px-2 font-weight-light">
									{ecole.indice_def_rang_decile_imse}
								</small>
							) : (
								<small className="px-2 font-weight-light">N/A</small>
							)}
						</small>
					</div>
					<div
						id={"ecole" + ecole._id}
						className="collapse"
						aria-labelledby={index}
						data-parent="#listeEcoles"
					>
						<div className="card-body d-flex flex-row">
							<div className="d-flex flex-column flex-grow-1">
								<h5>Code de l'établissement</h5>
								<div className="d-flex flex-row pl-3">{ecole.code_organisme}</div>
								<h5>Contact</h5>
								<div className="d-flex flex-row pl-3">
									<p className="px-2">
										<i className="fas fa-phone" />
										&nbsp;{ecole.contact_tel}
									</p>
									<p className="px-2">
										<i className="fas fa-id-card" />
										&nbsp;{ecole.contact_adresse}, &emsp;{ecole.contact_ville}
										&emsp; {ecole.contact_code_postal}
									</p>
								</div>

								<h5>Commission scolaire</h5>
								<div className="d-flex flex-row pl-3">
									<div className="px-2">
										<p className="font-weight-light">
											Commission scolaire {ecole.regroupement.nom} -{" "}
											{ecole.regroupement.code_regroupement}
										</p>
									</div>
								</div>
								<h5>Niveaux scolaires</h5>
								<div className="d-flex flex-row pl-3">
									{ecole.caract_niv_prescolaire ? (
										<p className="px-2 font-weight-light">Préscolaire</p>
									) : null}
									{ecole.caract_niv_primaire ? (
										<p className="px-2 font-weight-light">Primaire</p>
									) : null}
									{ecole.caract_niv_secondaire ? (
										<p className="px-2 font-weight-light">Secondaire</p>
									) : null}
									{ecole.caract_niv_collegial ? (
										<p className="px-2 font-weight-light">Collégial</p>
									) : null}
									{ecole.caract_niv_universitaire ? (
										<p className="px-2 font-weight-light">Universitaire</p>
									) : null}
									{ecole.caract_niv_adulte ? (
										<p className="px-2 font-weight-light">Ens. aux adultes</p>
									) : null}
									{ecole.caract_niv_professionnel ? (
										<p className="px-2 font-weight-light">Ens. professionnel</p>
									) : null}
								</div>
							</div>
							<div className="d-flex flex-column flex-shrink-1 ">
								<h6>Nombre d'élèves</h6>
								<div className="pl-3">
									{ecole.caract_nb_eleves_total !== null ? (
										<p className="px-2 font-weight-light">
											{ecole.caract_nb_eleves_total}
										</p>
									) : (
										<p className="px-2 font-weight-light">
											Information non disponible
										</p>
									)}
								</div>
								<h6>Langue d'enseignement</h6>
								<div className="d-flex flex-column pl-3">
									{ecole.caract_lang_francais && (
										<p className="px-2 font-weight-light">Français</p>
									)}
									{ecole.caract_lang_anglais && (
										<p className="px-2 font-weight-light">Anglais</p>
									)}
									{ecole.caract_lang_autre && (
										<p className="px-2 font-weight-light">Autre</p>
									)}
								</div>
								<h6>Indices de défavorisation</h6>
								<div className="d-flex flex-column pl-3">
									<p>Indice de milieu socio-économique (IMSE)</p>
									{ecole.indice_def_imse !== null ? (
										<p className="px-2 font-weight-light">
											{ecole.indice_def_imse}
										</p>
									) : (
										<p className="px-2 font-weight-light">
											Information non disponible
										</p>
									)}
									<p>Rang décile IMSE</p>
									{ecole.indice_def_rang_decile_imse !== null ? (
										<p className="px-2 font-weight-light">
											{ecole.indice_def_rang_decile_imse}
										</p>
									) : (
										<p className="px-2 font-weight-light">
											Information non disponible
										</p>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		});

		return (
			<div className="container p-3">
				<div className="row">
					<h1 className="col md-4 display-4">Liste des écoles publiques</h1>
				</div>
				<div className="row pl-3 py-3">
					<button className="btn btn-reseau" onClick={this.ExportList}>
						Exporter la liste affichée (Excel)
					</button>
					<small className="p-1">
						*L'IMSE (Indice de mesure socio-économique) permet de déterminer les écoles
						qui participent à la <em>Stratégie d’intervention Agir autrement</em>. Les
						écoles SIAA sont celles dont le rang décile de l’IMSE est 8, 9 ou 10. Les
						écoles SIAA représentent environ le tiers des écoles publiques du Québec.{" "}
						<a
							href="http://www.education.gouv.qc.ca/enseignants/aide-et-soutien/milieux-defavorises/agir-autrement/indice-de-milieu-socio-economique-imse/"
							target="_blank"
							rel="noopener noreferrer"
						>
							En savoir plus
						</a>
					</small>
				</div>
				<div className="row">
					<div className="col md-4">
						<label htmlFor="regionsSelect">Organismes régionaux</label>
						<form onSubmit={this.onSubmit}>
							<select
								className={classnames("form-control custom-select", {
									"is-invalid": error.regionsTab
								})}
								name="regionsSelect"
								id="regionsSelect"
								onChange={this.SetSelect}
								value={this.state.region}
							>
								<option value="">Choisissez une région</option>

								{listeRegions}
							</select>
							{error && <div className="invalid-feedback">{error.regionsTab}</div>}
						</form>
					</div>
				</div>

				<div className="accordion my-3" id="listeEcoles">
					{listeEcoles.length > 0 && (
						<div className="my-2">
							<h5>
								Astuce: Vous pouvez alterner le tri ascendant et descendant en
								réappuyant sur le bouton de tri.
							</h5>
							<button
								className="btn btn-outline-reseau mx-2 my-1"
								name="nom"
								data-order="1"
								onClick={this.Sort}
							>
								Trier par nom
							</button>
							<button
								className="btn btn-outline-reseau mx-2 my-1"
								name="contact_ville"
								data-order="1"
								onClick={this.Sort}
							>
								Trier par ville
							</button>
							<button
								className="btn btn-outline-reseau mx-2 my-1"
								name="code_organisme"
								data-order="1"
								onClick={this.Sort}
							>
								Trier par commission scolaire
							</button>
							<button
								className="btn btn-outline-reseau mx-2 my-1"
								name="caract_nb_eleves_total"
								data-order="1"
								onClick={this.Sort}
							>
								Trier par nombre d'élèves
							</button>
							<button
								className="btn btn-outline-reseau mx-2 my-1"
								name="indice_def_rang_decile_imse"
								data-order="1"
								onClick={this.Sort}
							>
								Trier par indice de mesure socio-économique (IMSE)
							</button>
							<button className="btn btn-outline-reseau mx-2" name="toggleActiveSchool" data-order="1" onClick={this.ToggleDisplay}>
								Afficher les écoles inactives
							</button>
						</div>
					)}

					{listeEcoles}
				</div>

				{this.props.errors.msg && (
					<div className="row mt-1 mb-1">
						<div className="col mx-3 alert alert-danger" role="alert">
							{this.props.errors.msg}
							<button
								onClick={this.props.ClearErrors}
								type="button"
								className="close"
								data-dismiss="alert"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProp = state => ({
	etablissements: state.etablissements,
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProp,
	{ RechercherEcolesPubliquesTous, SetSpinner }
)(listeEcolesPubliques);
