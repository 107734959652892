import { IMPORTATION_DONE, GET_ERRORS, SET_SPINNER } from "./types";
import axios from "axios";

export const ImporterAncienSysteme = file => dispatch => {
    dispatch({ type: SET_SPINNER, payload: true });
    return axios
        .post("/api/importation/donnees-ancien-systeme", file, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then(resultat => {
            dispatch({ type: IMPORTATION_DONE, payload: resultat.data });
            dispatch({ type: SET_SPINNER, payload: false });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, payload: err.response.data });
            dispatch({ type: SET_SPINNER, payload: false });
        });
};
