import React, {Component} from 'react';
import {Line} from 'react-chartjs-3';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default class LineChart extends Component {
  render () {
    return (
      <Line
        id={this.props.id}
        data={this.props.data}
        height={this.props.height}
        width={this.props.width}
        options={this.props.options}
      />
    );
  }
}
