import {
    GET_REGROUPEMENT_DISTINCT,
    GET_SCHOOLBOARDS_BY_REGION,
    SET_SELECTION_REGROUPEMENT,
    CLEAR_SELECTION_REGROUPEMENT,
    SET_SELECTION_TYPE_REGROUPEMENT,
    GET_REGROUPEMENT_TYPE_REGION,
    GET_REGROUPEMENT_REGION,
    CLEAR_SELECTION_ETABLISSEMENT,
    GET_REGROUPEMENT_ID,
    GET_ERRORS
} from "./types";
import axios from "axios";
import isEmpty from "../../validation/isEmpty";
export const RechercherCommissionsScolairesRegion = regionId => dispatch => {
    axios
        .get("/api/regroupement/region/type", { params: { regionId, type: "commission scolaire" } })
        .then(resultat => {
            dispatch({ type: GET_SCHOOLBOARDS_BY_REGION, payload: resultat.data });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
        });
};

export const RechercherRegroupementDistinct = () => dispatch => {
    axios
        .get("/api/regroupement/regroupement-distinct")
        .then(resultat => {
            dispatch({ type: GET_REGROUPEMENT_DISTINCT, payload: resultat.data });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
        });
};

export const RechercherRegroupementParId = regroupementId => dispatch => {
    axios
        .get("/api/regroupement/id", { params: { regroupementId } })
        .then(resultat => {
            dispatch({ type: GET_REGROUPEMENT_ID, payload: resultat.data });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
        });
};

export const RechercherParTypeParRegions = (type, regions) => async dispatch => {
    const tab = regions.map(region => {
        return axios.get("/api/regroupement/region/type", { params: { regionId: region._id, type } }).then(regroupements => {
            if (!isEmpty(regroupements.data)) {
                return regroupements.data;
            } else {
                return [];
            }
        });
    });

    await Promise.all(tab)
        .then(regroupements => {
            let merged = [].concat.apply([], regroupements);
            dispatch({ type: GET_REGROUPEMENT_TYPE_REGION, payload: merged });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err || err.response.data });
        });
};

export const RechercherRegroupementsParRegions = regions => async dispatch => {
    //Scroll through all regions
    const regionsIdsTab = regions.map(region => {
        return region._id;
    });

    return axios
        .get("/api/regroupement/regions", { params: { regions: regionsIdsTab } })
        .then(resultat => {
            dispatch({ type: GET_REGROUPEMENT_REGION, payload: resultat.data });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
        });
};

export const SetTypeRegroupement = type => dispatch => {
    dispatch({
        type: SET_SELECTION_TYPE_REGROUPEMENT,
        payload: type
    });
    dispatch({
        type: CLEAR_SELECTION_REGROUPEMENT
    });
};

export const SetSelectionRegroupement = regroupement_code => dispatch => {
    dispatch({
        type: SET_SELECTION_REGROUPEMENT,
        payload: regroupement_code
    });
    dispatch({
        type: CLEAR_SELECTION_ETABLISSEMENT
    });
};

export const ClearSelectionRegroupement = () => dispatch => {
    dispatch({
        type: CLEAR_SELECTION_REGROUPEMENT
    });
};
