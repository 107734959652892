import React, { Component } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store/store';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import setAuthHeader from './utils/setAuthHeader';
import jwt_decode from 'jwt-decode';
import { setCurrentUser, logoutUser } from './store/actions/authActions';

//Structure
import Spinner from './components/common/Spinner';
import Header from './components/pages/partials/Header';
import Footer from './components/pages/partials/Footer';

//PAGES APP
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import Accueil from './components/pages/Accueil';
import Connexion from './components/auth/Connexion';
import AjoutUtilisateurForm from './components/admin/AjoutUtilisateurForm';
import ModifierUtilisateurForm
  from './components/admin/ModifierUtilisateurForm';
import ListeUtilisateurs from './components/admin/ListeUtilisateurs';
import InitMotDePasse from './components/admin/InitMotDePasse';
import OubliMdp from './components/admin/OubliMdp';
import ReinitMdp from './components/admin/ReinitiMdp';

import ActiviteAjoutForm from './components/activites/ActiviteAjoutForm';
import ActiviteModifierForm from './components/activites/ActiviteModifierForm';
import AffichageActivites from './components/activites/ActivitesListe';
import Etablissements from './components/pages/Etablissements';
import Page404 from './components/pages/page404';
import ExportationRapports from './components/exportations/ExpRapports';
import Rapports from './components/exportations/Rapports';
import BilanHQ from './components/exportations/ExpBilanHq';
import ImportationDonnees
  from './components/importations/importationAncienSysteme';

import ListeEcolesPubliques
  from './components/etablissements/listeEcolesPubliques';
import ListeEcolesPrivees from './components/etablissements/listeEcolesPrivees';
import ListeBibliotheques from './components/etablissements/listeBibliotheques';
import ListeMunicipalites from './components/etablissements/listeMunicipalites';
import AjouterEtablissementForm
  from './components/etablissements/AjouterEtablissementForm';
import AjouterEcoleForm
  from './components/etablissements/AjouterEcoleForm';

import MiseAJourImse from './components/admin/MiseAJourIMSE';

import Instructions from './components/pages/Instructions';

//if there is a token already in localstorage,
//Allows to keep users info if page reloads
if (localStorage.jwtToken) {
  //Set authorization header for each request
  setAuthHeader(localStorage.jwtToken);
  //Decode user's token
  const decoded = jwt_decode(localStorage.jwtToken);
  //Set current user and authentication
  store.dispatch(setCurrentUser(decoded));

  //Checks expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    //Redirects
    window.location.href = '/connexion';
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Spinner />
            <Header />
            <div>
              <Switch>
                <Route exact path="/" component={Connexion} />
                <Route exact path="/oubli-mdp" component={OubliMdp} />
                <Route
                  exact
                  path="/reinitialisation-mdp/*"
                  component={ReinitMdp}
                />
                <Route
                  exact
                  path="/modification-mdp/*"
                  component={InitMotDePasse}
                />
                <Route
                  exact
                  path="/liste-etablissements"
                  component={Etablissements}
                />
                <Route exact path="/connexion" component={Connexion} />

                <PrivateRoute exact path="/accueil" component={Accueil} />
                <PrivateRoute
                  exact
                  path="/liste-activites"
                  component={AffichageActivites}
                />
                <PrivateRoute
                  exact
                  path="/ajouter-activite"
                  component={ActiviteAjoutForm}
                />
                <PrivateRoute
                  exact
                  path="/modifier-activite/*"
                  component={ActiviteModifierForm}
                />

                <PrivateRoute
                  exact
                  path="/liste-ecoles-publiques"
                  component={ListeEcolesPubliques}
                />
                <PrivateRoute
                  exact
                  path="/liste-ecoles-privees"
                  component={ListeEcolesPrivees}
                />
                <PrivateRoute
                  exact
                  path="/liste-bibliotheques"
                  component={ListeBibliotheques}
                />
                <PrivateRoute
                  exact
                  path="/liste-municipalites"
                  component={ListeMunicipalites}
                />
                <PrivateRoute
                  exact
                  path="/ajouter-etablissement"
                  component={AjouterEtablissementForm}
                />

                <AdminRoute
                  exact
                  path="/ajouter-utilisateur"
                  component={AjoutUtilisateurForm}
                />
                <AdminRoute
                  exact
                  path="/modifier-utilisateur/*"
                  component={ModifierUtilisateurForm}
                />
                <AdminRoute
                  exact
                  path="/liste-utilisateurs"
                  component={ListeUtilisateurs}
                />
                <AdminRoute
                  exact
                  path="/ajouter-ecole"
                  component={AjouterEcoleForm}
                />
                <PrivateRoute
                  exact
                  path="/exportation"
                  component={ExportationRapports}
                />
                <PrivateRoute exact path="/rapports" component={Rapports} />
                <PrivateRoute exact path="/bilan-hq" component={BilanHQ} />
                <PrivateRoute
                  exact
                  path="/maj-imse"
                  component={MiseAJourImse}
                />
                <PrivateRoute
                  exact
                  path="/instructions"
                  component={Instructions}
                />
                <Route exact path="/*" component={Page404} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
