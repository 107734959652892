import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import { ClearMessage, SetMessage } from "../../store/actions/messageActions";
import { ClearErrors } from "../../store/actions/errorActions";
import { EnvoyerCourrielMdpReinit } from "../../store/actions/adminActions";

class OubliMdp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			courriel: "",
			redirect: false
		};
	}

	componentWillUnmount = () => {
		this.props.ClearMessage();
		this.props.ClearErrors();
	};

	onSubmit = e => {
		e.preventDefault();
		this.props.EnvoyerCourrielMdpReinit(this.state.courriel);
		window.setTimeout(() => {
			this.setState({ redirect: true });
		}, 3000);
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const redirect = this.state.redirect && <Redirect to="/" />;
		const errors = this.props.errors;
		const message = this.props.message.message && (
			<div className="alert alert-info text-center">{this.props.message.message}</div>
		);

		return (
			<div className="container pb-4 pt-4">
				{message}
				{redirect}
				<div className="row">
					<div className="col-md-12 text-center">
						<h1>Réinitialisation du mot de passe</h1>
					</div>
					<div className="col-md-12 text-center">
						<p className="lead">
							Veuillez saisir le courriel associé au compte. Un courriel vous sera
							envoyé avec le lien de réinitialisation
						</p>
					</div>
				</div>

				<form onSubmit={this.onSubmit} autoComplete="off">
					<input
						id="username"
						style={{ display: "none" }}
						type="text"
						name="fakeusernameremembered"
					/>
					<input
						id="password"
						style={{ display: "none" }}
						type="password"
						name="fakepasswordremembered"
					/>

					<div className="form-group">
						<label htmlFor="courriel">Courriel</label>
						<input
							autoComplete="nope"
							type="email"
							id="courriel"
							name="courriel"
							onChange={this.onChange}
							aria-describedby="aideCourriel"
							placeholder="Inscrire le courriel"
							className={classnames("form-control", {
								"is-invalid": errors.courriel
							})}
							value={this.state.courriel}
						/>
						{errors.courriel && (
							<div className="invalid-feedback">{errors.courriel}</div>
						)}
					</div>
					<div className="row">
						<div className="mx-auto col-md-4">
							<input
								className="btn btn-reseau btn-block"
								type="submit"
								value="Envoyer"
							/>
						</div>
					</div>
				</form>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	errors: state.errors,
	message: state.message
});

export default withRouter(
	connect(
		mapStateToProps,
		{ ClearMessage, SetMessage, ClearErrors, EnvoyerCourrielMdpReinit }
	)(OubliMdp)
);
