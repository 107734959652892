import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { RechercherUtilisateursTous, SupprimerUtilisateur } from "../../store/actions/adminActions";
class ListeUtilisateurs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount = () => {
        this.props.RechercherUtilisateursTous();
    };
    supprimerUtilisateur = e => {
        this.props.SupprimerUtilisateur(e.target.dataset.id);
    };

    render() {
        const liste = this.props.utilisateur.utilisateurs.map(utilisateur => {
            return (
                <div key={utilisateur._id} className="list-group-item list-group-item-action flex-column align-items-start">
                    <div className="d-flex justify-content-between">
                        <h5 className="mb-1">
                            {utilisateur.prenom} {utilisateur.nom}
                        </h5>
                        <div>
                            <Link to={`/modifier-utilisateur/${utilisateur._id}`}>
                                <i data-id={utilisateur._id} className="fas fa-edit p-3 icons" />
                            </Link>
                            <Link to="#">
                                <i data-id={utilisateur._id} className="fas fa-trash p-3 icons" onClick={this.supprimerUtilisateur} />
                            </Link>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="container mb-3">
                <div className="row">
                    <div className="col-md-6">
                        <h1>Utilisateurs</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col md-10">
                        <p className="lead">Liste des utilisateurs</p>
                    </div>
                    <div className="col md-2 text-right">
                        <Link to="/ajouter-utilisateur">
                            <i className="fas fa-plus-circle fa-2x text-reseau" />
                        </Link>
                    </div>
                </div>
                <div className="list-group">{liste}</div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    utilisateur: state.utilisateur
});

export default connect(
    mapStateToProps,
    { RechercherUtilisateursTous, SupprimerUtilisateur }
)(ListeUtilisateurs);
