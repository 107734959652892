//IMPORT ACTIONS
import { ADD_NEW_USER, GET_ALL_USERS, DELETE_USER, GET_USER, CLEAR_USER, UPDATE_USER } from "../actions/types";

const initialState = {
    utilisateur: {},
    utilisateurs: []
};

const utilisateurReducer = (state = initialState, action) => {
    switch (action.type) {
        case DELETE_USER:
            return { ...state, utilisateur: action.payload };
        case GET_ALL_USERS:
            return { ...state, utilisateurs: action.payload };
        case GET_USER:
            return { ...state, utilisateur: action.payload };
        case UPDATE_USER:
            return { ...state, utilisateur: action.payload };
        case CLEAR_USER:
            return { ...state, utilisateur: {} };
        case ADD_NEW_USER:
            return { ...state, utilisateur: action.payload };
        default:
            return state;
    }
};

export default utilisateurReducer;
