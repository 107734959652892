import React, { Component } from "react";
import { connect } from "react-redux";
import { RechercherRegionsTous } from "../../store/actions/regionsActions";
import classnames from "classnames";

class RegionSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionSelectionnee: this.props.value,
            name: this.props.name,
            label: this.props.label,
            id: this.props.id,
            addclass: this.props.addclass,
            options: [],
            error: this.props.error,
            onChange: this.props.onChange,
            info: null
        };
    }

    componentDidMount() {
        this.setState({ options: this.props.auth.utilisateur.regions });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.auth.utilisateur.regions !== this.props.auth.utilisateur.regions) {
            this.setState({ options: this.props.auth.utilisateur.regions });
        }
        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    }

    render() {
        //Creates list of option tag
        const optionsList = this.state.options.map((option, index) => {
            let isSelected = false;
            option._id === this.state.defaultValue ? (isSelected = "selected") : (isSelected = null);
            return (
                <option key={index} value={option._id} selected={isSelected}>
                    {option.nom}
                </option>
            );
        });

        return (
            <div className={this.state.addclass}>
                <label htmlFor={this.state.id}>{this.state.label}</label>
                <select
                    name={this.state.name}
                    id={this.state.id}
                    onChange={this.state.onChange}
                    disabled={this.state.disabled}
                    value={this.props.value}
                    className={classnames("form-control custom-select", {
                        "is-invalid": this.state.error
                    })}
                >
                    <option value="">Choisir l'organisme régional</option>
                    {optionsList}
                </select>
                {this.state.info && <small className="form-text text-muted">{this.state.info}</small>}
                {this.state.error && <div className="invalid-feedback">{this.state.error}</div>}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { RechercherRegionsTous }
)(RegionSelect);
