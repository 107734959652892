import React, { Component } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import { RechercherUtilisateurID, ModifierUtilisateur } from "../../store/actions/adminActions";
import { RechercherRegionsTous } from "../../store/actions/regionsActions";

class ModifierUtilisateurForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            prenom: "",
            nom: "",
            courriel: "",
            isAdmin: false,
            regions: [],
            redirect: false
        };
    }

    componentDidMount = () => {
        this.props.RechercherRegionsTous();
        this.props.RechercherUtilisateurID(this.props.match.params[0]);
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.utilisateur.utilisateur !== this.props.utilisateur.utilisateur && this.props.utilisateur.utilisateur !== {}) {
            this.setState(this.props.utilisateur.utilisateur, () => {
                this.CheckRegion();
            });
        }
    };

    onSubmit = e => {
        e.preventDefault();
        const utilisateurModif = {
            _id: this.state._id,
            prenom: this.state.prenom,
            nom: this.state.nom,
            isAdmin: this.state.isAdmin,
            regions: this.state.regions
        };

        console.log(utilisateurModif);
        this.props.ModifierUtilisateur(utilisateurModif);
        this.setState({ redirect: true });
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    CheckRegion = () => {
        document.getElementById("adminCheck").checked = this.state.isAdmin;
        this.state.regions.map(region => {
            const obj = document.getElementById(region);
            if (obj) {
                obj.checked = "checked";
            }
            return true;
        });
    };

    onCheck = e => {
        console.log(e.target);
        if (e.target.name === "isAdmin") {
            return this.setState({ isAdmin: e.target.checked });
        }

        const regions = this.state.regions;
        if (e.target.checked) {
            if (!regions.includes(e.target.value)) {
                regions.push(e.target.value);
                this.setState({ regions });
            }
        } else {
            if (regions.includes(e.target.value)) {
                const res = regions.filter(region => {
                    return region !== e.target.value;
                });
                this.setState({ regions: res });
            }
        }
    };

    clearForm = () => {
        this.setState(this.initialState);
    };

    retour = () => {
        this.setState({ redirect: true });
    };

    render = () => {
        const redirect = this.state.redirect && <Redirect to="/liste-utilisateurs" />;
        const errors = this.props.errors;

        const regTable = this.props.regions.regions.map((elem, index) => {
            return (
                <div key={elem._id} className="form-check">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={elem._id}
                        name={"reg" + index}
                        id={elem._id}
                        checked={this.state[elem.nom]}
                        onChange={this.onCheck}
                    />
                    <label className="form-check-label" htmlFor={`checkReg${index}`}>
                        {elem.nom}
                    </label>
                </div>
            );
        });
        return (
            <div className="container pb-4 pt-4">
                {redirect}
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h1>Modification</h1>
                    </div>
                    <div className="col-md-12 text-center">
                        <p className="lead">Utilisateur</p>
                    </div>
                </div>

                <form onSubmit={this.onSubmit} autoComplete="off">
                    <input id="username" style={{ display: "none" }} type="text" name="fakeusernameremembered" />
                    <input id="password" style={{ display: "none" }} type="password" name="fakepasswordremembered" />

                    <div className="row form-group">
                        <div className="col md-6">
                            <label htmlFor="courriel">Courriel</label>
                            <input className="form-control" type="email" id="courriel" name="courriel" value={this.state.courriel} readOnly />
                            {errors.courriel && <div className="invalid-feedback">{errors.courriel}</div>}
                        </div>
                    </div>
                    <div className="row form-group">
                        <div className="col md-6">
                            <label htmlFor="prenom">Prénom</label>
                            <input
                                type="text"
                                name="prenom"
                                id="prenom"
                                onChange={this.onChange}
                                placeholder="Inscrire le prénom"
                                className={classnames("form-control", {
                                    "is-invalid": errors.prenom
                                })}
                                value={this.state.prenom}
                            />
                            {errors.prenom && <div className="invalid-feedback">{errors.prenom}</div>}
                        </div>
                        <div className="col md-6">
                            <label htmlFor="nom">Nom</label>
                            <input
                                type="text"
                                id="nom"
                                name="nom"
                                onChange={this.onChange}
                                placeholder="Inscrire le nom"
                                className={classnames("form-control", {
                                    "is-invalid": errors.nom
                                })}
                                value={this.state.nom}
                            />
                            {errors.nom && <div className="invalid-feedback">{errors.nom}</div>}
                        </div>
                    </div>
                    <div className="form-group form-check">
                        <input
                            type="checkbox"
                            name="isAdmin"
                            onChange={this.onCheck}
                            id="adminCheck"
                            className={classnames("form-check-input", {
                                "is-invalid": errors.admin
                            })}
                            checked={this.state.admin}
                        />
                        {errors.admin && <div className="invalid-feedback">{errors.admin}</div>}
                        <label className="form-check-label" htmlFor="adminCheck">
                            Est administrateur?
                        </label>
                    </div>
                    <div className="form-group">
                        <h3>Régions</h3>
                        {regTable}
                    </div>
                    <div className="row">
                        <div className="col md-12">
                            <input className="btn btn-reseau" type="submit" value="Envoyer" />
                            <input className="btn btn-secondary ml-3" type="button" value="Retourner à la liste" onClick={this.retour} />
                        </div>
                    </div>
                </form>
            </div>
        );
    };
}

const mapStateToProps = state => ({
    errors: state.errors,
    utilisateur: state.utilisateur,
    regions: state.regions
});

export default withRouter(
    connect(
        mapStateToProps,
        { RechercherUtilisateurID, RechercherRegionsTous, ModifierUtilisateur }
    )(ModifierUtilisateurForm)
);
