import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const CheckboxInputGroup = ({
   name,
   defaultChecked,
   placeholder,
   label,
   error,
   info,
   onChange,
   disabled,
   id,
   addclass
}) => {
   return (
      <div className={addclass}>
         <div className="form-check form-check-inline">
            <input
               name={name}
               id={id}
               type="checkbox"
               defaultChecked={defaultChecked}
               placeholder={placeholder}
               onChange={onChange}
               disabled={disabled}
               className={classnames("form-check-input", {
                  "is-invalid": error
               })}
            />
            <label className="form-check-label" htmlFor={id}>
               {label}
            </label>
         </div>
         {info && <small className="form-text text-muted">{info}</small>}
         {error && <div className="invalid-feedback">{error}</div>}
      </div>
   );
};

CheckboxInputGroup.propTypes = {
   id: PropTypes.string,
   name: PropTypes.string.isRequired,
   placeholder: PropTypes.string,
   defaultValue: PropTypes.string,
   label: PropTypes.string,
   info: PropTypes.string,
   error: PropTypes.string,
   onChange: PropTypes.func.isRequired,
   disabled: PropTypes.string,
   addclass: PropTypes.string
};

export default CheckboxInputGroup;
