import {
  CREATE_ACTIVITY,
  SET_ACTIVITY_FORM_ACTION,
  GET_ALL_ACTIVITIES,
  DELETE_ACTIVITY_ID,
  UPDATE_ACTIVITY_ID,
  GET_ACTIVITE,
  SET_ACTIVITY,
  CLEAR_ACTIVITY,
  CLEAR_ACTIVITY_LIST,
  GET_ERRORS,
  CLEAR_ERRORS,
  SET_SPINNER,
  GET_MESSAGE,
  GET_ALL_ACTIVITIES_LIST,
} from './types';
import axios from 'axios';
import {
  ClearSelectionActivite,
  ClearSelectionProgramme,
} from './programmesActions';

//PERMET DE CHERCHER EN FOURNISSANT UN TABLEAU D'ACTIVITÉS ET UN TABLEAU DE RÉGION
export const RechercherActivitesRegionsProgrammesAnnees = (
  date_debut,
  date_fin,
  programmesTab,
  regionsTab
) => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});

  axios
    .post ('/api/activite/regions-programmes-annees', {
      date_debut,
      date_fin,
      programmesTab,
      regionsTab,
    })
    .then (resultat => {
      dispatch ({type: GET_ALL_ACTIVITIES, payload: resultat.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch (ClearActivitesListe ());
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherActivitesRegionTypeActiviteAnnees = (
  date_debut,
  date_fin,
  programme,
  activite_type,
  region
) => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});

  axios
    .post ('/api/activite/region-type-activite-annees', {
      date_debut,
      date_fin,
      programme,
      activite_type,
      region,
    })
    .then (resultat => {
      dispatch ({type: GET_ALL_ACTIVITIES, payload: resultat.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch (ClearActivitesListe ());
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};
export const RechercherActivitesParRegions = (
  regionTab,
  date_debut,
  date_fin,
  offset,
  limit
) => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .post ('/api/activite/regions', {
      regionTab,
      date_debut,
      date_fin,
      offset,
      limit,
    })
    .then (resultat => {
      dispatch ({type: GET_ALL_ACTIVITIES_LIST, payload: resultat.data});
      dispatch ({type: SET_SPINNER, payload: false});
      dispatch ({type: CLEAR_ERRORS});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherActivitesParFiltres = filters => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/activite/filtres', {params: filters})
    .then (resultat => {
      dispatch ({type: GET_ALL_ACTIVITIES, payload: resultat.data});
      dispatch ({type: SET_SPINNER, payload: false});
      dispatch ({type: CLEAR_ERRORS});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherActiviteParId = activiteId => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/activite/id', {params: {activiteId}})
    .then (resultat => {
      dispatch ({type: GET_ACTIVITE, payload: resultat.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const AjouterActivite = activite => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .post ('/api/activite/creer', activite)
    .then (resultat => {
      resultat.data.success = true;
      dispatch ({type: CREATE_ACTIVITY, payload: resultat.data});
      dispatch ({type: CLEAR_ERRORS});
      dispatch ({type: SET_ACTIVITY_FORM_ACTION, payload: 'clear'});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const ModifierActivite = activite => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .put ('/api/activite/modifier', activite)
    .then (resultat => {
      dispatch ({type: UPDATE_ACTIVITY_ID, payload: resultat.data});
      dispatch ({type: CLEAR_ERRORS});
      dispatch ({type: SET_ACTIVITY_FORM_ACTION, payload: 'clear'});
      dispatch ({type: SET_SPINNER, payload: false});
      dispatch ({type: GET_MESSAGE, payload: {message: 'ACTIVITY_UPDATED'}});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const DupliquerActivite = activite => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .put ('/api/activite/dupliquer', activite)
    .then (resultat => {
      dispatch ({type: UPDATE_ACTIVITY_ID, payload: resultat.data});
      dispatch ({type: CLEAR_ERRORS});
      dispatch ({type: SET_SPINNER, payload: false});
      dispatch ({type: GET_MESSAGE, payload: {message: 'ACTIVITY_UPDATED'}});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const ResetActivite = () => dispatch => {
  dispatch ({type: CLEAR_ACTIVITY});
  dispatch (ClearSelectionProgramme ());
  dispatch (ClearSelectionActivite ());
};

export const SupprimerActivite = (id, filtres) => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .delete (`/api/activite/supprimer`, {params: {activiteId: id}})
    .then (resultat => {
      dispatch ({type: DELETE_ACTIVITY_ID, payload: resultat.data});
      dispatch (ClearActivitesListe ());
    })
    .then (resultatDel => {
      dispatch (RechercherActivitesParFiltres (filtres));
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const ClearActivitesListe = () => dispatch => {
  dispatch ({type: CLEAR_ACTIVITY_LIST});
};

export const SelectionnerUneActivite = id => dispatch => {
  axios
    .get (`/api/activite/id`, {params: {activiteId: id}})
    .then (resultat => {
      dispatch ({type: SET_ACTIVITY, payload: resultat.data});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
    });
};

export const ExporterTest = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/activite/tous')
    .then (resultat => {
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};
