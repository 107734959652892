import React, { Component } from "react";
import { withRouter, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";

import { ClearErrors } from "../../store/actions/errorActions";
import { ClearMessage } from "../../store/actions/messageActions";
import { ReinitMdp } from "../../store/actions/adminActions";

class ReinitiMdp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mdp: "",
			mdp2: "",
			redirect: false
		};
	}

	componentDidMount = () => {
		this.props.ClearErrors();
		this.props.ClearMessage();
	};

	componentWillUnmount = () => {
		this.props.ClearErrors();
		this.props.ClearMessage();
	};
	componentDidUpdate = (prevProps, prevState) => {
		if (prevProps.message !== this.props.message && this.props.message.message) {
			window.setTimeout(() => {
				this.setState({ redirect: true });
			}, 2000);
		}
	};

	onSubmit = e => {
		e.preventDefault();

		this.props.ReinitMdp(this.props.match.params[0], this.state.mdp, this.state.mdp2);
	};

	onChange = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render = () => {
		const errors = this.props.errors;
		const redirect = this.state.redirect && <Redirect to="/" />;
		const message = this.props.message;
		const tokenAlert = this.props.errors.err ? (
			<div className="alert alert-danger">
				{this.props.errors.err.token}
				<Link to="/oubli-mdp">Veuillez refaire une demande de réinitialisation</Link>
			</div>
		) : null;

		return (
			<div className="container pb-4 pt-4">
				{redirect}
				{tokenAlert}
				{message.message && <div className="alert alert-info">{message.message}</div>}
				<div className="row">
					<div className="col-md-12 text-center">
						<p className="lead">Réinitialisation du mot de passe</p>
					</div>
				</div>

				<form onSubmit={this.onSubmit} autoComplete="off">
					<input
						id="username"
						style={{ display: "none" }}
						type="text"
						name="fakeusernameremembered"
					/>
					<input
						id="password"
						style={{ display: "none" }}
						type="password"
						name="fakepasswordremembered"
					/>

					<div className="row form-group">
						<div className="col md-6">
							<label htmlFor="mdp">Nouveau mot de passe</label>
							<input
								className={classnames("form-control", { "is-invalid": errors.mdp })}
								type="password"
								id="mdp"
								name="mdp"
								value={this.state.mdp}
								onChange={this.onChange}
							/>
							{errors.mdp && <div className="invalid-feedback">{errors.mdp}</div>}
						</div>
					</div>
					<div className="row form-group">
						<div className="col md-6">
							<label htmlFor="mdp2">Veuillez confirmer le mot de passe</label>
							<input
								className={classnames("form-control", {
									"is-invalid": errors.mdp2
								})}
								type="password"
								id="mdp2"
								name="mdp2"
								value={this.state.mdp2}
								onChange={this.onChange}
							/>
							{errors.mdp2 && <div className="invalid-feedback">{errors.mdp2}</div>}
						</div>
					</div>

					<div className="row">
						<div className="col md-12">
							<input
								className="btn btn-reseau"
								type="submit"
								value="Réinitialiser le mot de passe"
							/>
						</div>
					</div>
				</form>
			</div>
		);
	};
}

const mapStateToProps = state => ({
	errors: state.errors,
	message: state.message
});

export default withRouter(
	connect(
		mapStateToProps,
		{ ClearErrors, ClearMessage, ReinitMdp }
	)(ReinitiMdp)
);
