export const GET_ALL_REGIONS = 'GET_ALL_REGIONS';
export const GET_REGION_ID = 'GET_REGION_ID';
export const CREATE_REGION = 'CREATE_REGION';
export const UPDATE_REGION_ID = 'UPDATE_REGION_ID';
export const DELETE_REGION_ID = 'DELETE_REGION_ID';

export const GET_ALL_PROGRAMMES = 'GET_ALL_PROGRAMMES';
export const GET_ALL_TYPES_ACTIVITE = 'GET_ALL_TYPES_ACTIVITE';
export const GET_TYPES_PROGRAMME = 'GET_TYPES_PROGRAMME';
export const GET_PROGRAMME_ID = 'GET_PROGRAMME_ID';
export const CREATE_PROGRAMME = 'CREATE_PROGRAMME';
export const UPDATE_PROGRAMME_ID = 'UPDATE_PROGRAMME_ID';
export const DELETE_PROGRAMME_ID = 'DELETE_PROGRAMME_ID';
export const SET_SELECTION_PROGRAMME = 'SET_SELECTION_PROGRAMME';
export const SET_SELECTION_ACTIVITE = 'SET_SELECTION_ACTIVITE';
export const CLEAR_SELECTION_PROGRAMME = 'CLEAR_SELECTION_PROGRAMME';
export const CLEAR_SELECTION_ACTIVITE = 'CLEAR_SELECTION_ACTIVITE';
export const RESET_PROGRAMME = 'RESET_PROGRAMME';

export const GET_ALL_RESPONSABLES = 'GET_ALL_RESPONSABLES';
export const GET_RESPONSABLE_ID = 'GET_RESPONSABLE_ID';
export const CREATE_RESPONSABLE = 'CREATE_RESPONSABLE';
export const UPDATE_RESPONSABLE_ID = 'UPDATE_RESPONSABLE_ID';
export const DELETE_RESPONSABLE_ID = 'DELETE_RESPONSABLE_ID';
export const CLEAR_SELECTION_RESPONSABLE = 'CLEAR_SELECTION_RESPONSABLE';

export const GET_ALL_ETABLISSEMENTS = 'GET_ALL_ETABLISSEMENTS';
export const GET_SCHOOL_BY_CODE = 'GET_SCHOOL_BY_CODE';
export const GET_ALL_SCHOOLS = 'GET_ALL_SCHOOLS';
export const GET_ETABLISSEMENT_ID = 'GET_ETABLISSEMENT_ID';
export const GET_ETABLISSEMENTS_BY_REGROUPEMENT =
  'GET_ETABLISSEMENTS_BY_REGROUPEMENT';
export const GET_MAX_CODE = 'GET_MAX_CODE';
export const CREATE_ETABLISSEMENT = 'CREATE_ETABLISSEMENT';
export const UPDATE_ETABLISSEMENT_ID = 'UPDATE_ETABLISSEMENT_ID';
export const UPDATE_IMSE = 'UPDATE_IMSE';
export const DELETE_ETABLISSEMENT_ID = 'DELETE_ETABLISSEMENT_ID';
export const SET_SELECTION_ETABLISSEMENT = 'SET_SELECTION_ETABLISSEMENT';
export const CLEAR_SELECTION_ETABLISSEMENT = 'CLEAR_SELECTION_ETABLISSEMENT';

export const GET_ALL_REGROUPEMENTS = 'GET_ALL_REGROUPEMENTS';
export const GET_SCHOOLBOARDS_BY_REGION = 'GET_SCHOOLBOARDS_BY_REGION';
export const GET_REGROUPEMENT_ID = 'GET_REGROUPEMENT_ID';
export const GET_REGROUPEMENT_TYPE_REGION = 'GET_REGROUPEMENT_TYPE_REGION';
export const GET_REGROUPEMENT_REGION = 'GET_REGROUPEMENT_REGION';
export const CREATE_REGROUPEMENT = 'CREATE_REGROUPEMENT';
export const UPDATE_REGROUPEMENT_ID = 'UPDATE_REGROUPEMENT_ID';
export const DELETE_REGROUPEMENT_ID = 'DELETE_REGROUPEMENT_ID';
export const GET_REGROUPEMENT_DISTINCT = 'GET_REGROUPEMENT_DISTINCT';
export const CLEAR_SELECTION_REGROUPEMENT = 'CLEAR_SELECTION_REGROUPEMENT';
export const SET_SELECTION_REGROUPEMENT = 'SET_SELECTION_REGROUPEMENT';
export const SET_SELECTION_TYPE_REGROUPEMENT =
  'SET_SELECTION_TYPE_REGROUPEMENT';

export const GET_ALL_ACTIVITIES = 'GET_ALL_ACTIVITIES';
export const GET_ALL_ACTIVITIES_LIST = 'GET_ALL_ACTIVITIES_LIST';
export const GET_ACTIVITY_ID = 'GET_ACTIVITY_ID';
export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const UPDATE_ACTIVITY_ID = 'UPDATE_ACTIVITY_ID';
export const DELETE_ACTIVITY_ID = 'DELETE_ACTIVITY_ID';
export const SET_ACTIVITY_FORM_ACTION = 'SET_ACTIVITY_FORM_ACTION';
export const SET_ACTIVITY = 'SET_ACTIVITY';
export const CLEAR_ACTIVITY = 'CLEAR_ACTIVITY';
export const CLEAR_ACTIVITY_LIST = 'CLEAR_ACTIVITY_LIST';
export const GET_ACTIVITE = 'GET_ACTIVITE';

export const GET_ALL_LEVELS = 'GET_ALL_LEVELS';

export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER = 'GET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';

export const EXPORTATION_DONE = 'EXPORTATION_DONE';
export const CLEAR_EXPORTATION_DATA = 'CLEAR_EXPORTATION_DATA';
export const SET_EXPORTATION_DATA = 'SET_EXPORTATION_DATA';
export const SET_EXPORTATION_FORMAT = 'SET_EXPORTATION_FORMAT';

export const IMPORTATION_DONE = 'IMPORTATION_DONE';

export const SET_SPINNER = 'SET_SPINNER';
export const GET_MESSAGE = 'GET_MESSAGE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const SET_MESSAGE = 'SET_MESSAGE';
