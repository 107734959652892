import { GET_ALL_RESPONSABLES, CREATE_RESPONSABLE, CLEAR_SELECTION_RESPONSABLE } from "../actions/types";

const initialState = {
    responsables: [],
    selectionResponsable: {}
};

const responsablesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_SELECTION_RESPONSABLE:
            return { ...state, selectionResponsable: {} };
        case CREATE_RESPONSABLE:
            return { ...state, selectionResponsable: action.payload };
        case GET_ALL_RESPONSABLES:
            return { ...state, responsables: action.payload };
        default:
            return state;
    }
};

export default responsablesReducer;
