import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import InitMotDePasse from '../admin/InitMotDePasse';
class Accueil extends Component {
  render () {
    const nouveauCompte = this.props.auth.utilisateur.nouveauCompte;
    const accueilText = (
      <Fragment>
        <div className="row">
          <div className="col md-12 mt-3">
            <h2>
              Bienvenue sur l'application de gestion des statistiques <br />
              du Réseau Technoscience
            </h2>
            <h6>
              <small>Version 1.2</small>
            </h6>
            <p>
              L'application Statistiques permet de compiler les informations
              concernant les activités réalisées dans votre région. Elle permet
              également d'exporter rapidement des données en fonction des périodes et
              des programmes. Il est possible d'importer des données depuis l'ancien
              système mais de nouvelles catégories de données ont été ajoutées. Il est
              donc préférable d'utiliser l'application afin d'enregistrer vos données.
            </p>
            <hr />
            <h3>Nouveauté | <small>29 juillet 2019</small></h3>
            <p>
              <ul>
                <li>Visualisation sous forme de graphiques (menu Activités)</li>
                <li>
                  Nouveau module de recherche permettant de filtrer par date, programme, organisme régional et numéro de référence
                </li>
                <li>
                  Possibilité de dupliquer une activité existante permettant d'accélérer la saisie
                </li>
                <li>
                  Correction d'un bug d'affichage lors de la modification d'une activité existante
                </li>
              </ul>
            </p>
            <hr />
            <p>
              L'application Statistiques est constamment en cours de développement,
              une bonification des fonctionnalités est prévue.
            </p>
            <p className="">
              Vous avez besoin d'aide? Cliquez sur l'onglet{' '}
              <a href="/instructions">Aide</a> dans le menu principal
            </p>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <p className="lead">En développement</p>
            <ul className="">
              <li className="">
                Modification du formulaire d'ajout d'activité
              </li>
              <li className="">
                Exportation de données sous forme de pdf et excel plus précis
              </li>
            </ul>
          </div>
          <div className="col-md-6">
            <p className="lead">
              Vous ne trouvez pas ce que vous cherchez? Vous avez besoin de d'autres données?
            </p>
            <address>
              Maxime Lacasse Germain, développeur Web <br />
              <a href="tel:+1-514-252-3027,3893">
                <i className="fas fa-phone" /> 514 252-3027, poste 3899
              </a>
              <br />
              <a href="mailto:mlacassegermain@technoscience.ca">
                <i className="fas fa-address-card" />{' '}
                mlacassegermain@technoscience.ca
              </a>
            </address>
          </div>
        </div>
      </Fragment>
    );

    return (
      <div className="container">
        {nouveauCompte ? <InitMotDePasse /> : accueilText}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect (mapStateToProps) (Accueil);
