import {
    GET_SCHOOL_BY_CODE,
    GET_ALL_SCHOOLS,
    GET_ALL_ETABLISSEMENTS,
    GET_REGROUPEMENT_DISTINCT,
    GET_REGROUPEMENT_TYPE_REGION,
    GET_REGROUPEMENT_REGION,
    GET_REGROUPEMENT_ID,
    GET_MAX_CODE,
    GET_ETABLISSEMENTS_BY_REGROUPEMENT,
    SET_SELECTION_TYPE_REGROUPEMENT,
    SET_SELECTION_REGROUPEMENT,
    SET_SELECTION_ETABLISSEMENT,
    CLEAR_SELECTION_REGROUPEMENT,
    CLEAR_SELECTION_ETABLISSEMENT,
    CREATE_ETABLISSEMENT
} from "../actions/types";

const initialState = {
    regroupements: [],
    etablissements: [],
    typesRegroupement: [],
    typesEtablissement: [],
    selectionEtablissement: "",
    selectionRegroupement: "",
    selectionTypeRegroupement: "",
    codeEtablissement: "",
    regroupementActuel: {},
    rechercheEtablissement:""
};

const etablissementsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_REGROUPEMENT_DISTINCT:
            return { ...state, typesRegroupement: action.payload };
        case GET_REGROUPEMENT_TYPE_REGION:
            return { ...state, regroupements: action.payload };
        case GET_REGROUPEMENT_REGION:
            return { ...state, regroupements: action.payload };
        case GET_REGROUPEMENT_ID:
            return { ...state, regroupementActuel: action.payload };
        case GET_ALL_ETABLISSEMENTS:
            return { ...state, etablissements: action.payload };
        case GET_ETABLISSEMENTS_BY_REGROUPEMENT:
            return { ...state, etablissements: action.payload };
        case GET_SCHOOL_BY_CODE:
            return { ...state, rechercheEtablissement: action.payload };
        case GET_ALL_SCHOOLS:
            return { ...state, etablissements: action.payload };
        case GET_MAX_CODE:
            return { ...state, codeEtablissement: action.payload };
        case CLEAR_SELECTION_REGROUPEMENT:
            return { ...state, regroupements: [], etablissements: [] };
        case CREATE_ETABLISSEMENT:
            return { ...state, selectionEtablissement: action.payload };
        case CLEAR_SELECTION_ETABLISSEMENT:
            return { ...state, etablissements: [], selectionEtablissement: "" };
        case SET_SELECTION_REGROUPEMENT:
            return { ...state, selectionRegroupement: action.payload };
        case SET_SELECTION_TYPE_REGROUPEMENT:
            return { ...state, selectionTypeRegroupement: action.payload };
        case SET_SELECTION_ETABLISSEMENT:
            return { ...state, selectionEtablissement: action.payload };
        default:
            return state;
    }
};

export default etablissementsReducer;
