import { SET_EXPORTATION_DATA, EXPORTATION_DONE, GET_ERRORS, SET_EXPORTATION_FORMAT, SET_SPINNER } from "./types";
import axios from "axios";

export const RechercherJeunesParPalier = () => dispatch => {
    dispatch({ type: SET_SPINNER, payload: true });
    return axios
        .get("/api/exportation/jeunes-par-palier")
        .then(resultat => {
            const doc = {
                nom: "Jeunes par palier",
                content: resultat.data
            };

            dispatch({ type: SET_EXPORTATION_DATA, payload: doc });
            dispatch({ type: SET_SPINNER, payload: false });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
            dispatch({ type: SET_SPINNER, payload: false });
        });
};

export const SetExportationData = data => dispatch => {
    dispatch({ type: SET_EXPORTATION_DATA, payload: data });
};
export const SetExportationFormat = format => dispatch => {
    dispatch({ type: SET_EXPORTATION_FORMAT, payload: format });
};

export const ExportationDone = () => dispatch => {
    dispatch({ type: EXPORTATION_DONE });
    dispatch({ type: SET_SPINNER, payload: false });
};
