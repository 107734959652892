import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextFieldGroup = ({ name, placeholder, value, defaultValue, label, error, info, onChange, disabled, id, addclass, min, max, step }) => {
    return (
        <div className={addclass}>
            <label htmlFor={id}>{label}</label>
            <input
                type="number"
                className={classnames("form-control", {
                    "is-invalid": error
                })}
                placeholder={placeholder}
                name={name}
                onChange={onChange}
                disabled={disabled}
                id={id}
                value={value}
                min={min}
                max={max}
                step={step}
            />
            {info && <small className="form-text text-muted">{info}</small>}
            {error && <div className="invalid-feedback">{error}</div>}
        </div>
    );
};

TextFieldGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    info: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string,
    addclass: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    step: PropTypes.string
};
TextFieldGroup.defaultProps = {
    type: "text"
};
export default TextFieldGroup;
