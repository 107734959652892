import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import { AjouterActivite, ResetActivite } from "../../store/actions/activitesActions";
import { ClearSelectionProgramme } from "../../store/actions/programmesActions";
import { RechercherRegroupementsParRegions } from "../../store/actions/regroupementsActions";
import { ClearErrors } from "../../store/actions/errorActions";
import isEmpty from "../../validation/isEmpty";
import moment from "moment";

import TextFieldGroup from "../common/TextFieldGroup";
import SelectInputGroup from "../common/SelectInputGroup";
import NumberInputGroup from "../common/NumberInputGroup";
import CheckboxInputGroup from "../common/CheckboxInputGroup";
import TextAreaInputGroup from "../common/TextAreaInputGroup";
import RegionSelect from "./RegionSelect";
import EcoleSelect from "./EcoleSelect";
import NiveauScolaireSelect from "./NiveauScolaireSelect";
import ProgramSelect from "../programmes/SelectProgrammes";
import AjoutEtablissementPopup from "../etablissements/AjouterEtablissementPopup";
import VisibilitySelect from "./VisibilitySelect";

class ActiviteAjoutForm extends Component {
	constructor(props) {
		super(props);

		this.state = {
			activite: "",
			adaptation_scolaire: false,
			date_debut: this.dateParDefaut(),
			date_fin: "",
			duree: 0,
			etablissement: "",
			etablissements: [],
			evaluation_recue: false,
			langue: "francais",
			nb_activites: 1,
			nb_enseignants: 1,
			nb_garcons: 0,
			nb_filles: 0,
			nb_total: 0,
			nb_solo: 0,
			nb_duo: 0,
			nb_trio: 0,
			nb_quatuor: 0,
			nb_visiteurs: 0,
			no_reference: "",
			nom_animateur: "",
			niveau_scolaire: "",
			notes: "",
			premiere_participation: false,
			prix: 0,
			programme: "",
			region: "",
			regroupement: "",
			responsable_prenom: "",
			responsable_nom: "",
			responsable_courriel: "",
			responsable_tel1: "",
			responsable_tel2: "",
			thematique: "",
			type_regroupement: "",
			visibilite: "",
			redirect: false,

			liste_regroupements_distinct: [],
			liste_regroupements_region_type: [],
			liste_etablissements_regroupement: [],

			popupEtablissement: false
		};

		this.initialState = this.state;
	}

	componentDidMount = () => {
		//Resets form action
		this.props.ResetActivite();
		//sets activite from redux
		this.setState({
			activite: this.props.programmes.selectionActivite,
			programme: this.props.programmes.selectionProgramme
		});
	};

	componentWillUnmount = () => {
		this.props.ResetActivite();
		this.props.ClearErrors();
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (this.props.programmes.selectionActivite !== prevProps.programmes.selectionActivite) {
			this.setState({ activite: this.props.programmes.selectionActivite });
		}

		if (this.props.programmes.selectionProgramme !== prevProps.programmes.selectionProgramme) {
			this.setState({ programme: this.props.programmes.selectionProgrammeNom });
		}

		if (
			this.props.etablissements.selectionEtablissement !==
			prevProps.etablissements.selectionEtablissement
		) {
			this.setState({ etablissement: this.props.etablissements.selectionEtablissement });
		}
		if (this.props.activites.activiteActuelle !== prevProps.activites.activiteActuelle) {
			if (this.props.activites.activiteActuelle.success) {
				this.setState(this.initialState);
				this.props.ResetActivite();
				this.props.ClearSelectionProgramme();
				this.props.ClearErrors();
				this.setState({ etablissementAjout: true });
			}
		}
		if (
			prevState.nb_garcons !== this.state.nb_garcons ||
			prevState.nb_filles !== this.state.nb_filles
		) {
			this.setState({
				nb_total: parseInt(this.state.nb_garcons) + parseInt(this.state.nb_filles)
			});
		}
	};

	onChange = e => {
		if (e.target.type === "checkbox") {
			this.setState({ [e.target.name]: e.target.checked });
		} else {
			this.setState({ [e.target.name]: e.target.value });
		}
	};
	onDateChange = e => {
		this.setState({ [e.target.name]: moment.utc(e.target.value).format("YYYY-MM-DD") });
	};

	onSubmit = e => {
		e.preventDefault();
	};

	dateParDefaut = () => {
		var date = new Date();

		var day = date.getDate();
		var month = date.getMonth() + 1;
		var year = date.getFullYear();

		if (month < 10) month = "0" + month;
		if (day < 10) day = "0" + day;

		return year + "-" + month + "-" + day;
	};

	enregistrer = () => {
		const newActivite = this.state;
		newActivite.type = this.props.programmes.selectionProgrammeNom;
		newActivite.programme = this.state.activite;
		newActivite.modification_date = new Date();
		newActivite.modification_auteur = this.props.auth.utilisateur.id;
		this.props.AjouterActivite(newActivite);
		//this.setState({ redirect: true });
	};

	enregistrerEtAjouter = () => {
		const newActivite = this.state;
		newActivite.type = this.props.programmes.selectionProgrammeNom;
		newActivite.programme = this.state.activite;
		newActivite.modification_date = new Date();
		newActivite.modification_auteur = this.props.auth.utilisateur.id;
		this.props.AjouterActivite(newActivite);
		this.clearForm();
	};

	clearForm = () => {
		this.setState(this.initialState, () => {
			this.props.ResetActivite();
			document.getElementById("notes").value = "";
			this.forceUpdate();
		});
	};

	CacherModal = () => {
		this.setState({ popupEtablissement: false });
	};

	ShowModal = () => {
		this.setState({ popupEtablissement: true });
	};
	OuvrirModal = () => {
		this.props.RechercherRegroupementsParRegions(this.props.auth.utilisateur.regions);
	};

	AcceptModal = () => {
		console.log("Accept");
	};
	ajouterEtablissementAListe = () => {
		const { selectionEtablissement } = this.props.etablissements;
		const { etablissements } = this.state;
		if (!etablissements.includes(selectionEtablissement)) {
			etablissements.push(selectionEtablissement);
			this.setState({ etablissements })
		} else {
			console.log("élément déjà dans la liste");
		}
	}
	supprimerEtablissementListe = (e) => {
		const parent = e.target.closest(".etablissements-btn");
		const codeOrganisme = parent.id;
		console.log(parent, codeOrganisme);
		let { etablissements } = this.state;
		etablissements = etablissements.filter((element) => {
			return element.code_organisme !== codeOrganisme
		})
		this.setState({ etablissements })
	}
	renderListeEtablissements = () => {
		const { etablissements } = this.state;

		const elementsListe = etablissements.map((etablissement, index) => {
			return (<div key={index} id={etablissement.code_organisme} className="etablissements-btn pills" title={etablissement.region_admin}>{etablissement.nom} (#{etablissement.code_organisme})<span className="remove-btn fas fa-times-circle ml-2" onClick={this.supprimerEtablissementListe}></span></div>)
		})

		return elementsListe.length > 0 ? elementsListe : "Aucun élément sélectionné"
	}

	render = () => {
		//REDIRECT
		const { redirect } = this.state;
		if (redirect) {
			return <Redirect to="/liste-activites" />;
		}

		const errors = this.props.errors;
		const t = this.props.programmes.selectionProgrammeNom;
		const langue = [
			{ value: "francais", name: "Français" },
			{ value: "anglais", name: "Anglais" },
			{ value: "autre", name: "Autre" }
		];

		const alertAjout = (
			<div className="alert alert-info alert-dismissible fade show" role="alert">
				Activité ajoutée
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-label="Close"
					onClick={this.CacherModal}
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		);

		const alertError = (
			<div className="alert alert-danger" role="alert">
				Le formulaire contient des erreurs. Veuillez apporter les corrections demandées.
			</div>
		);

		return (
			<div className="form-activite">
				<AjoutEtablissementPopup />

				<div className="container">
					<div className="row">
						<div className="col-md-8 m-auto">
							<h1 className="display-4 text-center mt-20">Ajouter une activité</h1>
							<h6 className="py-3" style={{ lineHeight: "1.5em" }}>
								Astuce: Vous pouvez vous déplacer dans le formulaire avec les
								touches <kbd>Tab</kbd>, <kbd>Espace</kbd>, <kbd>Enter &#x23CE;</kbd>{" "}
								ainsi que <kbd>&larr;, &uarr;, &rarr;, &darr;</kbd>
							</h6>
							<div className="col-md-12">
								{this.state.etablissementAjout && alertAjout}
							</div>
							<form id="xscForm" noValidate onSubmit={this.onSubmit}>
								{/* SELECTION PROGRAMME ET ACTIVITE*/}
								<ProgramSelect error={errors.programme} />
								<hr />
								{t !== "" && (
									<div>
										<div className="row">
											<h3>Description de l'activité</h3>
										</div>
										<div className="form-group row">
											<TextFieldGroup
												id="no_reference"
												name="no_reference"
												onChange={this.onChange}
												value={this.state.no_reference}
												label="Numéro de référence"
												type="text"
												addclass="col-md-12"
												error={errors.no_reference}
											/>
										</div>

										<div className="form-group row">
											<TextFieldGroup
												id="date_debut"
												name="date_debut"
												onChange={this.onDateChange}
												label="Date de début"
												type="date"
												addclass="col-md-6 mb-3"
												value={this.state.date_debut}
												error={errors.date_debut}
											/>
											<TextFieldGroup
												id="date_fin"
												name="date_fin"
												onChange={this.onDateChange}
												label="Date de fin"
												type="date"
												addclass="col-md-6 mb-3"
												value={this.state.date_fin}
												error={errors.date_fin}
											/>
											<NumberInputGroup
												name="duree"
												label="Durée en heures d'une activité"
												id="duree"
												onChange={this.onChange}
												addclass="col-md-12"
												value={this.state.duree}
												min="0"
												error={errors.duree}
											/>
										</div>
										<hr />
										<div className="form-group row mb-3 mt-3">
											<NiveauScolaireSelect
												name="niveau_scolaire"
												label="Niveau scolaire"
												onChange={this.onChange}
												id="niveau_scolaire"
												addclass="col-md-4"
												value={this.state.niveau_scolaire}
												error={errors.niveau_scolaire}
											/>
											<SelectInputGroup
												name="langue"
												label="Langue"
												onChange={this.onChange}
												id="langue"
												addclass="col-md-4"
												options={langue}
												value={this.state.langue}
												error={errors.langue}
											/>
											<NumberInputGroup
												name="nb_activites"
												label="Nombre d'activités"
												id="nb_activites"
												onChange={this.onChange}
												addclass="col-md-4"
												value={this.state.nb_activites}
												min="1"
												step="1"
												error={errors.nb_activites}
											/>
										</div>

										<div className="form-group row mb-3 mt-3">
											<NumberInputGroup
												name="nb_visiteurs"
												label="Nombre de visiteurs"
												id="nb_visiteurs"
												onChange={this.onChange}
												addclass="col-md-6"
												value={this.state.nb_visiteurs}
												min="0"
												step="1"
												error={errors.nb_visiteurs}
											/>
											<VisibilitySelect
												name="visibilite"
												label="Visibilité"
												onChange={this.onChange}
												id="visibilite"
												addclass="col-md-6"
												value={this.state.visibilite}
												error={errors.visibilite}
											/>
										</div>

										<div className="form-group row mb-3 mt-3">
											{(t === "Les Débrouillards" || t === "Trousses numériques" || t === "Autres") && (
												<TextFieldGroup
													id="thematique"
													name="thematique"
													onChange={this.onChange}
													label="Thématique"
													type="text"
													addclass="col-md-6 mb-4"
													value={this.state.thematique}
													error={errors.thematique}
												/>
											)}
											{(t === "Les Débrouillards" ||
												t === "Trousses numériques" ||
												t === "Produits clés en main" ||
												t === "Autres") && (
													<TextFieldGroup
														id="nom_animateur"
														name="nom_animateur"
														onChange={this.onChange}
														label="Nom de l'animateur"
														type="text"
														addclass="col-md-6 mb-4"
														value={this.state.nom_animateur}
														error={errors.nom_animateur}
													/>
												)}
											{t === "Innovateurs à l'école" && (
												<TextFieldGroup
													id="nom_animateur"
													name="nom_animateur"
													onChange={this.onChange}
													label="Nom de l'innovateur (ou organisme)"
													type="text"
													addclass="col-md-6 mb-4"
													value={this.state.nom_animateur}
													error={errors.nom_animateur}
												/>
											)}
										</div>
										<div className="form-group row">
											{(t === "Innovateurs à l'école" ||
												t === "Les Débrouillards" ||
												t === "Produits clés en main" ||
												t === "Envirovolt" ||
												t === "00Watt") && (
													<CheckboxInputGroup
														name="evaluation_recue"
														id="evaluation_recue"
														label="Évaluation reçue"
														addclass="col-md-6"
														onChange={this.onChange}
														checked={this.state.evaluation_recue}
														error={errors.evaluation_recue}
													/>
												)}
										</div>
										<hr />
										<div className="row">
											<h3>Personne contact au lieu de l'activité</h3>
										</div>
										<div className="form-group row mb-3 mt-3">
											<TextFieldGroup
												id="responsable_prenom"
												name="responsable_prenom"
												onChange={this.onChange}
												value={this.state.responsable_prenom}
												label="Prénom"
												type="text"
												addclass="col-md-6"
												error={errors.responsable_prenom}
											/>
											<TextFieldGroup
												id="responsable_nom"
												name="responsable_nom"
												onChange={this.onChange}
												value={this.state.responsable_nom}
												label="Nom"
												type="text"
												addclass="col-md-6"
												error={errors.responsable_nom}
											/>
										</div>
										<div className="form-group row mb-3 mt-3">
											<TextFieldGroup
												id="responsable_courriel"
												name="responsable_courriel"
												onChange={this.onChange}
												value={this.state.responsable_courriel}
												label="Courriel"
												type="text"
												addclass="col-md-4"
												error={errors.responsable_courriel}
											/>
											<TextFieldGroup
												id="responsable_tel1"
												name="responsable_tel1"
												onChange={this.onChange}
												value={this.state.responsable_tel1}
												label="Téléphone 1"
												type="text"
												addclass="col-md-4"
												error={errors.responsable_tel1}
											/>
											<TextFieldGroup
												id="responsable_tel2"
												name="responsable_tel2"
												onChange={this.onChange}
												value={this.state.responsable_tel2}
												label="Téléphone 2"
												type="text"
												addclass="col-md-4"
												error={errors.responsable_tel2}
											/>
										</div>
										<hr />
										<div className="row">
											<h3>Organisme régional</h3>
										</div>
										<div className="form-group row mb-3 mt-3">
											<RegionSelect
												name="region"
												id="region"
												label="Organisme régional"
												addclass="col-md-12"
												onChange={this.onChange}
												value={this.state.region}
												error={errors.region}
											/>
										</div>
										<hr />
										<div className="row">
											<div className="col-md-12">
												<h3>Établissements participants</h3>
											</div>
											<div className="col-md-12">
												<small>
													Astuce: Les écoles sont regroupées sous
													"Commission scolaire".
												</small>
											</div>
										</div>

										<EcoleSelect error={errors.etablissement} />
										{/* <div className="form-group row mb-3 mt-3">
											<CheckboxInputGroup
												name="premiere_participation"
												id="premiere_participation"
												label="Première participation"
												addclass="col-md-6"
												onChange={this.onChange}
												checked={this.state.premiere_participation}
												error={errors.premiere_participation}
											/>
											<CheckboxInputGroup
												name="adaptation_scolaire"
												id="adaptation_scolaire"
												label="Adaptation scolaire"
												addclass="col-md-6"
												onChange={this.onChange}
												checked={this.state.adaptation_scolaire}
												error={errors.adaptation_scolaire}
											/>
										</div> */}
										<div className="form-row">
											<div className="col-12 text-align-center">
												<button
													type="button"
													className="btn btn-reseau btn-block"
													onClick={this.ajouterEtablissementAListe}
												>
													<i className="fas fa-plus-circle" /> Ajouter le lieu à la listes
												</button>
											</div>
											<div className="col-12">
												<small className="py-1">
													<i className="fas fa-exclamation-triangle text-warning" />{" "}
													Vous pouvez ajouter plusieurs établissements à une activité
												</small>
											</div>
										</div>
										<h5 className="my-2">Liste des établissements sélectionnés</h5>
										<div className="liste-etablissements">
											{this.renderListeEtablissements()}
										</div>
										<hr />
										<div className="row mt-3">
											<div className="col-12 text-align-center">
												<h3>L'établissement n'existe pas?</h3>
												<p>Vérifiez que vous avez le bon nom avant d'ajouter un nouvel établissement</p>
												<button
													type="button"
													className="btn btn-reseau-reverse btn-block"
													data-toggle="modal"
													data-target="#ajoutModal"
													onClick={this.OuvrirModal}
												>
													<i className="fas fa-plus-circle" /> Créer un nouvel
													établissement
												</button>
											</div>
											<div className="col-12">
												<small className="py-1">
													<i className="fas fa-exclamation-triangle text-warning" />{" "}
													Vous ne pouvez pas créer d'école, de
													municipalité ou de bibliothèque. Cela doit être fait par un utilisateur administrateur. Les données proviennent des différents ministères pour ces établissements
												</small>
											</div>
										</div>
										<hr />
										<div className="row">
											<h3>Infos sur les participants</h3>
										</div>
										<div className="form-group row mb-3 mt-3">
											<NumberInputGroup
												name="nb_enseignants"
												id="nb_enseignants"
												label="Nombre d'enseignants"
												onChange={this.onChange}
												addclass="col-md-3"
												value={this.state.nb_enseignants}
												min="0"
												step="1"
												error={errors.nb_enseignants}
											/>
											<div className="col-md-9" />
										</div>
										<div className="form-group row mb-3 mt-3">
											<NumberInputGroup
												name="nb_garcons"
												id="nb_garcons"
												label="Nb garçons"
												onChange={this.onChange}
												addclass="col-md-3"
												value={this.state.nb_garcons}
												min="0"
												step="1"
												error={errors.nb_garcons}
											/>
											<NumberInputGroup
												name="nb_filles"
												id="nb_filles"
												label="Nb filles"
												onChange={this.onChange}
												addclass="col-md-3"
												value={this.state.nb_filles}
												min="0"
												step="1"
												error={errors.nb_filles}
											/>
											<NumberInputGroup
												name="nb_total"
												id="nb_total"
												label="Total de participants"
												onChange={this.onChange}
												addclass="col-md-6"
												value={this.state.nb_total}
												min="0"
												step="1"
												error={errors.nb_total}
											/>
										</div>
										{(t === "Expo-sciences" ||
											t === "Défi génie inventif" ||
											t === "Défi apprenti génie") && (
												<div className="form-group row mb-3 mt-3">
													<NumberInputGroup
														name="nb_solo"
														id="nb_solo"
														label="Nombre de projets solo"
														onChange={this.onChange}
														addclass="col-md-6"
														value={this.state.nb_solo}
														min="0"
														step="1"
														error={errors.nb_solo}
													/>
													<NumberInputGroup
														name="nb_duo"
														id="nb_duo"
														label="Nombre de projets duo"
														onChange={this.onChange}
														addclass="col-md-6"
														value={this.state.nb_duo}
														min="0"
														step="1"
														error={errors.nb_duo}
													/>
												</div>
											)}
										{t === "Défi génie inventif" && (
											<div className="form-group row mb-3 mt-3">
												<NumberInputGroup
													name="nb_trio"
													id="nb_trio"
													label="Nombre de projets trio"
													onChange={this.onChange}
													addclass="col-md-6"
													value={this.state.nb_trio}
													min="0"
													step="1"
													error={errors.nb_trio}
												/>
												<NumberInputGroup
													name="nb_quatuor"
													id="nb_quatuor"
													label="Nombre de projets quatuor"
													onChange={this.onChange}
													addclass="col-md-6"
													value={this.state.nb_quatuor}
													min="0"
													step="1"
													error={errors.nb_quatuor}
												/>
											</div>
										)}

										<hr />
										<TextAreaInputGroup
											name="notes"
											id="notes"
											maxlength="200"
											onChange={this.onChange}
											addclass="col-md-12"
											label="Notes"
											value={this.state.notes}
											error={errors.notes}
										/>
										<hr />
										{!isEmpty(this.props.errors) && alertError}
										<div className="form-group row justify-content-around">
											<input
												type="button"
												className="btn btn-reseau btn-block mt-4 col-md-3"
												value="Enregistrer"
												onClick={this.enregistrer}
											/>
										</div>
									</div>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	};
}
const mapStateToProps = state => ({
	etablissements: state.etablissements,
	regions: state.regions,
	programmes: state.programmes,
	responsables: state.responsables,
	auth: state.auth,
	errors: state.errors,
	activites: state.activites
});
export default connect(
	mapStateToProps,
	{
		AjouterActivite,
		ResetActivite,
		RechercherRegroupementsParRegions,
		ClearSelectionProgramme,
		ClearErrors
	}
)(withRouter(ActiviteAjoutForm));
