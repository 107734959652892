//IMPORT ACTIONS
import { GET_ALL_LEVELS } from "../actions/types";

const initialState = {
   niveaux: []
};

const activitesReducer = (state = initialState, action) => {
   switch (action.type) {
      case GET_ALL_LEVELS:
         return { ...state, niveaux: action.payload };
      default:
         return state;
   }
};

export default activitesReducer;
