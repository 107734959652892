import React, {Component, Fragment} from 'react';
import LineChart from './Template/LineChart';
import fileSaver from 'file-saver';
import canvasToBlob from 'canvas-toBlob';
export default class NbActivitesParMois extends Component {
  constructor (props) {
    super (props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Nb d'activités",
            data: [],
            backgroundColor: [],
            fill: false,
            pointRadius: 10,
            lineTension: 0.25,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        layout: {
          padding: 50,
        },
        plugins: {
          datalabels: {
            color: '#FFF',
            anchor: 'center',
            align: 'end',
            clamp: false,
            backgroundColor: context => {
              return context.dataset.backgroundColor;
            },
            borderRadius: 25,
            clip: false,
            font: {
              size: 12,
              weight: 'bolder',
            },
            offset: 10,
            padding: 6,
            borderRadius: 25,
            borderWidth: 2,
            borderColor: '#FFF',
            textAlign: 'center',
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.updateData ();
  };
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.activites !== this.props.activites) {
      this.updateData ();
    }
  }
  convertToUTC = date => {
    return Date.UTC (
      date.getUTCFullYear (),
      date.getUTCMonth (),
      date.getUTCDate (),
      date.getUTCHours (),
      date.getUTCMinutes (),
      date.getUTCSeconds ()
    );
  };
  saveImage = () => {
    const canvas = document.querySelector ('#activites');
    canvas.toBlobHD (blob => {
      fileSaver.saveAs (blob, 'nbActivitesParMois.png');
    });
  };
  updateData = () => {
    let nb_total = 0;
    //Get all dates
    const dates = this.props.activites.map (activite => {
      nb_total++;
      return {
        date: new Date (activite.date_debut),
        activites: activite.nb_activites,
      };
    });
    //Sorts dates older first
    dates.sort ((a, b) => {
      return a.date - b.date;
    });

    //If any
    //if (dates.length > 0) {
    let periode_debut = new Date (
      this.props.periode_debut
    ).toLocaleDateString ('fr-CA', {
      timeZone: 'UTC',
    });
    let periode_fin = new Date (
      this.props.periode_fin
    ).toLocaleDateString ('fr-CA', {
      timeZone: 'UTC',
    });
    //Reset to first day of month/Normalize to get timezone
    let dateMin = new Date (periode_debut);
    let dateMax = new Date (periode_fin);
    dateMin.setHours (dateMin.getHours () + 4);
    dateMax.setHours (dateMax.getHours () + 4);

    const labels = [];
    const dataParMois = [];
    for (let d = dateMin; d <= dateMax; d.setMonth (d.getMonth () + 1)) {
      //console.log("month", d.getMonth() + 1);
      //Set labels
      labels.push (
        d.getMonth () + 1 + '/' + d.getYear ().toString ().substr (-2)
      );

      //Set data
      const nbActivites = dates.filter (date => {
        return (
          date.date.getMonth () === d.getMonth () &&
          date.date.getFullYear () === d.getFullYear ()
        );
      });
      let total = 0;
      nbActivites.forEach (date => {
        total += date.activites;
      });
      dataParMois.push (total);
    }

    //SET COLOR FOR EACH ELEMENTS
    let colourBackground = dataParMois.map ((data, index) => {
      let background = '';

      let colorIndex = index % 3;
      if (colorIndex === 0) {
        background = 'rgb(34, 108, 172)';
      } else if (colorIndex === 1) {
        background = 'rgb(255, 152, 42)';
      } else if (colorIndex === 2) {
        background = '#aeb8ea';
      } else {
        background = 'rgb(34, 108, 172)';
      }

      return background;
    });

    const dataToUpdate = this.state.data;

    dataToUpdate.labels = labels;
    dataToUpdate.datasets[0].data = dataParMois;
    dataToUpdate.datasets[0].backgroundColor = colourBackground;

    this.setState ({data: dataToUpdate, nb_total});
  };

  render () {
    return (
      <Fragment>
        <h4 className="text-center">Nombre d'activités par mois</h4>
        <h6 className="text-center">
          Nombre total d'activités:
          {' '}
          {this.props.nb_activites}
          <span className="ml-5 btn btn-reseau" onClick={this.saveImage}>
            <i className=" fas fa-camera-retro" />
          </span>

        </h6>
        <LineChart
          id="activites"
          data={this.state.data}
          height={80}
          options={this.state.options}
        />
      </Fragment>
    );
  }
}
