import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import classnames from "classnames";
import moment from "moment";
import "moment/locale/fr";

import { RechercherProgrammesTous } from "../../store/actions/programmesActions";
import { RechercherRegionsTous } from "../../store/actions/regionsActions";
import { RechercherActivitesRegionsProgrammesAnnees } from "../../store/actions/activitesActions";
import { ClearErrors } from "../../store/actions/errorActions";

import isEmpty from "../../validation/isEmpty";

moment.locale("fr");

class RappProgramme extends Component {
	constructor(props) {
		super(props);
		this.state = {
			date_debut: "",
			date_fin: "",
			programmesSelect: [],
			regionsSelect: []
		};
	}
	componentDidMount = () => {
		this.props.ClearErrors();
		this.props.RechercherProgrammesTous();
		this.props.RechercherRegionsTous();
	};
	componentWillUnmount = () => {
		this.props.ClearErrors();
	};

	componentDidUpdate = (prevProps, prevState) => {
		if (
			prevProps.activites.activites !== this.props.activites.activites &&
			!isEmpty(this.props.activites.activites)
		) {
			this.SendXSLX({ nom: "Liste des activités", content: this.props.activites.activites });
		}
	};

	setMultipleSelect = e => {
		var values = [...e.target.selectedOptions].map(elem => {
			return elem.value;
		});
		this.setState({ [e.target.name]: values });
	};

	setDate = e => {
		//console.log("Date valide",moment.utc(e.target.value).isValid());

		this.setState({ [e.target.name]: moment.utc(e.target.value).format("YYYY-MM-DD") });
	};

	CreateYearList = () => {
		const listStart = new Date("2016-07-01");
		const now = new Date();
		const yearList = [];
		let yearToAdd = new Date(listStart);
		do {
			let year = {
				label: `${yearToAdd.getFullYear()} - ${yearToAdd.getFullYear() + 1}`,
				value: yearToAdd
			};
			yearList.push(year);
			yearToAdd = new Date(yearToAdd);
			yearToAdd.setFullYear(yearToAdd.getFullYear() + 1);
		} while (yearToAdd <= now);
		return yearList;
	};

	onSubmit = e => {
		e.preventDefault();
		this.props.ClearErrors();
		this.props.RechercherActivitesRegionsProgrammesAnnees(
			this.state.date_debut,
			this.state.date_fin,
			this.state.programmesSelect,
			this.state.regionsSelect
		);
	};

	FlattenObject = ob => {
		var toReturn = {};

		for (var i in ob) {
			if (!ob.hasOwnProperty(i)) continue;

			if (typeof ob[i] == "object") {
				var flatObject = this.FlattenObject(ob[i]);
				for (var x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) continue;

					toReturn[i + " " + x] = flatObject[x];
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	};

	FormatActivites = data => {


		data.content = data.content.map(elem => {
			elem.date_debut = elem.date_debut === null ? "" : moment.utc(elem.date_debut).format('YYYY-MM-DD 00:00:00');
			elem.date_fin = elem.date_fin === null ? "" : moment.utc(elem.date_fin).format('YYYY-MM-DD 00:00:00');
			elem.mois_debut = elem.date_debut === null ? "" : `${(+moment.utc(elem.date_debut).month() + 1).toString().padStart(2, "0")} - ${moment.utc(elem.date_debut).format("MMMM")} `;
			delete elem["__v"];
			delete elem["modification_date"];
			delete elem["modification_auteur"];
			delete elem["etablissement"]["__v"];
			delete elem["etablissement"]["_id"];
			delete elem["etablissement"]["region"];
			delete elem["etablissement"]["indice_def_rang_decile_sfr"];
			delete elem["etablissement"]["indice_def_sfr"];
			delete elem["etablissement"]["region_admin"];
			delete elem["etablissement"]["regroupement"]["__v"];
			delete elem["etablissement"]["regroupement"]["_id"];
			delete elem["etablissement"]["regroupement"]["region"];
			delete elem["niveau_scolaire"]["__v"];
			delete elem["niveau_scolaire"]["_id"];
			delete elem["niveau_scolaire"]["code"];
			delete elem["niveau_scolaire"]["ordre_affichage"];
			delete elem["programme"]["__v"];
			delete elem["programme"]["_id"];
			delete elem["programme"]["programme_code"];
			delete elem["programme"]["type_activite_code"];
			delete elem["region"]["__v"];
			delete elem["region"]["_id"];
			delete elem["region"]["code"];

			return this.FlattenObject(elem);
		});
		return data;
	};

	SendXSLX = data => {
		const test = this.FormatActivites(data);
		const file = this.ObjectToXLSX(test);
		this.Download(file, data.nom);
	};

	Download = (file, nom) => {
		const blob = new Blob([file], { type: "application/octet-stream" });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement("a");
		a.setAttribute("hidden", "");
		a.setAttribute("href", url);
		a.setAttribute("download", nom + ".xlsx");
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	ObjectToXLSX = data => {
		//CREATE WORKBOOK
		const wb = XLSX.utils.book_new();
		wb.Props = {
			Title: data.nom,
			Subject: "Rapport statistiques",
			Author: "Réseau Technoscience",
			CreatedDate: new Date()
		};
		//CREATE WORKSHEET
		wb.SheetNames.push(data.nom);
		const header = [
			"_id",
			"no_reference",
			"date_debut",
			"date_fin",
			"mois_debut",
			"duree",
			"nb_activites",
			"programme programme_nom",
			"programme type_activite_nom",
			"niveau_scolaire niveau",
			"niveau_scolaire palier",
			"nb_total",
			"nb_garcons",
			"nb_filles",
			"nb_solo",
			"nb_duo",
			"nb_trio",
			"nb_quatuor",
			"nb_visiteurs",
			"visibilite",
			"nb_enseignants",
			"langue",
			"premiere_participation",
			"adaptation_scolaire",
			"evaluation_recue",
			"prix",
			"thematique",
			"nom_animateur",
			"notes",
			"region nom",
			"etablissement nom",
			"etablissement code_organisme",
			"etablissement type_etablissement",
			"etablissement reseau_ens",
			"etablissement regroupement nom",
			"etablissement regroupement code_regroupement",
			"etablissement regroupement type",
			"etablissement contact_tel",
			"etablissement contact_adresse",
			"etablissement contact_ville",
			"etablissement contact_province",
			"etablissement contact_code_postal",
			"etablissement caract_nb_eleves_total",
			"etablissement caract_niv_prescolaire",
			"etablissement caract_niv_primaire",
			"etablissement caract_niv_secondaire",
			"etablissement caract_niv_professionnel",
			"etablissement caract_niv_adulte",
			"etablissement caract_niv_collegial",
			"etablissement caract_niv_universitaire",
			"etablissement caract_lang_francais",
			"etablissement caract_lang_anglais",
			"etablissement caract_lang_autre",
			"etablissement indice_def_maj",
			"etablissement indice_def_imse",
			"etablissement indice_def_rang_decile_imse",
			"responsable_prenom",
			"responsable_nom",
			"responsable_courriel",
			"responsable_tel1",
			"responsable_tel2"
		];
		//ADD DATA TO SHEET
		const ws = XLSX.utils.json_to_sheet(data.content, { header });
		//INCLUDE WORKSHEET TO WORKBOOK
		wb.Sheets[data.nom] = ws;

		//SET  column to date type
		var range = XLSX.utils.decode_range(ws['!ref']);
		for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
			const dateCell = ws[XLSX.utils.encode_cell({ r: rowNum, c: 2 })];
			if (rowNum > 0 && dateCell.v !== "") {
				dateCell.t = 'd';
			} else {
				dateCell.t = 's';
			}
		}
		for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
			const dateCell = ws[XLSX.utils.encode_cell({ r: rowNum, c: 3 })];
			if (rowNum > 0 && dateCell.v !== "") {
				dateCell.t = 'd';
			} else {
				dateCell.t = 's';
			}
		}

		//CREATE FILE
		const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

		//CONVERT TO BINARY TO SEND
		const buf = new ArrayBuffer(wbout.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i < wbout.length; i++) {
			view[i] = wbout.charCodeAt(i) & 0xff;
		}
		return buf;
	};

	render() {
		const error = this.props.errors;
		const listeRegions = this.props.auth.utilisateur.regions.map((region, index) => {
			return (
				<option key={region._id} value={region._id}>
					{region.nom}
				</option>
			);
		});

		const listeProgrammes = this.props.programmes.programmes.map((programme, index) => {
			return (
				<option
					key={programme.programme_code.toString() + index}
					value={programme.programme_code}
				>
					{programme.programme_nom}
				</option>
			);
		});

		return (
			<div className="mt-3 mb-3">
				<p className="mb-3">
					Le module d'exportation ci-dessous pour permet d'avoir accès à toutes les
					données pour une période, une ou plusieurs activités et une région donnée.{" "}
					<br />
					<br />
					<i className="fas fa-exclamation-triangle" /> Un module spécifique pour
					l'exportation vers le fichier de bilan des <strong>Trousses Envirovolt</strong>,
					des <strong>Valises 00Watt</strong> et des{" "}
					<strong>Produits Clés en main</strong> est{" "}
					<Link to="/bilan-hq">disponible ici</Link>.
				</p>
				<hr />
				<form id="exportRapportForm" noValidate onSubmit={this.onSubmit}>
					<div className="form-group row">
						<div className="col md-12">
							<h3>Exportation de toutes les activités</h3>
							<h5>
								Astuce: Vous pouvez choisir plusieurs options en gardant la touche{" "}
								<kbd>Ctrl</kbd> ou <kbd>Cmd</kbd> enfoncée.
							</h5>
						</div>
					</div>
					<div className="form-group row">
						<div className="col md-6">
							<label htmlFor="date_debut">Début de la recherche</label>
							<input
								type="date"
								name="date_debut"
								id="date_debut"
								value={this.state.date_debut}
								onChange={this.setDate}
								className={classnames("form-control", {
									"is-invalid": error.date_debut
								})}
							/>
							{error && <div className="invalid-feedback">{error.date_debut}</div>}
						</div>
						<div className="col md-6">
							<label htmlFor="date_fin">Fin de la recherche</label>
							<input
								className={classnames("form-control", {
									"is-invalid": error.date_fin
								})}
								type="date"
								name="date_fin"
								id="date_fin"
								value={this.state.date_fin}
								onChange={this.setDate}
							/>
							{error && <div className="invalid-feedback">{error.date_fin}</div>}
						</div>
					</div>
					<div className=" form-group row">
						<div className="col md-6">
							<label htmlFor="programmesSelect">Programmes</label>
							<select
								className={classnames("form-control", {
									"is-invalid": error.programmesTab
								})}
								name="programmesSelect"
								id="programmesSelect"
								size="10"
								multiple
								onChange={this.setMultipleSelect}
							>
								{listeProgrammes}
							</select>
							{error && <div className="invalid-feedback">{error.programmesTab}</div>}
						</div>
						<div className="col md-6">
							<label htmlFor="regionsSelect">Organismes régionaux</label>
							<select
								className={classnames("form-control", {
									"is-invalid": error.regionsTab
								})}
								name="regionsSelect"
								id="regionsSelect"
								size="10"
								multiple
								onChange={this.setMultipleSelect}
							>
								{listeRegions}
							</select>
							{error && <div className="invalid-feedback">{error.regionsTab}</div>}
						</div>
					</div>
					<div className="row">
						<div className="col md-6">
							<button className="btn btn-reseau btn-block">Exporter (Excel)</button>
						</div>
					</div>
					{this.props.errors.msg && (
						<div className="row mt-1 mb-1">
							<div className="col mx-3 alert alert-danger" role="alert">
								{this.props.errors.msg}
								<button
									onClick={this.props.ClearErrors}
									type="button"
									className="close"
									data-dismiss="alert"
									aria-label="Close"
								>
									<span aria-hidden="true">&times;</span>
								</button>
							</div>
						</div>
					)}
				</form>
			</div>
		);
	}
}

const mapStateToProps = state => ({
	regions: state.regions,
	programmes: state.programmes,
	auth: state.auth,
	activites: state.activites,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{
		RechercherProgrammesTous,
		RechercherRegionsTous,
		RechercherActivitesRegionsProgrammesAnnees,
		ClearErrors
	}
)(RappProgramme);
