import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";

import {
    RechercherProgrammesTous,
    RechercherTypesParProgramme,
    SetSelectionProgramme,
    ClearSelectionProgramme,
    SetSelectionActivite,
    ClearSelectionActivite,
    ResetProgramme
} from "../../store/actions/programmesActions";

class SelectProgrammes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            programme_nom: "",
            programme_code: "",
            activite_nom: "",
            activite_code: "",
            error: this.props.error
        };
        this.initialState = {
            programme_nom: "",
            programme_code: "",
            activite_nom: "",
            activite_code: "",
            error: this.props.error
        };
    }

    componentDidMount = () => {
        this.props.RechercherProgrammesTous();
    };

    componentWillUnmount = () => {
        this.props.ResetProgramme();
    };

    //Modifier si activite == ""
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.programmes.typesActivites !== this.props.programmes.typesActivites && this.props.programmes.typesActivites !== "") {
            this.setState({ activitesListe: this.props.programmes.typesActivites });
        }

        if (prevProps.programmes.selectionProgramme !== this.props.programmes.selectionProgramme && this.props.programmes.selectionProgramme === "") {
            this.setState(this.initialState);
            this.props.ClearSelectionProgramme();
        }
        if (this.props.programme !== prevProps.programme) {
            const { programme_code, programme_nom, _id } = this.props.programme;
            this.setState({ programme_code: programme_code, programme_nom: programme_nom }, () => {
                this.props.SetSelectionProgramme(programme_code, programme_nom);
                this.setState({ activite_code: _id }, () => {
                    this.props.SetSelectionActivite(_id);
                });
            });
        }
        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    };

    selectProgramme = e => {
        let nom = "";
        e.target.value === "" ? (nom = "") : (nom = e.target.selectedOptions[0].innerHTML);
        this.props.SetSelectionProgramme(e.target.value, nom);
        this.setState({ programme_code: e.target.value });
    };

    selectActivite = e => {
        this.props.SetSelectionActivite(e.target.value);
        this.setState({ activite_code: e.target.value });
    };

    onChange = e => {};

    render() {
        const programmesListe = this.props.programmes.programmes.map((programme, index) => {
            return (
                <option key={index} value={programme.programme_code}>
                    {programme.programme_nom}
                </option>
            );
        });

        const activitesListe = this.props.programmes.typesActivites.map((activite, index) => {
            if (activite.type_activite_code === 2 && !this.props.auth.utilisateur.isAdmin) return null;
            return (
                <option key={index} value={activite._id}>
                    {activite.type_activite_nom}
                </option>
            );
        });

        return (
            <div className=" form-group row">
                <div className="col md-6">
                    <label htmlFor="programme_select">
                        <span className="lead">Programme</span>
                    </label>
                    <select
                        id="programme_select"
                        className="form-control custom-select"
                        onChange={this.selectProgramme}
                        value={this.state.programme_code}
                    >
                        <option value="">Choisir un programme</option>
                        {programmesListe}
                    </select>
                </div>
                <div className="col md-6">
                    <label htmlFor="activite_select">
                        <span className="lead">Activité</span>
                    </label>
                    <select
                        id="activite_select"
                        className={classnames("form-control custom-select", {
                            "is-invalid": this.state.error
                        })}
                        onChange={this.selectActivite}
                        value={this.state.activite_code}
                        disabled={this.props.programmes.typesActivites.length === 0 && "disabled"}
                    >
                        <option value="" />
                        {activitesListe}
                    </select>
                    {this.state.info && <small className="form-text text-muted">{this.state.info}</small>}
                    {this.state.error && <div className="invalid-feedback">{this.state.error}</div>}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    programmes: state.programmes,
    activites: state.activites
});

export default connect(
    mapStateToProps,
    {
        RechercherProgrammesTous,
        RechercherTypesParProgramme,
        SetSelectionProgramme,
        ClearSelectionProgramme,
        SetSelectionActivite,
        ClearSelectionActivite,
        ResetProgramme
    }
)(SelectProgrammes);
