import { combineReducers } from "redux";
import regionsReducer from "./regionsReducer";
import activitesReducer from "./activitesReducer";
import etablissementsReducer from "./etablissementsReducer";
import programmesReducer from "./programmesReducer";
import niveauxScolairesReducer from "./niveauxScolairesReducer";
import responsableReducer from "./responsablesReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import messageReducer from "./messageReducer";
import userReducer from "./userReducer";
import exportationReducer from "./exportationReducer";

export default combineReducers({
    regions: regionsReducer,
    activites: activitesReducer,
    etablissements: etablissementsReducer,
    programmes: programmesReducer,
    niveauxScolaires: niveauxScolairesReducer,
    responsables: responsableReducer,
    auth: authReducer,
    errors: errorReducer,
    message: messageReducer,
    utilisateur: userReducer,
    exportations: exportationReducer
});
