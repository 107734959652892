import React, {Component} from 'react';
import {connect} from 'react-redux';
import {MajIMSE} from '../../store/actions/etablissementsActions';
import isEmpty from '../../validation/isEmpty';

export class MiseAJourIMSE extends Component {
  constructor (props) {
    super (props);

    this.state = {
      imse: [],
    };
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevState.imse !== this.state.imse) {
      this.props.MajIMSE (this.state.imse);
    }
    if (prevProps.etablissements !== this.props.etablissements) {
      if (
        this.props.etablissements &&
        !isEmpty (this.props.etablissements.etablissements)
      ) {
      }
    }
  }

  OnHandleCSV = e => {
    const content = this.fileReader.result;
    this.ProcessData (content);
  };

  OnLoad = e => {
    if (!window.FileReader) return;
    this.fileReader = new FileReader ();
    this.fileReader.onload = this.OnHandleCSV;
    this.fileReader.readAsText (e.currentTarget.files[0]);
  };

  ProcessData = csv => {
    var allTextLines = csv.split (/\r\n|\n/);
    var lines = [];
    let headers = [];

    for (var i = 0; i < allTextLines.length; i++) {
      if (i === 0) {
        headers = allTextLines[i].split (';');
      } else {
        var data = allTextLines[i].split (';');
        var tarr = {};
        for (var j = 0; j < data.length; j++) {
          tarr[headers[j]] = data[j];
        }
        lines.push (tarr);
      }
    }
    this.setState ({imse: lines});
  };

  render () {
    return (
      <div className="container">
        <div className="row">
          <h1>Mise à jour de l'indice de mesure socio-économique (IMSE)</h1>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h2>ATTENTION AVANT DE CONTINUER</h2><br />
          </div>
          <div className="col-md-12">
            <p>
              Vous devez ajouter un fichier csv dont les données sont séparées par des virgules
            </p>
            <p>
              Votre fichier csv ne doit contenir que les colonnes suivantes (garder les barres de soulignement):
            </p>
            <ul>
              <li>code_organisme: Le code unique de l'établissement</li>
              <li>indice_def_sfr: L'indice de défavorisation SFR sur 20</li>
              <li>
                indice_def_rang_decile_sfr: Le rang décile de l'indice de défavorisation SFR sur 10
              </li>
              <li>
                indice_def_imse: Le rang décile de l'indice de défavorisation IMSE sur 20
              </li>
              <li>
                indice_def_rang_decile_imse: Le rang décile de l'indice de défavorisation IMSE sur 10
              </li>
            </ul>
            <p>
              Toute autre colonne modifiera l'école et devra être supprimée du fichier csv avant son enregistrement
            </p>
          </div>
        </div>
        <div className="row pb-5">
          <div className="col-md-12">
            <label htmlFor="fichier_imse">
              <h4>
                Ajouter le fichier csv
              </h4>
            </label><br />
            <input
              type="file"
              accept="text/csv"
              name="fichier"
              id="fichier_imse"
              onChange={this.OnLoad}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({etablissements: state.etablissements});

const mapDispatchToProps = {MajIMSE};

export default connect (mapStateToProps, mapDispatchToProps) (MiseAJourIMSE);
