//IMPORT ACTIONS
import { GET_MESSAGE, CLEAR_MESSAGE, SET_MESSAGE } from "../actions/types";

const initialState = {};

const messageReducer = (state = initialState, action) => {
	switch (action.type) {
		case CLEAR_MESSAGE:
			return initialState;
		case GET_MESSAGE:
			return { ...action.payload };
		case SET_MESSAGE:
			return { ...action.payload };
		default:
			return state;
	}
};

export default messageReducer;
