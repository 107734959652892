import React, {Component} from 'react';
import {connect} from 'react-redux';
import XLSX from 'xlsx';
import classnames from 'classnames';
import moment from 'moment';

import {RechercherProgrammesTous} from '../../store/actions/programmesActions';
import {RechercherRegionsTous} from '../../store/actions/regionsActions';
import {
  RechercherActivitesRegionsProgrammesAnnees,
} from '../../store/actions/activitesActions';
import {ClearErrors} from '../../store/actions/errorActions';

import isEmpty from '../../validation/isEmpty';
class ExpBilanHq extends Component {
  constructor (props) {
    super (props);
    this.state = {
      date_debut: '',
      date_fin: '',
      programmesSelect: [],
      regionsSelect: [],
    };
  }
  componentDidMount = () => {
    this.props.ClearErrors ();
    this.props.RechercherProgrammesTous ();
    this.props.RechercherRegionsTous ();
  };
  componentWillUnmount = () => {
    this.props.ClearErrors ();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.activites.activites !== this.props.activites.activites &&
      !isEmpty (this.props.activites.activites)
    ) {
      this.SendXSLX ({
        nom: 'Bilan HQ' +
          new Date ().toLocaleDateString ('fr-CA', {timeZone: 'UTC'}),
        content: this.props.activites.activites,
      });
    }
  };

  setMultipleSelect = e => {
    var values = [...e.target.selectedOptions].map (elem => {
      return elem.value;
    });
    this.setState ({[e.target.name]: values});
  };

  setDate = e => {
    this.setState ({
      [e.target.name]: moment.utc (e.target.value).format ('YYYY-MM-DD'),
    });
  };

  CreateYearList = () => {
    const listStart = new Date ('2016-07-01');
    const now = new Date ();
    const yearList = [];
    let yearToAdd = new Date (listStart);
    do {
      let year = {
        label: `${yearToAdd.getFullYear ()} - ${yearToAdd.getFullYear () + 1}`,
        value: yearToAdd,
      };
      yearList.push (year);
      yearToAdd = new Date (yearToAdd);
      yearToAdd.setFullYear (yearToAdd.getFullYear () + 1);
    } while (yearToAdd <= now);
    return yearList;
  };

  onSubmit = e => {
    e.preventDefault ();
    this.props.ClearErrors ();
    this.props.RechercherActivitesRegionsProgrammesAnnees (
      this.state.date_debut,
      this.state.date_fin,
      this.state.programmesSelect,
      this.state.regionsSelect
    );
  };

  FlattenObject = ob => {
    var toReturn = {};

    for (var i in ob) {
      if (!ob.hasOwnProperty (i)) continue;

      if (typeof ob[i] == 'object') {
        var flatObject = this.FlattenObject (ob[i]);
        for (var x in flatObject) {
          if (!flatObject.hasOwnProperty (x)) continue;

          toReturn[i + ' ' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  };

  FormatActivites = data => {
    data.content = data.content.map (elem => {
      const flattenElem = this.FlattenObject (elem);
      const newElem = {
        "Code de l'activité": flattenElem._id,
        "Code de l'année": 3,
        'Code du programme': flattenElem['programme programme_code'],
        'Code de la commission scolaire': flattenElem[
          'etablissement regroupement code_regroupement'
        ],
        'Nom de la commission scolaire': flattenElem[
          'etablissement regroupement nom'
        ],
        "Code de l'école": flattenElem['etablissement code_organisme'],
        "Nom de l'école": flattenElem['etablissement nom'],
        'Indice de seuil faible revenu': flattenElem[
          'etablissement indice_def_sfr'
        ],
        'Rang décile (SFR)': flattenElem[
          'etablissement indice_def_rang_decile_sfr'
        ],
        'Indice de milieu socio-économique': flattenElem[
          'etablissement indice_def_imse'
        ],
        'Rang décile (IMSE)': flattenElem[
          'etablissement indice_def_rang_decile_imse'
        ],
        "Nombre d'élèves": flattenElem['etablissement caract_nb_eleves_total'],
        'Numéro de référence': flattenElem['no_reference'],
        Thématique: flattenElem['thematique'],
        "Code type d'activité": flattenElem['programme type_activite_code'],
        'Niveau scolaire': flattenElem['niveau_scolaire code'] === 20
          ? 15
          : flattenElem['niveau_scolaire code'],
        "Nombre d'activités": flattenElem['nb_activites'],
        'Nombre de solo': flattenElem['nb_solo'],
        'Nombre de duo': flattenElem['nb_duo'],
        'Nombre de trio': flattenElem['nb_trio'],
        'Nombre de quatuor': flattenElem['nb_quatuor'],
        'Date de début': flattenElem['date_debut'] === null
          ? ''
          : moment
              .utc (flattenElem['date_debut'])
              .format ('YYYY-MM-DD 00:00:00'),
        'Date de fin': flattenElem['date_fin'] === null
          ? ''
          : moment.utc (flattenElem['date_fin']).format ('YYYY-MM-DD 00:00:00'),
        'En français?': flattenElem['langue'] === 'francais' ? true : false,
        'Nombre de garçons': flattenElem['nb_garcons'],
        'Nombre de filles': flattenElem['nb_filles'],
        "Nombre d'enseignants": flattenElem['nb_enseignants'],
        "Nom de l'animateur": flattenElem['nom_animateur'],
        'Adaptation scolaire?': flattenElem['adaptation_scolaire'],
        'Première demande?': flattenElem['premiere_participation'],
        'Nom du responsable': `${flattenElem['responsable_prenom']} ${flattenElem['responsable_nom']}`,
        'Téléphone du responsable': flattenElem['responsable_tel1'],
        'Courriel du responsable': flattenElem['responsable_courriel'],
        'Rue du responsable': flattenElem['etablissement contact_adresse'],
        'Ville du responsable': flattenElem['etablissement contact_ville'],
        'Code postal du responsable': flattenElem[
          'etablissement contact_code_postal'
        ],
      };

      return newElem;
    });
    return data;
  };

  FormatActivites2 = data => {
    const formattedData = data;
    return formattedData;
  };

  SendXSLX = data => {
    const test = this.FormatActivites (data);
    const format2 = this.FormatActivites2 (test);
    const file = this.ObjectToXLSX (format2);
    this.Download (file, data.nom);
  };

  Download = (file, nom) => {
    const blob = new Blob ([file], {type: 'application/octet-stream'});
    const url = window.URL.createObjectURL (blob);
    const a = document.createElement ('a');
    a.setAttribute ('hidden', '');
    a.setAttribute ('href', url);
    a.setAttribute ('download', nom + '.xlsx');
    document.body.appendChild (a);
    a.click ();
    document.body.removeChild (a);
  };

  ObjectToXLSX = data => {
    //CREATE WORKBOOK
    const wb = XLSX.utils.book_new ();
    wb.Props = {
      Title: data.nom,
      Subject: 'Rapport statistiques',
      Author: 'Réseau Technoscience',
      CreatedDate: new Date (),
    };
    //CREATE WORKSHEET
    wb.SheetNames.push (data.nom);
    const header = [
      "Code de l'activité",
      "Code de l'année",
      'Code du programme',
      'Code de la commission scolaire',
      'Nom de la commission scolaire',
      "Code de l'école",
      "Nom de l'école",
      'Indice de seuil faible revenu',
      'Rang décile (SFR)',
      'Indice de milieu socio-économique',
      'Rang décile (IMSE)',
      "Nombre d'élèves",
      'Numéro de référence',
      'Thématique',
      "Code type d'activité",
      'Niveau scolaire',
      "Nombre d'activités",
      'Nombre de solo',
      'Nombre de duo',
      'Nombre de trio',
      'Nombre de quatuor',
      'Date de début',
      'Date de fin',
      'En français?',
      'Nombre de garçons',
      'Nombre de filles',
      "Nombre d'enseignants",
      "Nom de l'animateur",
      'Adaptation scolaire?',
      'Première demande?',
      'Nom du responsable',
      'Téléphone du responsable',
      'Courriel du responsable',
      'Rue du responsable',
      'Ville du responsable',
      'Code postal du responsable',
    ];
    //ADD DATA TO SHEET
    const ws = XLSX.utils.json_to_sheet (data.content, {header});
    //INCLUDE WORKSHEET TO WORKBOOK
    wb.Sheets[data.nom] = ws;

    //SET  column to date type
    var range = XLSX.utils.decode_range (ws['!ref']);
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      const dateCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 8})];
      if (rowNum > 0 && dateCell.v !== '') {
        dateCell.t = 'n';
      } else {
        dateCell.t = 's';
      }
    }
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      const dateCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 10})];
      if (rowNum > 0 && dateCell.v !== '') {
        dateCell.t = 'n';
      } else {
        dateCell.t = 's';
      }
    }
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      const dateCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 21})];
      if (rowNum > 0 && dateCell.v !== '') {
        dateCell.t = 'd';
      } else {
        dateCell.t = 's';
      }
    }
    for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
      const dateCell = ws[XLSX.utils.encode_cell ({r: rowNum, c: 22})];
      if (rowNum > 0 && dateCell.v !== '') {
        dateCell.t = 'd';
      } else {
        dateCell.t = 's';
      }
    }

    //CREATE FILE
    const wbout = XLSX.write (wb, {bookType: 'xlsx', type: 'binary'});

    //CONVERT TO BINARY TO SEND
    const buf = new ArrayBuffer (wbout.length);
    const view = new Uint8Array (buf);
    for (let i = 0; i < wbout.length; i++) {
      view[i] = wbout.charCodeAt (i) & 0xff;
    }
    return buf;
  };

  render () {
    const error = this.props.errors;
    const listeRegions = this.props.auth.utilisateur.regions.map (
      (region, index) => {
        return (
          <option key={region._id} value={region._id}>
            {region.nom}
          </option>
        );
      }
    );

    const listeProgrammes = this.props.programmes.programmes.map (
      (programme, index) => {
        //ONLY FOR ENVIROVOLT && 00WATT
        if (programme.programme_code !== 40 && programme.programme_code !== 50)
          return null;

        return (
          <option
            key={programme.programme_code.toString () + index}
            value={programme.programme_code}
          >
            {programme.programme_nom}
          </option>
        );
      }
    );

    return (
      <div className="container">
        <div className="row mt-3">
          <div className="col-md-12">
            <h1>Exportation en vue du bilan pour Hydro-Québec</h1>
          </div>
          <div className="col-md-12">
            <h6 className="lead">ENVIROVOLT et 00WATT</h6>
          </div>
        </div>
        <div className="mt-3 mb-3">
          <p className="mb-3">
            <i className="fas fa-exclamation-triangle" />
            {' '}
            Ceci est un module spécifique
            pour l'exportation vers le fichier de bilan des{' '}
            <strong>Trousses Envirovolt</strong>
            {' '}
            et des
            {' '}
            <strong>Valises 00Watt</strong>
            .
          </p>
          <hr />
          <form id="exportRapportForm" noValidate onSubmit={this.onSubmit}>
            <div className="form-group row">
              <div className="col md-12">
                <h3>Exportation de toutes les activités</h3>
                <h5>
                  Astuce: Vous pouvez choisir plusieurs options en gardant la
                  touche <kbd>Ctrl</kbd> ou <kbd>Cmd</kbd> enfoncée.
                </h5>
              </div>
            </div>
            <div className="form-group row">
              <div className="col md-6">
                <label htmlFor="date_debut">Début de la recherche</label>
                <input
                  type="date"
                  name="date_debut"
                  id="date_debut"
                  value={this.state.date_debut}
                  onChange={this.setDate}
                  className={classnames ('form-control', {
                    'is-invalid': error.date_debut,
                  })}
                />
                {error &&
                  <div className="invalid-feedback">{error.date_debut}</div>}
              </div>
              <div className="col md-6">
                <label htmlFor="date_fin">Fin de la recherche</label>
                <input
                  className={classnames ('form-control', {
                    'is-invalid': error.date_fin,
                  })}
                  type="date"
                  name="date_fin"
                  id="date_fin"
                  value={this.state.date_fin}
                  onChange={this.setDate}
                />
                {error &&
                  <div className="invalid-feedback">{error.date_fin}</div>}
              </div>
            </div>
            <div className=" form-group row">
              <div className="col md-6">
                <label htmlFor="programmesSelect">Programmes</label>
                <select
                  className={classnames ('form-control', {
                    'is-invalid': error.programmesTab,
                  })}
                  name="programmesSelect"
                  id="programmesSelect"
                  size="10"
                  multiple
                  onChange={this.setMultipleSelect}
                >
                  {listeProgrammes}
                </select>
                {error &&
                  <div className="invalid-feedback">{error.programmesTab}</div>}
              </div>
              <div className="col md-6">
                <label htmlFor="regionsSelect">Organismes régionaux</label>
                <select
                  className={classnames ('form-control', {
                    'is-invalid': error.regionsTab,
                  })}
                  name="regionsSelect"
                  id="regionsSelect"
                  size="10"
                  multiple
                  onChange={this.setMultipleSelect}
                >
                  {listeRegions}
                </select>
                {error &&
                  <div className="invalid-feedback">{error.regionsTab}</div>}
              </div>
            </div>
            <div className="row">
              <div className="col md-6">
                <button className="btn btn-reseau btn-block">
                  Exporter (Excel)
                </button>
              </div>
            </div>
            {this.props.errors.msg &&
              <div className="row mt-1 mb-1">
                <div className="col mx-3 alert alert-danger" role="alert">
                  {this.props.errors.msg}
                  <button
                    onClick={this.props.ClearErrors}
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>}
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  regions: state.regions,
  programmes: state.programmes,
  auth: state.auth,
  activites: state.activites,
  errors: state.errors,
});

export default connect (mapStateToProps, {
  RechercherProgrammesTous,
  RechercherRegionsTous,
  RechercherActivitesRegionsProgrammesAnnees,
  ClearErrors,
}) (ExpBilanHq);
