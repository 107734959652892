import React, {Component} from 'react';
import {withRouter, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {AjouterUtilisateur} from '../../store/actions/adminActions';

import {RechercherRegionsTous} from '../../store/actions/regionsActions';
class AjoutUtilisateurForm extends Component {
  constructor (props) {
    super (props);
    this.state = {
      prenom: '',
      nom: '',
      courriel: '',
      mdp: '',
      mdp2: '',
      isAdmin: false,
      regions: [],
      redirect: false,
    };
    this.initialState = this.state;
    this.onSubmit = this.onSubmit.bind (this);
    this.onChange = this.onChange.bind (this);
    this.onCheck = this.onCheck.bind (this);
    this.clearForm = this.clearForm.bind (this);
    this.retour = this.retour.bind (this);
  }

  componentDidMount () {
    this.props.RechercherRegionsTous ();
    const mdp = 'Science4545';
    this.setState ({mdp, mdp2: mdp});
  }

  onSubmit (e) {
    e.preventDefault ();
    const newUtilisateur = {
      prenom: this.state.prenom,
      nom: this.state.nom,
      courriel: this.state.courriel,
      mdp: this.state.mdp,
      mdp2: this.state.mdp2,
      isAdmin: this.state.isAdmin,
      regions: this.state.regions,
    };
    this.props.AjouterUtilisateur (newUtilisateur);
    this.setState ({redirect: true});
  }
  onChange (e) {
    this.setState ({[e.target.name]: e.target.value});
  }
  onCheck (e) {
    if (e.target.name === 'isAdmin') {
      return this.setState ({isAdmin: e.target.checked});
    }

    const regions = this.state.regions;
    if (e.target.checked) {
      if (!regions.includes (e.target.value)) {
        regions.push (e.target.value);
        this.setState ({regions});
      }
    } else {
      if (regions.includes (e.target.value)) {
        const res = regions.filter (region => {
          return region !== e.target.value;
        });
        this.setState ({regions: res});
      }
    }
  }

  clearForm () {
    this.setState (this.initialState);
  }

  retour () {
    this.setState ({redirect: true});
  }

  render () {
    const redirect =
      this.state.redirect && <Redirect to="/liste-utilisateurs" />;
    const errors = this.props.errors;

    const regTable = this.props.regions.regions.map ((elem, index) => {
      return (
        <div key={elem._id} className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            value={elem._id}
            name={'reg' + index}
            id={`checkReg${index}`}
            checked={this.state[elem.nom]}
            onChange={this.onCheck}
          />
          <label className="form-check-label" htmlFor={`checkReg${index}`}>
            {elem.nom}
          </label>
        </div>
      );
    });
    return (
      <div className="container pb-4 pt-4">
        {redirect}
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>Ajout</h1>
          </div>
          <div className="col-md-12 text-center">
            <p className="lead">Nouvel utilisateur</p>
          </div>
        </div>

        <form onSubmit={this.onSubmit} autoComplete="off">
          <input
            id="username"
            style={{display: 'none'}}
            type="text"
            name="fakeusernameremembered"
          />
          <input
            id="password"
            style={{display: 'none'}}
            type="password"
            name="fakepasswordremembered"
          />
          <div className="row form-group">
            <div className="col-md-6">
              <label htmlFor="prenom">Prénom</label>
              <input
                type="text"
                name="prenom"
                id="prenom"
                onChange={this.onChange}
                placeholder="Inscrire le prénom"
                className={classnames ('form-control', {
                  'is-invalid': errors.prenom,
                })}
                value={this.state.prenom}
              />
              {errors.prenom &&
                <div className="invalid-feedback">{errors.prenom}</div>}
            </div>
            <div className="col-md-6">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                id="nom"
                name="nom"
                onChange={this.onChange}
                placeholder="Inscrire le nom"
                className={classnames ('form-control', {
                  'is-invalid': errors.nom,
                })}
                value={this.state.nom}
              />
              {errors.nom &&
                <div className="invalid-feedback">{errors.nom}</div>}
            </div>
          </div>

          <div className="form-group">
            <label htmlFor="courriel">Courriel</label>
            <input
              autoComplete="nope"
              type="email"
              id="courriel"
              name="courriel"
              onChange={this.onChange}
              aria-describedby="aideCourriel"
              placeholder="Inscrire le courriel"
              className={classnames ('form-control', {
                'is-invalid': errors.courriel,
              })}
              value={this.state.courriel}
            />
            {errors.courriel &&
              <div className="invalid-feedback">{errors.courriel}</div>}
            <small id="aideCourriel" className="form-text text-muted">
              Sera utilisé lors de la connexion de l'utilisateur
            </small>
          </div>

          <div className="form-group form-check">
            <input
              type="checkbox"
              name="isAdmin"
              onChange={this.onCheck}
              id="adminCheck"
              className={classnames ('form-check-input', {
                'is-invalid': errors.admin,
              })}
              checked={this.state.admin}
            />
            {errors.admin &&
              <div className="invalid-feedback">{errors.admin}</div>}
            <label className="form-check-label" htmlFor="adminCheck">
              Est administrateur?
            </label>
          </div>
          <div className="form-group">
            <h3>Régions</h3>
            {regTable}
          </div>
          <div className="row">
            <div className="col md-12">
              <input className="btn btn-reseau" type="submit" value="Envoyer" />
              <input
                className="btn btn-secondary ml-3"
                type="button"
                value="Retourner à la liste"
                onClick={this.retour}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  errors: state.errors,
  utilisateur: state.utilisateur,
  regions: state.regions,
});

AjouterUtilisateur.propTypes = {
  AjouterUtilisateur: PropTypes.func.isRequired,
};

export default withRouter (
  connect (mapStateToProps, {AjouterUtilisateur, RechercherRegionsTous}) (
    AjoutUtilisateurForm
  )
);
