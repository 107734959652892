import React, { Component } from "react";
import { connect } from "react-redux";
import { RechercherNiveauxTous } from "../../store/actions/niveauxScolairesActions";
import isEmpty from "../../validation/isEmpty";
import classnames from "classnames";

class NiveauScolaireSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            regionSelectionnee: "",
            name: this.props.name,
            label: this.props.label,
            id: this.props.id,
            addclass: this.props.addclass,
            options: [],
            error: this.props.error,
            onChange: this.props.onChange,
            info: null,
            selectionNiveau: this.props.value
        };
    }

    componentDidMount() {
        if (isEmpty(this.state.options)) {
            this.props.RechercherNiveauxTous();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.niveauxScolaires !== this.props.niveauxScolaires) {
            this.setState({ options: this.props.niveauxScolaires.niveaux });
        }

        if (prevProps.error !== this.props.error) {
            this.setState({ error: this.props.error });
        }
    }

    SelectionNiveau = e => {
        this.setState({ selectionNiveau: e.target.value });
        this.state.onChange(e);
    };
    render() {
        //Creates list of option tag
        const optionsList = this.state.options.map((option, index) => {
            return (
                <option key={index} value={option._id}>
                    {option.niveau}
                </option>
            );
        });
        return (
            <div className={this.state.addclass}>
                <label htmlFor="niveauSelect">{this.state.label}</label>
                <select
                    name={this.state.name}
                    id="niveauSelect"
                    onChange={this.SelectionNiveau}
                    disabled={this.state.disabled}
                    className={classnames("form-control custom-select", {
                        "is-invalid": this.state.error
                    })}
                    value={this.state.selectionNiveau}
                >
                    <option value="">Choisir le niveau scolaire</option>
                    {optionsList}
                </select>
                {this.state.info && <small className="form-text text-muted">{this.state.info}</small>}
                {this.state.error && <div className="invalid-feedback">{this.state.error}</div>}
            </div>
        );
    }
}
const mapStateToProps = state => ({
    niveauxScolaires: state.niveauxScolaires
});

export default connect(
    mapStateToProps,
    { RechercherNiveauxTous }
)(NiveauScolaireSelect);
