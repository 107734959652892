import React from "react";

export default function MsgBox({ error, info }) {
    return (
        <div className="text-center">
            {info && <small className="form-text text-muted">{info}</small>}
            {error && <small className="text-danger center-text">{error}</small>}
        </div>
    );
}
