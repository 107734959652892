import React, {Component, Fragment} from 'react';
import DoughnutChart from './Template/DougnutChart';
import pattern from 'patternomaly';
import fileSaver from 'file-saver';
import canvasToBlob from 'canvas-toBlob';
export default class Participants extends Component {
  constructor (props) {
    super (props);
    this.state = {
      nb_ecoles_uniques: 0,
      data: {
        labels: [
          'École publique. IMSE 1 à 7',
          'École publique. IMSE 8 à 10',
          'École publique. IMSE non disponible',
          'Écoles privées',
          'Cégeps',
          'Autres',
        ],
        datasets: [
          {
            label: "Établissements d'enseignement",
            data: [12, 19, 2, 13, 3, 2, 0],
            backgroundColor: [
              'rgb(34, 108, 172)',
              'rgb(255, 152, 42)',
              '#aeb8ea',
              pattern.draw ('dot', 'rgb(34, 108, 172)'),
              pattern.draw ('dot', 'rgb(255, 152, 42)'),
              pattern.draw ('dot', '#aeb8ea'),
              'rgb(34, 108, 172)',
              'rgb(255, 152, 42)',
              '#aeb8ea',
              pattern.draw ('dot', 'rgb(34, 108, 172)'),
              pattern.draw ('dot', 'rgb(255, 152, 42)'),
              pattern.draw ('dot', '#aeb8ea'),
            ],
          },
        ],
      },
      options: {
        layout: {
          padding: {
            top: 30,
          },
        },
        legend: {
          position: 'bottom',
        },
        plugins: {
          datalabels: {
            color: '#FFF',
            anchor: 'end',
            align: 'end',
            clamp: false,
            offset: -10,
            backgroundColor: context => {
              return context.dataset.backgroundColor;
            },
            font: {
              size: 11,
              weight: 'bolder',
            },
            borderRadius: 25,
            borderWidth: 2,
            borderColor: '#FFF',
            textAlign: 'center',
            formatter: (value, context) => {
              return (
                (value / this.state.nb_ecoles_uniques * 100).toFixed (0) + '%'
              );
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.updateData ();
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.activites !== this.props.activites) {
      this.updateData ();
    }
  }
  saveImage = () => {
    const canvas = document.querySelector ('#etablissements');
    canvas.toBlobHD (blob => {
      fileSaver.saveAs (blob, 'nbEtablissements.png');
    });
  };
  updateData = () => {
    let labels = [];
    let values = [];
    // Loop through all activities
    //
    const dataEcoles = {
      ecole_publique_IMSE_1a7: 0,
      ecole_publique_IMSE_7a10: 0,
      ecole_publique_IMSE_nd: 0,
      ecole_privee: 0,
      cegep: 0,
    };

    let nb_ecoles = this.props.activites.map ((activite, index) => {
      return activite.etablissement;
    });

    const uniquesEcoles = [];
    const map = new Map ();

    for (const ecole of nb_ecoles) {
      if (!map.has (ecole._id)) {
        map.set (ecole._id, true); // set any value to Map
        uniquesEcoles.push (ecole);
      }
    }
    //console.log(uniquesEcoles);
    uniquesEcoles.map (ecole => {
      if (
        ecole.type_etablissement === 'École' &&
        ecole.reseau_ens === 'Public'
      ) {
        if (ecole.indice_def_rang_decile_imse === null) {
          dataEcoles.ecole_publique_IMSE_nd++;
        } else if (parseInt (ecole.indice_def_rang_decile_imse) >= 7) {
          dataEcoles.ecole_publique_IMSE_7a10++;
        } else {
          dataEcoles.ecole_publique_IMSE_1a7++;
        }
      } else if (ecole.regroupement.code_regroupement === 999999) {
        dataEcoles.ecole_privee++;
      } else if (ecole.regroupement.code_regroupement === 999998) {
        dataEcoles.cegep++;
      } else {
        if (!dataEcoles.hasOwnProperty (ecole.regroupement.type)) {
          dataEcoles[ecole.regroupement.type] = 0;
        }
        dataEcoles[ecole.regroupement.type]++;
      }
      return true;
    });

    labels = Object.keys (dataEcoles).map (key => {
      let valueToReturn = '';
      switch (key) {
        case 'ecole_publique_IMSE_1a7':
          valueToReturn = 'Écoles publiques. IMSE 1 à 7';
          break;
        case 'ecole_publique_IMSE_7a10':
          valueToReturn = 'Écoles publiques. IMSE 7 à 10';
          break;
        case 'ecole_publique_IMSE_nd':
          valueToReturn = 'Écoles publiques. IMSE non disponible';
          break;
        case 'ecole_privee':
          valueToReturn = 'Écoles privées';
          break;
        case 'cegep':
          valueToReturn = 'Cégep';
          break;
        default:
          valueToReturn = key;
      }
      return valueToReturn;
    });
    values = Object.values (dataEcoles);
    //console.log(dataEcoles);
    const dataToUpdate = this.state.data;

    dataToUpdate.labels = labels;
    dataToUpdate.datasets[0].data = values;

    this.setState ({
      data: dataToUpdate,
      nb_ecoles_uniques: uniquesEcoles.length,
    });
  };
  render () {
    return (
      <Fragment>
        <h4 className="text-center">Établissements</h4>
        <h6 className="text-center mb-3">
          Nombre total d'établissements: {this.state.nb_ecoles_uniques}
          <span className="ml-5 btn btn-reseau" onClick={this.saveImage}>
            <i className=" fas fa-camera-retro" />
          </span>
        </h6>
        <DoughnutChart
          id="etablissements"
          data={this.state.data}
          options={this.state.options}
        />
      </Fragment>
    );
  }
}
