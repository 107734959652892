import axios from "axios";
import setAuthToken from "../../utils/setAuthHeader";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, SET_SPINNER } from "./types";

//Register new user
//Redirects to login
export const registerUser = (userData, history) => dispatch => {
    axios
        .post("/api/utilisateur/enregistrement", userData)
        .then(result => {
            history.push("/connexion");
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

//Logs user
//Saves token into localstorage
//Sets current user into store
export const loginUser = userData => dispatch => {
    axios
        .post("/api/utilisateur/connexion", userData)
        .then(result => {
            const { token } = result.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const logoutUser = () => dispatch => {
    //removes from localstorage
    localStorage.removeItem("jwtToken");
    //resets requests authorization header
    setAuthToken(false);
    //Set current user to {}
    //set isAuthenticated to false
    dispatch(setCurrentUser({}));
    //Redirects to login
    window.location.href = "/connexion";
};

export const SetSpinner = val => dispatch => {
    dispatch({ type: SET_SPINNER, payload: val });
};
