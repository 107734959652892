import React, { Component } from "react";

export default class Instructions extends Component {
	render() {
		return (
			<div>
				<div className="container p-3">
					<div className="row">
						<h1 className="col-md-4 display-4">Aide</h1>
					</div>
					<div className="row">
						<div className="col-md-12 mt-3 mb-3">
							<p>
								Vous avez de la difficulté à vous y retrouver dans l'application
								statistiques?
							</p>
							<p className="lead">Dans l'onglet Activité</p>
							<ul className="">
								<li className="">
									Vous pouvez voir l'ensemble des activités pour la/les régions
									qui vous ont été attribuées, les modifier et les supprimer
									s'il-y-a lieu.
								</li>
								<li className="">
									Sous "Ajouter une activité", vous pouvez ajouter une nouvelle
									activité aux statistiques
								</li>
								<li className="">
									Sous "Importer les données", vous pouvez importer vos données du
									fichier excel, cette fonctionnalité est présentement en
									développement.
								</li>
								<li className="">
									Sous "Exporter les données", vous pouvez importer vos données du
									fichier excel, cette fonctionnalité est présentement en
									développement.
								</li>
							</ul>
							<p className="lead">Dans l'onglet Établissement</p>
							<ul className="">
								<li className="">
									Vous pouvez voir la liste des établissements où ont lieu vos
									activités et leurs coordonnées.
								</li>
								<li className="">
									Vous pouvez modifier les coordonnées des établissements à
									l'exception des écoles, des bibliothèques et des municipalités
									car celles-ci proviennent de listes gouvernementales
								</li>
								<li className="">
									Sous "Établissements autres", vous pouvez trouver tous les
									établissements spécifiques à la région qui vous est attribuée
								</li>
								<li className="">
									Si vous décellez des erreurs dans les listes, écrivez à{" "}
									<a href="mailto:mlacassegermain@technoscience.ca">
										mlacassegermain@technoscience.ca
									</a>
									. Les modifications seront effectuées directement dans la liste
									générale afin d'éviter de reproduire les erreurs à nouveau.
								</li>
							</ul>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6 m-auto">
							<p className="lead">Vous ne trouvez pas ce que vous cherchez&nbsp;?</p>
							<p>
								Contactez Maxime Lacasse Germain, développeur Web <br />
								<a href="tel:+1-514-252-3027,3893">
									<i className="fas fa-phone" /> 514 252-3027, poste 3899
								</a>
								<br />
								<a href="mailto:mlacassegermain@technoscience.ca">
									<i className="fas fa-address-card" />{" "}
									mlacassegermain@technoscience.ca
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
