import { SET_CURRENT_USER, SET_SPINNER } from "../actions/types";
import isEmpty from "../../validation/isEmpty";

const initialState = {
    isAuthenticated: false,
    utilisateur: {},
    isWorking: false
};

//Controller for authentication states
export default function(state = initialState, action) {
    switch (action.type) {
        case SET_SPINNER:
            return {
                ...state,
                isWorking: action.payload
            };
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                utilisateur: action.payload
            };
        default:
            return state;
    }
}
