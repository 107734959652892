import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const TextFieldGroup = ({
   name,
   placeholder,
   defaultValue,
   label,
   error,
   info,
   col,
   row,
   maxlength,
   onChange,
   disabled,
   id,
   addclass
}) => {
   return (
      <div className={addclass}>
         <label htmlFor={id}>{label}</label>
         <textarea
            col={col}
            row={row}
            maxLength={maxlength}
            className={classnames("form-control", {
               "is-invalid": error
            })}
            placeholder={placeholder}
            name={name}
            onChange={onChange}
            disabled={disabled}
            id={id}
            defaultValue={defaultValue}
         />
         {info && <small className="form-text text-muted">{info}</small>}
         {error && <div className="invalid-feedback">{error}</div>}
      </div>
   );
};

TextFieldGroup.propTypes = {
   id: PropTypes.string,
   name: PropTypes.string.isRequired,
   placeholder: PropTypes.string,
   defaultValue: PropTypes.string,
   label: PropTypes.string,
   row: PropTypes.string,
   col: PropTypes.string,
   maxlength: PropTypes.string,
   info: PropTypes.string,
   error: PropTypes.string,
   onChange: PropTypes.func.isRequired,
   disabled: PropTypes.string,
   addclass: PropTypes.string
};
TextFieldGroup.defaultProps = {
   type: "text"
};
export default TextFieldGroup;
