import React, { Component } from "react";
import { connect } from "react-redux";
import { RechercherCommissionsScolairesRegion } from "../../store/actions/regroupementsActions";

class Etablissements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listeRegions: []
		};
	}
	componentDidMount() {
		this.props.RechercherCommissionsScolairesRegion();
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.etablissements.regroupements !== this.props.etablissements.regroupements) {
			this.setState({ listeRegions: this.props.etablissements.regroupements.region });
		}
	}

	render() {
		const liste = this.state.listeRegions.map(region => {
			return (
				<div>
					<h2>{region.nom}</h2>
					<ul>
						{region.cs.map(cs => {
							return (
								<li>
									{cs.nom}
									<ul>
										{cs.ecoles.map(ecole => {
											return (
												<li>
													{ecole.code_organisme} || {ecole.nom}
												</li>
											);
										})}
									</ul>
								</li>
							);
						})}
					</ul>
				</div>
			);
		});
		return <div>{liste}</div>;
	}
}
const mapStateToProps = state => ({
	etablissements: state.etablissements
});
export default connect(
	mapStateToProps,
	{ RechercherCommissionsScolairesRegion }
)(Etablissements);
