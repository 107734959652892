import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { RechercherBibliothequesTous } from "../../store/actions/etablissementsActions";
import { SetSpinner } from "../../store/actions/authActions";
import excelExportUtils from "../../utils/excelExportUtils";
import isEmpty from "../../validation/isEmpty";
class listeBibliotheques extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listeEtablissement: this.props.etablissements.etablissements,
            region: "",
            etablissementsFiltres: [],
            etablissementsTries: []
        };
    }

    ExportList = () => {
        const expData = {};
        if (isEmpty(this.state.etablissementsTries)) {
            console.log("vide");
            //TODO: Error msg
            return;
        }
        expData.content = this.state.etablissementsTries.map(etablissement => {
            return {
                region_admin: etablissement.region_admin,
                regroupement_code: etablissement.regroupement.code_regroupement,
                regroupement_type: etablissement.regroupement.type,
                regroupement_nom: etablissement.regroupement.nom,
                _id: etablissement._id,
                nom: etablissement.nom,
                code_organisme: etablissement.code_organisme,
                type_etablissement: etablissement.type_etablissement,
                contact_tel: etablissement.contact_tel,
                contact_adresse: etablissement.contact_adresse,
                contact_ville: etablissement.contact_ville,
                contact_province: etablissement.contact_province,
                contact_code_postal: etablissement.contact_code_postal
            };
        });

        const date = new Date();

        expData.name = `Biblio - ${date.getDate()} ${date.getMonth() + 1} ${date.getFullYear()}`;
        expData.subject = "Liste des bibliothèques";
        excelExportUtils.SendXSLX(expData);
    };
    componentDidMount = () => {
        this.props.RechercherBibliothequesTous();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.etablissements.etablissements !== prevProps.etablissements.etablissements) {
            this.setState({ listeEtablissement: this.props.etablissements.etablissements });
        }
    };

    SetSelect = e => {
        this.setState({ region: e.target.value }, () => {
            this.FiltrerBiblio();
        });
    };

    FiltrerBiblio = async () => {
        const etablissementsFiltres = await this.state.listeEtablissement.filter(biblio => {
            return biblio.regroupement.region.includes(this.state.region);
        });

        this.setState({ etablissementsFiltres }, () => {
            const etablissementsTries = this.state.etablissementsFiltres;
            etablissementsTries.sort(this.Compare("contact_ville", 1));
            this.setState({ etablissementsTries });
        });
    };

    Sort = e => {
        const order = e.target.dataset.order;
        const etablissementsFiltres = this.state.etablissementsFiltres;
        etablissementsFiltres.sort(this.Compare(e.target.name, order));
        this.setState({ etablissementsTries: etablissementsFiltres });
        e.target.dataset.order *= -1;
    };

    Compare = (property, order) => {
        return function(a, b) {
            if (property === "nom" || property === "contact_ville") {
                return a[property].localeCompare(b[property]) * order;
            }

            const elemA = a[property] !== null ? a[property] : null;
            const elemB = b[property] !== null ? b[property] : null;

            return (elemA === null) - (elemB === null) || (elemA - elemB) * order;
        };
    };

    OnSubmit = e => {
        e.preventDefault();
    };

    render() {
        const error = this.props.errors;
        const listeRegions = this.props.auth.utilisateur.regions.map((region, index) => {
            return (
                <option key={region._id} value={region._id}>
                    {region.nom}
                </option>
            );
        });
        const listeBiblios = this.state.etablissementsTries.map((ecole, index) => {
            return (
                <div className="card" key={"card" + ecole._id}>
                    <div className="card-header p-0 d-flex flew-row align-items-center justify-content-between" id={index}>
                        <h5 className="mb-0 ">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#ecole" + ecole._id}
                                aria-expanded="true"
                                aria-controls={"ecole" + ecole._id}
                            >
                                <small>
                                    <strong>{ecole.nom}</strong> - {ecole.contact_ville}&emsp;&emsp;|&emsp;&emsp;
                                    {ecole.regroupement.nom}
                                </small>
                            </button>
                        </h5>
                    </div>
                    <div id={"ecole" + ecole._id} className="collapse" aria-labelledby={index} data-parent="#listeEcoles">
                        <div className="card-body d-flex flex-row">
                            <div className="d-flex flex-column flex-grow-1">
                                <h5>Contact</h5>
                                <div className="d-flex flex-row pl-3">
                                    <p className="px-2">
                                        <i className="fas fa-phone" />
                                        &nbsp;{ecole.contact_tel}
                                    </p>
                                    <p className="px-2">
                                        <i className="fas fa-id-card" />
                                        &nbsp;{ecole.contact_adresse}, &emsp;{ecole.contact_ville}&emsp; {ecole.contact_code_postal}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="container p-3">
                <div className="row">
                    <h1 className="col md-4 display-4">Liste des bibliothèques</h1>
                </div>
                <div className="row pl-3 py-3">
                    <button className="btn btn-reseau" onClick={this.ExportList}>
                        Exporter la liste affichée (Excel)
                    </button>
                </div>
                <div className="row">
                    <div className="col md-4">
                        <label htmlFor="regionsSelect">Organismes régionaux</label>
                        <form onSubmit={this.onSubmit}>
                            <select
                                className={classnames("form-control custom-select", {
                                    "is-invalid": error.regionsTab
                                })}
                                name="regionsSelect"
                                id="regionsSelect"
                                onChange={this.SetSelect}
                                value={this.state.region}
                            >
                                <option value="">Choisissez une région</option>

                                {listeRegions}
                            </select>
                            {error && <div className="invalid-feedback">{error.regionsTab}</div>}
                        </form>
                    </div>
                </div>

                <div className="accordion my-3" id="listeEcoles">
                    <h5>Astuce: Les bibliothèques sont triées selon l'ordre alphabétique des municipalités</h5>

                    {listeBiblios}
                </div>

                {this.props.errors.msg && (
                    <div className="row mt-1 mb-1">
                        <div className="col mx-3 alert alert-danger" role="alert">
                            {this.props.errors.msg}
                            <button onClick={this.props.ClearErrors} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProp = state => ({
    etablissements: state.etablissements,
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProp,
    { RechercherBibliothequesTous, SetSpinner }
)(listeBibliotheques);
