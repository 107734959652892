import XLSX from "xlsx";

const excelExportUtils = {};

excelExportUtils.SendXSLX = async data => {
    const file = await excelExportUtils.ObjectToXLSX(data);
    excelExportUtils.Download(file, data.name);
};

excelExportUtils.Download = (file, name) => {
    const blob = new Blob([file], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", name + ".xlsx");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

excelExportUtils.ObjectToXLSX = data => {
    //CREATE WORKBOOK
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: data.name,
        Subject: data.subject,
        Author: "Réseau Technoscience",
        CreatedDate: new Date()
    };
    //CREATE WORKSHEET
    wb.SheetNames.push(data.name);

    //ADD DATA TO SHEET
    const ws = XLSX.utils.json_to_sheet(data.content);

    //INCLUDE WORKSHEET TO WORKBOOK
    wb.Sheets[data.name] = ws;

    //CREATE FILE
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

    //CONVERT TO BINARY TO SEND
    const buf = new ArrayBuffer(wbout.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < wbout.length; i++) {
        view[i] = wbout.charCodeAt(i) & 0xff;
    }
    return buf;
};

export default excelExportUtils;
