import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import TextFieldGroup from "../common/TextFieldGroup";
import { RechercherRegroupementsParRegions } from "../../store/actions/regroupementsActions";
import { RechercherCodeMax, AjouterEtablissement } from "../../store/actions/etablissementsActions";
import { ClearErrors } from "../../store/actions/errorActions";

class AjouterEtablissementForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nom: "",
            type_etablissement: "",
            code_organisme: "",
            reseau_ens: "",
            regroupement: "",
            regroupement_type: "",
            region_admin: "",
            contact_adresse: "",
            contact_ville: "",
            contact_code_postal: "",
            contact_province: "",
            contact_tel: "",
            contact_courriel: "",
            etablissementAjout: false
        };
        this.initialState = {
            nom: "",
            type_etablissement: "",
            code_organisme: "",
            reseau_ens: "",
            regroupement: "",
            regroupement_type: "",
            region_admin: "",
            contact_adresse: "",
            contact_ville: "",
            contact_code_postal: "",
            contact_province: "",
            contact_tel: "",
            contact_courriel: ""
        };
    }
    componentDidMount = () => {
        this.props.RechercherRegroupementsParRegions(this.props.auth.utilisateur.regions);
    };

    componentWillUnmount = () => {
        this.props.ClearErrors();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.etablissements.codeEtablissement !== this.props.etablissements.codeEtablissement) {
            this.setState({ code_organisme: this.props.etablissements.codeEtablissement });
        }
        if (this.props.etablissements.selectionEtablissement !== prevProps.etablissements.selectionEtablissement) {
            if (this.props.etablissements.selectionEtablissement.success === true) {
                this.setState(this.initialState);
                this.setState({ etablissementAjout: true });
            }
        }
    };

    OnSubmit = e => {
        e.preventDefault();
    };

    SetTextField = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    SetSelectField = e => {
        const type = e.target.selectedOptions[0].dataset.type;
        const code = e.target.selectedOptions[0].dataset.code;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.props.RechercherCodeMax(type, code);
        });
    };

    Enregistrer = () => {
        if (this.props.errors !== {}) {
            this.props.ClearErrors();
        }
        this.props.AjouterEtablissement(this.state);
    };

    CacherModal = () => {
        this.setState({ etablissementAjout: false });
    };

    render() {
        const errors = this.props.errors;
        const alertAjout = (
            <div className="alert alert-info alert-dismissible fade show" role="alert">
                Établissement ajouté
                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={this.CacherModal}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        );

        const RegroupementsOptions = this.props.etablissements.regroupements.map((regroupement, index) => {
            if (
                regroupement.type === "commission scolaire" ||
                regroupement.type === "regroupement des établissements privés" ||
                regroupement.type === "Municipalités" ||
                regroupement.type === "Réseau des bibliothèques" ||
                regroupement.type === "fédération des cégeps"
            ) {
                return null;
            }
            return (
                <option key={index} value={regroupement._id} data-type={regroupement.type} data-code={regroupement.code_regroupement}>
                    {regroupement.nom}
                </option>
            );
        });

        return (
            <div className="form-activite">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 m-auto py-5">
                            <h1 className="display-4 text-center mt-20">Ajouter un établissement</h1>
                            <div className="col-md-12">{this.state.etablissementAjout && alertAjout}</div>
                            <form onSubmit={this.OnSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Informations sur l'établissement</h3>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="nom"
                                        name="nom"
                                        onChange={this.SetTextField}
                                        value={this.state.nom}
                                        label="Nom"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.nom}
                                    />
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <label htmlFor="regroupement_select">Sélectionner un regroupement</label>
                                        <select
                                            className={classnames("form-control custom-select", {
                                                "is-invalid": errors.regroupement
                                            })}
                                            id="regroupement_select"
                                            onChange={this.SetSelectField}
                                            name="regroupement"
                                            value={this.state.regroupement}
                                        >
                                            <option value="">Regroupement</option>
                                            {RegroupementsOptions}
                                        </select>
                                        {errors && <div className="invalid-feedback">{errors.regroupement}</div>}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="type_etablissement"
                                        name="type_etablissement"
                                        onChange={this.SetTextField}
                                        value={this.state.type_etablissement}
                                        label="Type d'établissement (Au choix. ex: Centre de loisirs, festival, entreprise, etc)"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.type_etablissement}
                                    />
                                    <div className="col-md-12">
                                        <small>Soyez constant dans le choix du type d'établissement, cela facilitera vos recherches.</small>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <h3>Informations de contact</h3>
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="contact_adresse"
                                        name="contact_adresse"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_adresse}
                                        label="Adresse"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.contact_adresse}
                                    />
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="contact_ville"
                                        name="contact_ville"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_ville}
                                        label="Ville"
                                        type="text"
                                        addclass="col-md-6"
                                        error={errors.contact_ville}
                                    />
                                    <TextFieldGroup
                                        id="contact_code_postal"
                                        name="contact_code_postal"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_code_postal}
                                        label="Code postal"
                                        type="text"
                                        addclass="col-md-3"
                                        error={errors.contact_code_postal}
                                    />
                                    <TextFieldGroup
                                        id="contact_province"
                                        name="contact_province"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_province}
                                        label="Province"
                                        type="text"
                                        addclass="col-md-3"
                                        error={errors.contact_province}
                                    />
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="contact_tel"
                                        name="contact_tel"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_tel}
                                        label="Téléphone"
                                        type="text"
                                        addclass="col-md-6"
                                        error={errors.contact}
                                    />
                                    <TextFieldGroup
                                        id="contact_courriel"
                                        name="contact_courriel"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_courriel}
                                        label="Courriel"
                                        type="text"
                                        addclass="col-md-6"
                                        error={errors.contact}
                                    />
                                </div>
                                <hr />
                                <div className="form-group row justify-content-around">
                                    <input
                                        type="submit"
                                        className="btn btn-reseau btn-block mt-4 col-md-3"
                                        value="Enregistrer"
                                        onClick={this.Enregistrer}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    regroupements: state.regroupements,
    etablissements: state.etablissements,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { RechercherRegroupementsParRegions, RechercherCodeMax, AjouterEtablissement, ClearErrors }
)(AjouterEtablissementForm);
