import { GET_ALL_LEVELS, GET_ERRORS } from "./types";
import axios from "axios";

export const RechercherNiveauxTous = () => dispatch => {
    axios
        .get("/api/niveau-scolaire/tous")
        .then(niveaux => {
            dispatch({ type: GET_ALL_LEVELS, payload: niveaux.data });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
        });
};
