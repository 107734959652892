import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../store/actions/authActions";

import TextFieldGroup from "../common/TextFieldGroup";
import MsgBox from "../common/MsgBox";

class Login extends Component {
	constructor() {
		super();
		this.state = {
			courriel: "",
			mdp: ""
		};
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	//If is authenticated, redirects to dashboard
	//Prevents from login again
	componentDidMount() {
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/accueil");
		}
	}

	//When is logged, redirects to dashboard
	//if errors, sets error states
	componentDidUpdate(prevProps) {
		if (this.props.auth.isAuthenticated) {
			this.props.history.push("/accueil");
		}
		if (this.props.errors !== prevProps.errors) {
			this.setState({ errors: this.props.errors });
		}
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onSubmit(e) {
		e.preventDefault();

		const utilisateur = {
			courriel: this.state.courriel,
			mdp: this.state.mdp
		};

		this.props.loginUser(utilisateur, this.props.history);
	}

	render() {
		const errors = this.props.errors;

		return (
			<div className="register">
				<div className="container">
					<div className="row">
						<div className="col-md-8 m-auto">
							<h1 className="display-4 text-center">Connexion</h1>
							<p className="lead text-center">Utilisateur</p>
							<MsgBox error={errors.msg} info={errors.info} />
							<form noValidate onSubmit={this.onSubmit}>
								<div className="form-group">
									<TextFieldGroup
										type="email"
										name="courriel"
										placeholder="Adresse courriel"
										error={errors.courriel}
										onChange={this.onChange}
										value={this.state.courriel}
										className="form-control"
										id="courriel"
										aria-describedby="emailHelp"
										label="Courriel"
									/>
								</div>
								<div className="form-group">
									<TextFieldGroup
										type="password"
										name="mdp"
										placeholder="Mot de passe"
										error={errors.mdp}
										onChange={this.onChange}
										value={this.state.mdp}
										className="form-control"
										id="mdp"
										aria-describedby="pwdHelp"
										label="Mot de passe"
									/>
								</div>
								<small>
									<Link to="/oubli-mdp">
										Vous avez oublié votre mot de passe?
									</Link>
								</small>
								<div className="form-group">
									<input
										type="submit"
										className="btn btn-reseau btn-block mt-4"
										value="Connexion"
									/>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

//Validates input passed to component
loginUser.propTypes = {
	loginUser: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired
};
//To access the info
const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});
export default connect(
	mapStateToProps,
	{ loginUser }
)(withRouter(Login));
