import React, {Component, Fragment} from 'react';
import BarChart from './Template/BarChart';
import fileSaver from 'file-saver';
import canvasToBlob from 'canvas-toBlob';
export default class NbActivitesParPaliers extends Component {
  constructor (props) {
    super (props);
    this.state = {
      data: {
        labels: [
          'Grand public',
          'Petite enfance',
          'Préscolaire',
          'Primaire',
          'Secondaire',
          'Collégial',
          'Universitaire',
          'Adulte',
          'Autre',
        ],
        datasets: [
          {
            label: "Nb d'activités par paliers",
            data: [12, 34, 154, 12, 51, 34, 3, 65, 13],
            backgroundColor: [
              'rgb(34, 108, 172)',
              'rgb(255, 152, 42)',
              '#aeb8ea',
              'rgb(34, 108, 172)',
              'rgb(255, 152, 42)',
              '#aeb8ea',
              'rgb(34, 108, 172)',
              'rgb(255, 152, 42)',
              '#aeb8ea',
            ],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        layout: {
          padding: 10,
        },
        plugins: {
          datalabels: {
            color: '#FFF',
            anchor: 'center',
            align: 'end',
            clamp: false,
            backgroundColor: context => {
              return context.dataset.backgroundColor;
            },
            borderRadius: 25,
            clip: false,
            font: {
              size: 11,
              weight: 'bolder',
            },
            padding: 6,
            borderRadius: 25,
            borderWidth: 2,
            borderColor: '#FFF',
            textAlign: 'center',
            formatter: (value, context) => {
              return (
                value +
                '\n' +
                (value / this.state.nb_total * 100).toFixed (0) +
                '%'
              );
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.updateData ();
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.activites !== this.props.activites) {
      this.updateData ();
    }
  }
  saveImage = () => {
    const canvas = document.querySelector ('#paliers');
    canvas.toBlobHD (blob => {
      fileSaver.saveAs (blob, 'nbActivitesParPalier.png');
    });
  };
  updateData = () => {
    let labels = [];
    let values = [];
    // Loop through all activities
    //
    let nb_total = 0;
    const data = {};
    this.props.activites.map ((activite, index) => {
      if (!data.hasOwnProperty (activite.niveau_scolaire.palier)) {
        data[activite.niveau_scolaire.palier] = 0;
      }
      //console.log ('ACT', activite);
      nb_total += activite.nb_activites;
      data[activite.niveau_scolaire.palier] += activite.nb_activites;
      return true;
    });

    Object.keys (data).forEach (key => {
      labels.push (key);
      values.push (data[key]);
    });

    const dataToUpdate = this.state.data;

    dataToUpdate.labels = labels;
    dataToUpdate.datasets[0].data = values;

    this.setState ({data: dataToUpdate, nb_total});
  };

  render () {
    return (
      <Fragment>
        <h4 className="text-center">Niveaux scolaires</h4>
        <h6 className="text-center">
          Nombre total d'activités: {this.props.nb_activites}
          <span className="ml-5 btn btn-reseau" onClick={this.saveImage}>
            <i className=" fas fa-camera-retro" />
          </span>
        </h6>
        <BarChart
          id="paliers"
          data={this.state.data}
          height={300}
          options={this.state.options}
        />
      </Fragment>
    );
  }
}
