import React, { Component } from "react";
import { connect } from "react-redux";

class Spinner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isWorking: false
        };
    }
    componentDidMount = () => {
        this.setState({ isWorking: this.props.isWorking });
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.isWorking !== this.props.isWorking) {
            this.setState({ isWorking: this.props.isWorking });
        }
    };

    render() {
        const showSpinner = this.state.isWorking;
        return (
            <div>
                {showSpinner && (
                    <div className="spinner-container fa-5x d-flex justify-content-center align-items-center">
                        <i className=" fas fa-circle-notch fa-spin text-white" />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isWorking: state.auth.isWorking
});

export default connect(mapStateToProps)(Spinner);
