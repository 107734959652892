import {
  GET_ALL_SCHOOLS,
  GET_SCHOOL_BY_CODE,
  GET_MAX_CODE,
  GET_ERRORS,
  GET_ALL_ETABLISSEMENTS,
  CREATE_ETABLISSEMENT,
  SET_SELECTION_ETABLISSEMENT,
  CLEAR_SELECTION_ETABLISSEMENT,
  GET_ETABLISSEMENTS_BY_REGROUPEMENT,
  UPDATE_IMSE,
  SET_SPINNER,
  CLEAR_ERRORS,
  GET_MESSAGE,
  SET_MESSAGE,
} from './types';
import axios from 'axios';

export const RechercherEtablissements = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/tous')
    .then (etablissement => {
      dispatch ({
        type: GET_ALL_ETABLISSEMENTS,
        payload: etablissement.data,
      });
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherCodeMax = (
  type_regroupement,
  code_regroupement
) => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/code-etablissement-max', {
      params: {type_regroupement, code_regroupement},
    })
    .then (etablissement => {
      dispatch ({
        type: GET_MAX_CODE,
        payload: etablissement.data,
      });
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const AjouterEtablissement = activite => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  dispatch ({type: CLEAR_SELECTION_ETABLISSEMENT});
  axios
    .post ('/api/etablissement/creer', activite)
    .then (resultat => {
      resultat.data.success = true;
      dispatch ({type: CREATE_ETABLISSEMENT, payload: resultat.data});
      dispatch ({type: GET_MESSAGE, payload: {message: 'SUCCESS'}});
      dispatch ({type: CLEAR_ERRORS});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, payload: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherEcoleParCode = (code) => dispatch => {
  axios
    .get ('/api/etablissement/code-organisme', {params: {code}})
    .then (ecoles => {
      dispatch ({type: GET_SCHOOL_BY_CODE, payload: ecoles.data});
    })
    .catch (err => {
      dispatch ({type: GET_SCHOOL_BY_CODE, payload: ""});
    });
};

export const RechercherEcole = (critere, searchValue) => dispatch => {
  axios
    .get ('/api/etablissement/critere', {params: {critere}})
    .then (ecoles => {
      dispatch ({type: GET_SCHOOL_BY_CODE, payload: ecoles.data});
    })
    .catch (err => {
      dispatch ({type: GET_SCHOOL_BY_CODE, payload: ""});
    });
};

export const RechercherEcolesTous = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/type', {params: {type_etablissement: 'École'}})
    .then (ecoles => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: ecoles.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherEcolesPubliquesTous = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/ecoles-publiques')
    .then (ecoles => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: ecoles.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherEcolesPriveesTous = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/ecoles-privees')
    .then (ecoles => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: ecoles.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherBibliothequesTous = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/bibliotheques')
    .then (ecoles => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: ecoles.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherMunicipalitesTous = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/municipalites')
    .then (ecoles => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: ecoles.data});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherEtablissementsAutresTous = () => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/tous')
    .then (etablissements => {
      return etablissements.filter (etablissements);
    })
    .then (etablissement => {
      dispatch ({
        type: GET_ALL_ETABLISSEMENTS,
        payload: etablissement.data,
      });
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherEtablissementsParRegroupement = code_regroupement => dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  axios
    .get ('/api/etablissement/regroupement-code', {params: {code_regroupement}})
    .then (etablissements => {
      dispatch ({
        type: GET_ETABLISSEMENTS_BY_REGROUPEMENT,
        payload: etablissements.data,
      });
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const RechercherEcolesParRegions = regions => async dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});
  const tab = regions.map (region => {
    return axios
      .get ('/api/etablissement/region', {params: {regionId: region._id}})
      .then (ecoles => {
        return ecoles;
      });
  });

  await Promise.all (tab)
    .then (ecoles => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: ecoles});
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const MajIMSE = newImse => async dispatch => {
  dispatch ({type: SET_SPINNER, payload: true});

  const tab = newImse.map (ecole => {
    return axios
      .put ('/api/etablissement/admin/maj-imse', ecole)
      .then (ecoleMaj => {
        return ecoleMaj.data;
      });
  });

  await Promise.all (tab)
    .then (etablissements => {
      dispatch ({type: GET_ALL_SCHOOLS, payload: etablissements});
      dispatch ({
        type: SET_MESSAGE,
        payload: {message: etablissements.length + ' écoles modifiées'},
      });
      dispatch ({type: SET_SPINNER, payload: false});
    })
    .catch (err => {
      dispatch ({type: GET_ERRORS, errors: err.response.data});
      dispatch ({type: SET_SPINNER, payload: false});
    });
};

export const SetSelectionEtablissement = id => dispatch => {
  dispatch ({
    type: SET_SELECTION_ETABLISSEMENT,
    payload: id,
  });
};

export const ClearSelectionEtablissement = () => dispatch => {
  dispatch ({
    type: CLEAR_SELECTION_ETABLISSEMENT,
  });
};
