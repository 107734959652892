import { GET_ALL_REGIONS, GET_ERRORS } from "./types";
import axios from "axios";

export const RechercherRegionsTous = () => dispatch => {
    axios
        .get("/api/region/tous")
        .then(regions => {
            dispatch({ type: GET_ALL_REGIONS, payload: regions.data });
        })
        .catch(err => {
            dispatch({ type: GET_ERRORS, errors: err.response.data });
        });
};
