import React, { Component } from "react";
import { connect } from "react-redux";
import isEmpty from "../../validation/isEmpty";
import classnames from "classnames";

class VisibilitySelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.name,
            label: this.props.label,
            id: this.props.id,
            addclass: this.props.addclass,
            error: this.props.error,
            onChange: this.props.onChange,
            info: null,
            selectionVisibilite: this.props.value
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps !== this.props) {
            this.setState({ selectionVisibilite: this.props.value });
        }
    }

    SelectionVisibilite = e => {
        this.setState({ selectionVisibilite: e.target.value });
        this.state.onChange(e);
    };
    render() {
        //Creates list of option tag

        return (
            <div className={this.state.addclass}>
                <label htmlFor="visibilitySelect">{this.state.label}</label>
                <select
                    name={this.state.name}
                    id="visibilitySelect"
                    onChange={this.SelectionVisibilite}
                    disabled={this.state.disabled}
                    className={classnames("form-control custom-select", {
                        "is-invalid": this.state.error
                    })}
                    value={this.state.selectionVisibilite}
                >
                    <option value="">N/A</option>
                    <option value="colloque">
                        Colloque
                    </option>
                    <option value="grand_public">
                        Activité grand public
                    </option>
                    <option value="salon">
                        Salon
                    </option>
                    <option value="atelier">
                        Atelier
                    </option>
                    <option value="formation">
                        Formation ou webinaire
                    </option>
                </select>
                {this.state.info && <small className="form-text text-muted">{this.state.info}</small>}
                {this.state.error && <div className="invalid-feedback">{this.state.error}</div>}
            </div>
        );
    }
}
const mapStateToProps = state => ({
});

export default connect(
    mapStateToProps,
    {}
)(VisibilitySelect);
