import { CLEAR_EXPORTATION_DATA, SET_EXPORTATION_DATA, SET_EXPORTATION_FORMAT, EXPORTATION_DONE } from "../actions/types";

const initialState = {
    data: {},
    format: "",
    success: false
};

const exportationReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EXPORTATION_DATA:
            return { ...state, data: action.payload };
        case CLEAR_EXPORTATION_DATA:
            return initialState;
        case EXPORTATION_DONE:
            return { data: {}, format: "", success: true };
        case SET_EXPORTATION_FORMAT:
            return { ...state, format: action.payload };
        default:
            return state;
    }
};

export default exportationReducer;
