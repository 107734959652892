import React, { Component } from "react";
import { connect } from "react-redux";
import { InitialiserMotDePasse } from "../../store/actions/adminActions";
class InitMotDePasse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ancienMdp: "",
            mdp: "",
            mdp2: "",
            _id: ""
        };
        this.initialState = this.state;
    }
    componentDidMount = () => {
        this.setState({ _id: this.props.auth.utilisateur.id });
    };
    onSubmit = e => {
        e.preventDefault();
        this.props.InitialiserMotDePasse(this.state);
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };
    render() {
        return (
            <div className="container p-5">
                <h1>Bienvenue sur l'application statistique</h1>
                <p>
                    Veuillez modifier votre mot de passe avant de continuer. Ce dernier doit comporter au minimum 8 caractères, une majuscule et un
                    chiffre.
                </p>
                <form onSubmit={this.onSubmit}>
                    <div className="form-group">
                        <label htmlFor="ancienMdp">Mot de passe actuel</label>
                        <input
                            type="password"
                            className="form-control"
                            name="ancienMdp"
                            id="ancienMdp"
                            placeholder="Mot de passe"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mdp">Nouveau mot de passe</label>
                        <input type="password" className="form-control" name="mdp" id="mdp" placeholder="Mot de passe" onChange={this.onChange} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="mdp2">Veuillez confirmer votre nouveau mot de passe</label>
                        <input type="password" className="form-control" name="mdp2" id="mdp2" placeholder="Mot de passe" onChange={this.onChange} />
                    </div>
                    <input type="submit" value="Soumettre" />
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(
    mapStateToProps,
    { InitialiserMotDePasse }
)(InitMotDePasse);
