import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import ParticipantsGraph from './Charts/Participants';
import EcolesGraph from './Charts/Ecoles';
import NbActivitesParMois from './Charts/NbActivitesParMois';
import NbActivitesParPaliers from './Charts/NbActivitesParPaliers';
import NbActivitesParProgramme from './Charts/NbActivitesParProgramme';

import {
  RechercherProgrammesTous,
  RechercherTypesParProgramme,
} from '../../store/actions/programmesActions';
import {RechercherRegionsTous} from '../../store/actions/regionsActions';
import {
  RechercherActivitesRegionTypeActiviteAnnees,
} from '../../store/actions/activitesActions';

import classnames from 'classnames';
import NbActivitesParRegion from './Charts/NbActivitesParRegion';

class Rapports extends Component {
  constructor (props) {
    super (props);
    this.state = {
      date_debut: '',
      date_fin: '',
      programme: '',
      programme_nom: '',
      activite: '',
      activite_nom: '',
      region: '',
      nb_enseignants: 0,
      nb_visiteurs: 0,
      nb_adaptation_scolaire: 0,
      activites_liste: [],
      participants: {
        total: 0,
        garcons: 0,
        fille: 0,
      },
      type_etablissement: {
        total: 0,
        ecoles: {
          total: 0,
          public: 0,
          prive: 0,
          nonDefini: 0,
        },
        CPE: 0,
        municipalite: 0,
      },
      activites: {
        nb_activites: 0,
      },
    };
  }
  componentDidMount = () => {
    this.props.RechercherProgrammesTous ();
    this.props.RechercherTypesParProgramme ();
    this.props.RechercherRegionsTous ();
    const dateActuelle = new Date ();
    let dateDebutDefault = dateActuelle.getMonth () < 6
      ? new Date (
          dateActuelle.getFullYear () - 1,
          6,
          1
        ).toLocaleDateString ('fr-CA', {
          timeZone: 'UTC',
        })
      : new Date (
          dateActuelle.getFullYear (),
          6,
          1
        ).toLocaleDateString ('fr-CA', {
          timeZone: 'UTC',
        });
    let dateFinDefault = dateActuelle.toLocaleDateString ('fr-CA', {
      timeZone: 'UTC',
    });
    this.setState ({date_debut: dateDebutDefault, date_fin: dateFinDefault});
  };

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.activites.activites !== this.props.activites.activites) {
      //console.log ('UPDATE STATS');
      this.updateStats ();
    }
  }
  updateStats = () => {
    const activites = this.state.activites;
    let nb_activites = 0;
    this.props.activites.activites.map (activite => {
      //UPDATE NB ACTIVITES
      nb_activites += activite.nb_activites;
      return true;
    });
    activites.nb_activites = nb_activites;
    this.setState ({activites}, () => {
      this.updateInfos (this.props.activites.activites);
    });
  };

  updateInfos = activites => {
    let nb_enseignants = 0;
    let nb_visiteurs = 0;
    let nb_adaptation_scolaire = 0;

    activites.map (activite => {
      nb_enseignants += activite.nb_enseignants;
      nb_visiteurs += activite.nb_visiteurs;
      nb_adaptation_scolaire += activite.adaptation_scolaire ? 1 : 0;
      return true;
    });
    //console.log(activites, nb_adaptation_scolaire);
    this.setState ({nb_enseignants, nb_visiteurs, nb_adaptation_scolaire});
  };

  setSelectRegion = e => {
    this.setState ({[e.target.name]: e.target.selectedOptions[0].value});
    this.setState ({region_nom: e.target.selectedOptions[0].innerText});
  };

  setSelectProgramme = e => {
    this.setState ({[e.target.name]: e.target.selectedOptions[0].value}, () => {
      this.props.RechercherTypesParProgramme (this.state.programme);
    });
    this.setState ({programme_nom: e.target.selectedOptions[0].innerText});
  };
  setSelectActivite = e => {
    this.setState ({[e.target.name]: e.target.selectedOptions[0].value});
    this.setState ({activite_nom: e.target.selectedOptions[0].innerText});
  };
  setDate = e => {
    this.setState ({[e.target.name]: e.target.value});
  };
  searchActivities = () => {
    if (this.props.auth.utilisateur.isAdmin || this.state.region !== '') {
      this.props.RechercherActivitesRegionTypeActiviteAnnees (
        this.state.date_debut,
        this.state.date_fin,
        this.state.programme,
        this.state.activite,
        this.state.region
      );
    }
  };

  render () {
    const error = {};
    const region_nom = this.state.region_nom
      ? this.state.region_nom
      : 'Tous les organismes régionaux';
    const programme_nom = this.state.programme_nom
      ? this.state.programme_nom
      : 'Tous les programmes';
    const activite_nom = this.state.activite_nom
      ? this.state.activite_nom
      : 'Toutes les activites';
    const date_debut = this.state.date_debut
      ? 'Du ' + this.state.date_debut
      : ' - Début de la période non définie - ';
    const date_fin = this.state.date_fin
      ? ' au ' + this.state.date_fin
      : ' - Fin de la période non définie - ';

    const regions_liste = this.props.auth.utilisateur.regions.map (region => {
      return (
        <option key={region._id} value={region._id}>
          {region.nom}
        </option>
      );
    });
    const types_programme = this.props.programmes.programmes.map (programme => {
      return (
        <option key={programme.programme_code} value={programme.programme_code}>
          {programme.programme_nom}
        </option>
      );
    });
    const types_activite = this.props.programmes.typesActivites.map (
      type_activite => {
        return (
          <option
            key={type_activite._id}
            value={type_activite.type_activite_code}
          >
            {type_activite.type_activite_nom}
          </option>
        );
      }
    );

    return (
      <div className="container py-5">
        <h1>Rapports</h1>

        <div className="row py-1">
          <div className="col-md-3">
            <label htmlFor="date_debut">Début de la période</label>
            <input
              type="date"
              name="date_debut"
              id="date_debut"
              value={this.state.date_debut}
              onChange={this.setDate}
              className={classnames ('form-control', {
                'is-invalid': error.date_debut,
              })}
            />
            {error &&
              <div className="invalid-feedback">{error.date_debut}</div>}
          </div>
          <div className="col-md-3">
            <label htmlFor="date_fin">Fin de la période</label>
            <input
              className={classnames ('form-control', {
                'is-invalid': error.date_fin,
              })}
              type="date"
              name="date_fin"
              id="date_fin"
              value={this.state.date_fin}
              onChange={this.setDate}
            />
            {error && <div className="invalid-feedback">{error.date_fin}</div>}
          </div>
          <div className="col text-right d-flex justify-content-end align-items-end">
            <div className="btn btn-reseau" onClick={this.searchActivities}>
              <i className="fas fa-sync-alt fa-2x" />
            </div>
          </div>
        </div>

        <div className="row py-1">
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="region">Organisme régional</label>
              <select
                className="custom-select"
                name="region"
                id="region"
                onChange={this.setSelectRegion}
                value={this.state.region}
              >
                {this.props.auth.utilisateur.isAdmin &&
                  <option value="">Tous les organismes régionaux</option>}
                {!this.props.auth.utilisateur.isAdmin &&
                  <option value="">Choisir un organisme régional</option>}
                {regions_liste}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="programme">Programme</label>
              <select
                className="custom-select"
                name="programme"
                id="programme"
                onChange={this.setSelectProgramme}
                value={this.state.programme}
              >
                <option value="">Tous les programmes</option>
                {types_programme}
              </select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="activite">Type d'activités</label>
              <select
                className="custom-select"
                name="activite"
                id="activite"
                onChange={this.setSelectActivite}
                value={this.state.activite}
                disabled={types_activite.length <= 0 ? 'disabled' : null}
              >
                <option value="">Toutes les activites</option>
                {types_activite}
              </select>
            </div>
          </div>
        </div>

        <hr />

        <div className="row p-2">
          <div className="col-md-12 text-center">
            <h3>
              Statistiques | {region_nom} - {programme_nom} - {activite_nom}
            </h3>
            <h6>
              Période sélectionnée | {date_debut} {date_fin}
            </h6>
          </div>
        </div>
        <div className="row my-1">
          <div className="col-md-12 p-3 ">
            <NbActivitesParMois
              activites={this.props.activites.activites}
              nb_activites={this.state.activites.nb_activites}
              periode_debut={this.state.date_debut}
              periode_fin={this.state.date_fin}
            />
          </div>
        </div>
        <hr />
        <div className="row my-1">
          <div className="col-md-12 p-3 ">
            <NbActivitesParProgramme
              activites={this.props.activites.activites}
              nb_activites={this.state.activites.nb_activites}
              periode_debut={this.state.date_debut}
              periode_fin={this.state.date_fin}
            />
          </div>
        </div>
        <hr />
        {this.props.auth.utilisateur.isAdmin &&
          <Fragment>
            <div className="row my-1">
              <div className="col-md-12 p-3 ">
                <NbActivitesParRegion
                  activites={this.props.activites.activites}
                  nb_activites={this.state.activites.nb_activites}
                  periode_debut={this.state.date_debut}
                  periode_fin={this.state.date_fin}
                />
              </div>
            </div>
            <hr />
          </Fragment>}

        <div className="row py-3">
          <div className="col-md-4 p-2 row">
            <div className="col-md-12 py-2 mb-3 shadow">
              <ParticipantsGraph
                activites={this.props.activites.activites}
                nb_activites={this.state.activites.nb_activites}
                periode_debut={this.state.date_debut}
                periode_fin={this.state.date_fin}
              />
            </div>
            <div className="col-md-12 py-2 shadow">
              <NbActivitesParPaliers
                activites={this.props.activites.activites}
                nb_activites={this.state.activites.nb_activites}
                periode_debut={this.state.date_debut}
                periode_fin={this.state.date_fin}
              />
            </div>
          </div>
          <div className="col-md-8 p-2  d-flex flex-column justify-content-center">
            <EcolesGraph
              activites={this.props.activites.activites}
              nb_activites={this.state.activites.nb_activites}
              periode_debut={this.state.date_debut}
              periode_fin={this.state.date_fin}
            />
          </div>
        </div>
        <hr />
        <div className="row my-1">
          <div className="col p-3 mx-3 bg-reseau text-white shadow d-flex flex-column justify-content-center">
            <h3 className="text-center">Enseignants</h3>
            <h4 className="text-center">{this.state.nb_enseignants}</h4>
          </div>
          <div className="col p-3 mx-3 shadow d-flex flex-column justify-content-center">
            <h3 className="text-center">Visiteurs</h3>
            <h4 className="text-center">{this.state.nb_visiteurs}</h4>
          </div>
          <div className="col p-3 mx-3 bg-accent shadow d-flex flex-column justify-content-center">
            <h3 className="text-center">
              Activites comportant
              <br /> des groupes <br />
              en adaptation scolaire
            </h3>
            <h4 className="text-center">
              {' '}{this.state.nb_adaptation_scolaire}
            </h4>
          </div>
        </div>
        <hr />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  regions: state.regions,
  programmes: state.programmes,
  auth: state.auth,
  activites: state.activites,
  errors: state.errors,
});

export default connect (mapStateToProps, {
  RechercherActivitesRegionTypeActiviteAnnees,
  RechercherProgrammesTous,
  RechercherTypesParProgramme,
  RechercherRegionsTous,
}) (Rapports);
