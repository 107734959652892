import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const SelectInputGroup = ({
	name,
	defaultValue,
	label,
	error,
	info,
	onChange,
	disabled,
	id,
	addclass,
	options,
	value
}) => {
	//Creates list of option tag
	const optionsList = options.map((option, index) => {
		return (
			<option key={index} value={option.value}>
				{option.name}
			</option>
		);
	});

	return (
		<div className={addclass}>
			<label htmlFor={id}>{label}</label>
			<select
				name={name}
				id={id}
				value={value}
				onChange={onChange}
				disabled={disabled}
				className={classnames("form-control custom-select", {
					"is-invalid": error
				})}
			>
				{optionsList}
			</select>
			{info && <small className="form-text text-muted">{info}</small>}
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

SelectInputGroup.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string.isRequired,
	defaultValue: PropTypes.string,
	label: PropTypes.string,
	info: PropTypes.string,
	error: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	disabled: PropTypes.string,
	addclass: PropTypes.string
};
SelectInputGroup.defaultProps = {
	type: "text"
};
export default SelectInputGroup;
