import {
	ADD_NEW_USER,
	GET_ALL_USERS,
	UPDATE_USER,
	DELETE_USER,
	IMPORTATION_DONE,
	GET_USER,
	GET_ERRORS,
	CLEAR_USER,
	SET_MESSAGE
} from "./types";
import axios from "axios";
import { logoutUser } from "./authActions";

export const AjouterUtilisateur = utilisateur => dispatch => {
	axios
		.post("/api/utilisateur/enregistrement", utilisateur)
		.then(resultat => {
			dispatch({ type: ADD_NEW_USER, payload: resultat.data });
		})
		.then(resultat => {
			dispatch(RechercherUtilisateursTous());
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const ModifierUtilisateur = utilisateur => dispatch => {
	axios
		.put("/api/utilisateur/modifier", utilisateur)
		.then(resultat => {
			dispatch({ type: UPDATE_USER, payload: resultat.data });
		})
		.then(resultat => {
			dispatch(RechercherUtilisateursTous());
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const InitialiserMotDePasse = utilisateurInfos => dispatch => {
	axios
		.put("/api/utilisateur/init-mdp", utilisateurInfos)
		.then(utilisateur => {
			//dispatch(setCurrentUser(utilisateur.data));
			dispatch(logoutUser());
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const EnvoyerCourrielMdpReinit = courriel => dispatch => {
	axios
		.post("/api/utilisateur/oubli-mdp", { courriel })
		.then(message => {
			dispatch({ type: SET_MESSAGE, payload: message.data });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const ReinitMdp = (token, mdp, mdp2) => dispatch => {
	axios
		.put("/api/utilisateur/reinit-mdp", { token, mdp, mdp2 })
		.then(message => {
			dispatch({ type: SET_MESSAGE, payload: message.data });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const RechercherUtilisateursTous = () => dispatch => {
	axios
		.get("/api/utilisateur/tous")
		.then(utilisateurs => {
			dispatch({ type: GET_ALL_USERS, payload: utilisateurs.data });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const RechercherUtilisateurID = utilisateurId => dispatch => {
	axios
		.get("/api/utilisateur/id", { params: { utilisateurId } })
		.then(listeUtilisateurs => {
			dispatch({ type: CLEAR_USER });
			dispatch({ type: GET_USER, payload: listeUtilisateurs.data });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const SupprimerUtilisateur = utilisateurId => dispatch => {
	axios
		.delete("/api/utilisateur/supprimer", { params: { utilisateurId } })
		.then(utilisateur => {
			dispatch({ type: CLEAR_USER });
			dispatch({ type: DELETE_USER, payload: utilisateur.data });
			dispatch(RechercherUtilisateursTous());
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};

export const InitialiserBDD = () => dispatch => {
	axios
		.post("/api/niveau-scolaire/importer")
		.then(resultat => {
			return axios.post("/api/programme/importer");
		})
		.then(resultat => {
			return axios.post("/api/programme/importer");
		})
		.then(resultat => {
			return axios.post("/api/region/importer");
		})
		.then(resultat => {
			return axios.post("/api/regroupement/importer-commission-scolaire");
		})
		.then(resultat => {
			dispatch({ type: IMPORTATION_DONE });
		})
		.catch(err => {
			dispatch({ type: GET_ERRORS, payload: err.response.data });
		});
};
