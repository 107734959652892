import {
  GET_ALL_PROGRAMMES,
  GET_TYPES_PROGRAMME,
  GET_ALL_TYPES_ACTIVITE,
  SET_SELECTION_PROGRAMME,
  SET_SELECTION_ACTIVITE,
  CLEAR_SELECTION_PROGRAMME,
  CLEAR_SELECTION_ACTIVITE,
  RESET_PROGRAMME,
} from '../actions/types';

const initialState = {
  programmes: [],
  typesActivites: [],
  selectionProgramme: '',
  selectionProgrammeNom: '',
  selectionActivite: '',
};

const programmesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_SELECTION_PROGRAMME:
      return {...state, selectionProgramme: '', selectionProgrammeNom: ''};

    case SET_SELECTION_PROGRAMME:
      return {
        ...state,
        selectionProgramme: action.payload.code,
        selectionProgrammeNom: action.payload.nom,
      };

    case CLEAR_SELECTION_ACTIVITE:
      return {...state, typesActivites: [], selectionActivite: ''};

    case SET_SELECTION_ACTIVITE:
      return {
        ...state,
        selectionActivite: action.payload,
      };

    case GET_TYPES_PROGRAMME:
      return {...state, typesActivites: action.payload};

    case GET_ALL_PROGRAMMES:
      return {...state, programmes: action.payload};

    case GET_ALL_TYPES_ACTIVITE:
      return {...state, typesActivites: action.payload};

    case RESET_PROGRAMME:
      return initialState;

    default:
      return state;
  }
};

export default programmesReducer;
