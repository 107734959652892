import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  RechercherActivitesParFiltres,
  SupprimerActivite,
  ResetActivite,
  ClearActivitesListe,
} from '../../store/actions/activitesActions';
import {
  RechercherProgrammesTous,
  RechercherTypeActivitesTous,
} from '../../store/actions/programmesActions';
import {
  RechercherNiveauxTous,
} from '../../store/actions/niveauxScolairesActions';
import { RechercherRegionsTous } from '../../store/actions/regionsActions';
import {
  RechercherRegroupementDistinct,
  RechercherRegroupementsParRegions,
} from '../../store/actions/regroupementsActions';
import { ClearErrors } from '../../store/actions/errorActions';
import { ClearMessage } from '../../store/actions/messageActions';
import Modal from './ActiviteModifierModal';
import DupliquerModal from './ActiviteDupliquerModal';
import moment from 'moment';
import isEmpty from '../../validation/isEmpty';

class ActivitesListe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {
        date_debut: '',
        date_fin: '',
        programme: [],
        region: [],
        no_reference: '',
      },
      limits: {
        sortingkey: '',
        sortingdirection: 1,
        limit: 25,
        offset: 0,
      },
      listeActivites: [],
      modal: '',
    };
  }

  //When mounting comp., get all activities linked to the users' regions
  componentDidMount = () => {
    this.props.ClearErrors();
    this.props.ResetActivite();
    this.props.RechercherProgrammesTous();
    this.props.RechercherRegionsTous();
    this.props.RechercherNiveauxTous();
    this.props.RechercherRegroupementDistinct();
    this.props.RechercherRegroupementsParRegions(
      this.props.auth.utilisateur.regions
    );
    this.props.RechercherTypeActivitesTous();
    this.props.ClearActivitesListe();

    const dateActuelle = new Date();
    let dateDebutDefault = dateActuelle.getMonth() < 6
      ? new Date(
        dateActuelle.getFullYear() - 1,
        6,
        1
      ).toLocaleDateString('fr-CA', {
        timeZone: 'UTC',
      })
      : new Date(
        dateActuelle.getFullYear(),
        6,
        1
      ).toLocaleDateString('fr-CA', {
        timeZone: 'UTC',
      });

    let dateFinDefault = dateActuelle.getMonth() < 6
      ? new Date(
        dateActuelle.getFullYear(),
        5,
        30
      ).toLocaleDateString('fr-CA', {
        timeZone: 'UTC',
      })
      : new Date(
        dateActuelle.getFullYear() + 1,
        5,
        30
      ).toLocaleDateString('fr-CA', {
        timeZone: 'UTC',
      });
    this.setFilters('date_debut', dateDebutDefault);
    this.setFilters('date_fin', dateFinDefault);
  };

  componentWillUnmount = () => {
    this.props.ClearErrors();
    this.props.ResetActivite();
    this.props.ClearActivitesListe();
  };

  //When component updates, refresh list
  componentDidUpdate = prevProps => {
    if (prevProps.activites !== this.props.activites) {
      this.setState({
        listeActivites: this.props.activites.activites,
      });
    }
    if (prevProps.message !== this.props.message) {
      if (this.props.message.message === 'ACTIVITY_UPDATED') {
        this.clearModal();
        this.props.ClearMessage();
        this.props.RechercherActivitesParFiltres(this.state.filters);
      }
    }
  };

  supprimerActivite = e => {
    this.props.SupprimerActivite(
      e.currentTarget.dataset.id,
      this.state.filters
    );
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.RechercherActivitesParFiltres(this.state.filters);
  };

  clearModal = () => {
    document.getElementById('closeModalBtn').click();
    this.setState({ modal: '' });
  };

  modifierActivite = e => {
    const activite = this.props.activites.activites.filter(act => {
      return act._id === e.currentTarget.dataset.id;
    });
    this.showModal('MODIFIER', activite[0]);
  };
  dupliquerActivite = e => {
    const activite = this.props.activites.activites.filter(act => {
      return act._id === e.currentTarget.dataset.id;
    });
    this.showModal('DUPLIQUER', activite[0]);
  };

  showModal = (action, activite) => {
    let modal = null;
    if (action === 'MODIFIER') {
      modal = <Modal clearModal={this.clearModal} activite={activite} />;
    } else if (action === 'DUPLIQUER') {
      modal = (
        <DupliquerModal clearModal={this.clearModal} activite={activite} />
      );
    }

    this.setState({ modal }, () => {
      document.getElementById('modalModifier-btn').click();
    });
  };
  setFilters = (filter, value) => {
    const updatedState = this.state;
    updatedState.filters = {
      ...updatedState.filters,
      [filter]: value,
    };
    this.setState(updatedState);
  };
  onInputChange = e => {
    this.setFilters(e.currentTarget.name, e.currentTarget.value);
  };

  setMultipleSelect = e => {
    var values = [...e.target.selectedOptions].map(elem => {
      return elem.value;
    });
    this.setFilters(e.currentTarget.name, values);
  };

  onSort = e => {
    const limits = this.state.limits;
    const { sortingkey } = e.currentTarget.dataset;

    if (limits.sortingkey !== sortingkey) {
      limits.sortingdirection = 1;
    } else {
      limits.sortingdirection = limits.sortingdirection * -1;
    }

    limits.sortingkey = sortingkey;
    this.setState({ limits }, () => {
      this.orderActivities();
    });
  };

  orderActivities = () => {
    const { limits, listeActivites } = this.state;
    const { sortingkey, sortingdirection } = limits;

    listeActivites.sort((a, b) => {
      if (a === null) {
        return -1;
      }
      if (b === null) {
        return 1;
      }
      switch (sortingkey) {
        case 'nb_garcons':
        case 'nb_filles':
        case 'nb_total':
          return sortingdirection < 0
            ? b[sortingkey] - a[sortingkey]
            : a[sortingkey] - b[sortingkey];
        case 'niveau_scolaire':
          if (a[sortingkey]['niveau'] < b[sortingkey]['niveau']) {
            return -1 * sortingdirection;
          } else {
            return 1 * sortingdirection;
          }
        case 'region':
          if (a[sortingkey]['nom'] < b[sortingkey]['nom']) {
            return -1 * sortingdirection;
          } else {
            return 1 * sortingdirection;
          }
        case 'programme':
          if (a[sortingkey]['programme_nom'] < b[sortingkey]['programme_nom']) {
            return -1 * sortingdirection;
          } else {
            return 1 * sortingdirection;
          }
        case 'activite':
          if (
            a['programme']['type_activite_nom'] <
            b['programme']['type_activite_nom']
          ) {
            return -1 * sortingdirection;
          } else {
            return 1 * sortingdirection;
          }
        case 'regroupement':
          if (
            a['etablissement']['regroupement']['nom'] <
            b['etablissement']['regroupement']['nom']
          ) {
            return -1 * sortingdirection;
          } else {
            return 1 * sortingdirection;
          }

        default:
          if (a[sortingkey] < b[sortingkey]) {
            return -1 * sortingdirection;
          } else {
            return 1 * sortingdirection;
          }
      }
    });
    this.setState({ listeActivites });
  };
  fetchPreviousData = () => { };
  fetchNextData = () => { };
  updateData = direction => { };
  renderEtablissements = (etablissements) => {
    let liste = etablissements.map((etablissement) => {
      // console.log(etablissement.nom);
      return (<div>{etablissement.nom}</div>)
    })
    return liste;
  }
  activitiesTableView = activities => {
    return (
      <table className="table">
        {this.activitiesHeaders()}
        {this.activitiesBody(activities)}
      </table>
    );
  };

  activitiesHeaders = () => {
    return (
      <thead>
        <tr>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="date_debut"
          >
            Date
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="no_reference"
          >
            No de référence
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="region"
          >
            Lieu
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="programme"
          >
            Programme
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="activite"
          >
            Activité
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="niveau_scolaire"
          >
            Niveau
          </th>
          <th>
            Établissements
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="nb_total"
          >
            Nb participants
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="nb_garcons"
          >
            Garçons
          </th>
          <th
            className="clickable-header"
            onClick={this.onSort}
            data-sortingkey="nb_filles"
          >
            Filles
          </th>
          <th>Dupliquer</th>
          <th>Modifier</th>
          <th>Supprimer</th>
        </tr>
      </thead>
    );
  };

  activitiesBody = activities => {
    return (
      <tbody>
        {activities.length === 0 &&
          <tr>
            <td>Aucune activité</td>
          </tr>}
        {activities.length > 0 && activities.map(this.activitiesRows)}
      </tbody>
    );
  };

  activitiesRows = activity => {
    if (activity === null || isEmpty(activity)) {
      return;
    }
    const {
      date_debut,
      no_reference,
      programme,
      niveau_scolaire,
      region,
      nb_total,
      nb_garcons,
      nb_filles,
      etablissement,
      etablissements = []
    } = activity;
    return (
      <tr key={activity._id} className="activity-row">
        <td>{moment.utc(date_debut).format('DD-MM-YYYY')}</td>
        <td>{no_reference}</td>
        <td>{region.nom}</td>
        <td>{programme.programme_nom}</td>
        <td>{programme.type_activite_nom}</td>
        <td>{niveau_scolaire && niveau_scolaire.niveau}</td>
        <td>{etablissements.length < 1 ? etablissement.nom : this.renderEtablissements(etablissements)}</td>
        <td>{nb_total}</td>
        <td>{nb_garcons}</td>
        <td>{nb_filles}</td>
        <td>
          <div className="btn">
            <i
              className="fas fa-copy"
              data-id={activity._id}
              onClick={this.dupliquerActivite}
            />
          </div>
        </td>
        <td>
          <div className="btn">
            <i
              className="fas fa-edit"
              data-id={activity._id}
              onClick={this.modifierActivite}
            />
          </div>
        </td>
        <td>
          <div className="btn">
            <i
              className="fas fa-trash"
              data-id={activity._id}
              onClick={this.supprimerActivite}
            />
          </div>
        </td>
      </tr>
    );
  };

  programmesList = (programme, index) => {
    return (
      <option key={index} value={programme.programme_code}>
        {programme.programme_nom}
      </option>
    );
  };

  regionsList = region => {
    return (
      <option key={region._id} value={region._id}>
        {region.nom}
      </option>
    );
  };

  searchFormView = (filters, regions, programmes) => {
    const { date_debut, date_fin, no_reference, programme, region } = filters;

    return (
      <div className="container">
        <h1>Liste des activités</h1>
        <form onSubmit={this.onSubmit} className="py-3">
          <h3>Filtres</h3>
          <div className="row">
            <div className="form-group col-md-4">
              <label htmlFor="">Début de la période</label>
              <input
                type="date"
                className="form-control"
                name="date_debut"
                id="date_debut"
                onChange={this.onInputChange}
                value={date_debut}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="">Fin de la période</label>
              <input
                type="date"
                className="form-control"
                name="date_fin"
                id="date_fin"
                onChange={this.onInputChange}
                value={date_fin}
              />
            </div>
            <div className="form-group col-md-4">
              <label htmlFor="">Numéro de référence</label>
              <input
                type="text"
                className="form-control"
                name="no_reference"
                id="no_reference"
                onChange={this.onInputChange}
                value={no_reference}
              />
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <label htmlFor="programmesSelect">
                Programmes{' '}
                <small id="helpId" className="form-text text-muted">
                  Gardez la touche CTRL/CMD enfoncée pour sélectionner
                  plusieurs éléments
                </small>
              </label>

              <select
                className="form-control"
                name="programme"
                id="programmesSelect"
                size="5"
                multiple
                onChange={this.setMultipleSelect}
                value={programme}
              >
                {programmes.map(this.programmesList)}
              </select>
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="regionsSelect">
                Organismes régionaux{' '}
                <small id="helpId" className="form-text text-muted">
                  Gardez la touche CTRL/CMD enfoncée pour sélectionner
                  plusieurs éléments
                </small>
              </label>

              <select
                className="form-control"
                name="region"
                id="regionsSelect"
                size="5"
                multiple
                onChange={this.setMultipleSelect}
                value={region}
              >
                {regions.map(this.regionsList)}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <button type="submit" className="btn btn-primary mx-auto">
                Rechercher les activités
              </button>
            </div>
          </div>
        </form>

      </div>
    );
  };

  errorMsg = errors => {
    return (
      errors.msg &&
      <div className="row mt-1 mb-1">
        <div className="col mx-3 alert alert-danger" role="alert">
          {errors.msg}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="p-5">
        <div className="row py-5">
          <div className="col-md-12">
            {this.state.modal}
            {this.searchFormView(
              this.state.filters,
              this.props.regions.regions,
              this.props.programmes.programmes
            )}
            <hr />
            {this.activitiesTableView(this.state.listeActivites)}
            {this.errorMsg(this.props.errors)}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  regions: state.regions,
  programmes: state.programmes,
  activites: state.activites,
  auth: state.auth,
  errors: state.errors,
  message: state.message,
});
export default connect(mapStateToProps, {
  RechercherActivitesParFiltres,
  RechercherProgrammesTous,
  RechercherRegionsTous,
  RechercherTypeActivitesTous,
  RechercherRegroupementDistinct,
  RechercherRegroupementsParRegions,
  RechercherNiveauxTous,
  SupprimerActivite,
  ResetActivite,
  ClearActivitesListe,
  ClearMessage,
  ClearErrors,
})(ActivitesListe);
