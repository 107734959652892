import React, { Component } from "react";
import { connect } from "react-redux";
import { RechercherEcolesPriveesTous } from "../../store/actions/etablissementsActions";
import { SetSpinner } from "../../store/actions/authActions";
import excelExportUtils from "../../utils/excelExportUtils";
import isEmpty from "../../validation/isEmpty";
class listeEcolesPrivees extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listeEtablissement: this.props.etablissements.etablissements,
            region: "",
            etablissementsTries: [],
            activeFilter: true
        };
    }
    ExportList = () => {
        const expData = {};
        if (isEmpty(this.state.etablissementsTries)) {
            console.log("vide");
            //TODO: Error msg
            return;
        }
        expData.content = this.state.etablissementsTries.map(etablissement => {
            return {
                is_active: etablissement.is_active,
                region_admin: etablissement.region_admin,
                _id: etablissement._id,
                nom: etablissement.nom,
                code_organisme: etablissement.code_organisme,
                type_etablissement: etablissement.type_etablissement,
                contact_tel: etablissement.contact_tel,
                contact_adresse: etablissement.contact_adresse,
                contact_ville: etablissement.contact_ville,
                contact_province: etablissement.contact_province,
                contact_code_postal: etablissement.contact_code_postal,
                caract_niv_prescolaire: etablissement.caract_niv_prescolaire,
                caract_niv_primaire: etablissement.caract_niv_primaire,
                caract_niv_secondaire: etablissement.caract_niv_secondaire,
                caract_niv_collegial: etablissement.caract_niv_collegial,
                caract_niv_universitaire: etablissement.caract_niv_universitaire,
                caract_niv_professionnel: etablissement.caract_niv_professionnel,
                caract_niv_adulte: etablissement.caract_niv_adulte,
                caract_lang_francais: etablissement.caract_lang_francais,
                caract_lang_anglais: etablissement.caract_lang_anglais,
                caract_lang_autre: etablissement.caract_lang_autre
            };
        });

        const date = new Date();

        expData.name = `Écoles privées - ${date.getDate()} ${date.getMonth() + 1} ${date.getFullYear()}`;
        expData.subject = "Liste des écoles privées";
        excelExportUtils.SendXSLX(expData);
    };
    componentDidMount = () => {
        this.props.RechercherEcolesPriveesTous();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.etablissements.etablissements !== prevProps.etablissements.etablissements) {
            this.setState({ listeEtablissement: this.props.etablissements.etablissements }, () => {
                this.setState({ etablissementsTries: this.state.listeEtablissement });
            });
        }
    };

    Sort = e => {
        const order = e.target.dataset.order;
        const etablissementsTries = this.state.listeEtablissement;
        etablissementsTries.sort(this.Compare(e.target.name, order));
        this.setState({ etablissementsTries: etablissementsTries });
        e.target.dataset.order *= -1;
    };

    ToggleDisplay = e => {
        this.button = e.currentTarget;
        this.button.classList.toggle("btn-outline-reseau", !this.state.activeFilter);
        this.button.classList.toggle("btn-info", this.state.activeFilter);
        this.setState({ activeFilter: !this.state.activeFilter });
    }

    Compare = (property, order) => {
        return function (a, b) {
            if (a.is_active == false && b.is_active !== false) {
                return 1;
            }
            if (a.is_active !== false && b.is_active == false) {
                return -1;
            }

            if (property === "nom" || property === "contact_ville") {
                return a[property].localeCompare(b[property]) * order;
            }

            const elemA = a[property] !== null ? a[property] : null;
            const elemB = b[property] !== null ? b[property] : null;

            return (elemA === null) - (elemB === null) || (elemA - elemB) * order;
        };
    };

    OnSubmit = e => {
        e.preventDefault();
    };

    render() {
        const listeEcoles = this.state.etablissementsTries.map((ecole, index) => {
            //Si le filter est actif et que l'école n'est pas active, on la cache
            //Si le filter est inactif et que l'école est active, on la cache
            let classActive = "";
            if (this.state.activeFilter && !ecole.is_active) {
                classActive = "d-none";
            } else if (!this.state.activeFilter && ecole.is_active) {
                classActive = "d-none";
            }

            const messageInactif = ecole.is_active !== false ? "" : <em> - École inactive</em>;

            return (
                <div className={"card " + classActive} key={"card" + ecole._id} data-active={ecole.is_active !== false}>
                    <div className="card-header p-0 d-flex flew-row align-items-center justify-content-between" id={index}>
                        <h5 className="mb-0 ">
                            <button
                                className="btn btn-link collapsed"
                                type="button"
                                data-toggle="collapse"
                                data-target={"#ecole" + ecole._id}
                                aria-expanded="true"
                                aria-controls={"ecole" + ecole._id}
                            >
                                <small className={ecole.is_active !== false ? "" : "text-muted"}>
                                    <strong >{ecole.nom}</strong> - {ecole.contact_ville} {ecole.is_active !== false ? "" : messageInactif}
                                </small>
                            </button>
                        </h5>
                    </div>
                    <div id={"ecole" + ecole._id} className="collapse" aria-labelledby={index} data-parent="#listeEcoles">
                        <div className="card-body d-flex flex-row">
                            <div className="d-flex flex-column flex-grow-1">
                                <h5>Contact</h5>
                                <div className="d-flex flex-row pl-3">
                                    <p className="px-2">
                                        <i className="fas fa-phone" />
                                        &nbsp;{ecole.contact_tel}
                                    </p>
                                    <p className="px-2">
                                        <i className="fas fa-id-card" />
                                        &nbsp;{ecole.contact_adresse}, &emsp;{ecole.contact_ville}&emsp; {ecole.contact_code_postal}
                                    </p>
                                </div>
                                <h5>Niveaux scolaires</h5>
                                <div className="d-flex flex-row pl-3">
                                    {ecole.caract_niv_prescolaire ? <p className="px-2 font-weight-light">Préscolaire</p> : null}
                                    {ecole.caract_niv_primaire ? <p className="px-2 font-weight-light">Primaire</p> : null}
                                    {ecole.caract_niv_secondaire ? <p className="px-2 font-weight-light">Secondaire</p> : null}
                                    {ecole.caract_niv_collegial ? <p className="px-2 font-weight-light">Collégial</p> : null}
                                    {ecole.caract_niv_universitaire ? <p className="px-2 font-weight-light">Universitaire</p> : null}
                                    {ecole.caract_niv_adulte ? <p className="px-2 font-weight-light">Ens. aux adultes</p> : null}
                                    {ecole.caract_niv_professionnel ? <p className="px-2 font-weight-light">Ens. professionnel</p> : null}
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-shrink-1 ">
                                <h6>Langue d'enseignement</h6>
                                <div className="d-flex flex-column pl-3">
                                    {ecole.caract_lang_francais && <p className="px-2 font-weight-light">Français</p>}
                                    {ecole.caract_lang_anglais && <p className="px-2 font-weight-light">Anglais</p>}
                                    {ecole.caract_lang_autre && <p className="px-2 font-weight-light">Autre</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
        return (
            <div className="container p-3">
                <div className="row">
                    <h1 className="col md-4 display-4">Liste des écoles privées</h1>
                </div>
                <div className="row pl-3 py-3">
                    <button className="btn btn-reseau" onClick={this.ExportList}>
                        Exporter la liste affichée (Excel)
                    </button>
                </div>

                <div className="accordion my-3" id="listeEcoles">
                    {listeEcoles.length > 0 && (
                        <div className="my-2">
                            <h5>Astuce: Vous pouvez alterner le tri ascendant et descendant en réappuyant sur le bouton de tri.</h5>

                            <button className="btn btn-outline-reseau mx-2" name="nom" data-order="1" onClick={this.Sort}>
                                Trier par nom
                            </button>
                            <button className="btn btn-outline-reseau mx-2" name="contact_ville" data-order="1" onClick={this.Sort}>
                                Trier par ville
                            </button>
                            <button className="btn btn-outline-reseau mx-2" name="toggleActiveSchool" data-order="1" onClick={this.ToggleDisplay}>
                                Afficher les écoles inactives
                            </button>
                        </div>
                    )}

                    {listeEcoles}
                </div>

                {this.props.errors.msg && (
                    <div className="row mt-1 mb-1">
                        <div className="col mx-3 alert alert-danger" role="alert">
                            {this.props.errors.msg}
                            <button onClick={this.props.ClearErrors} type="button" className="close" data-dismiss="alert" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProp = state => ({
    etablissements: state.etablissements,
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProp,
    { RechercherEcolesPriveesTous, SetSpinner }
)(listeEcolesPrivees);
