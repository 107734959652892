import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { ImporterAncienSysteme } from "../../store/actions/importationsActions";
import { ClearErrors } from "../../store/actions/errorActions";

class importationAncienSysteme extends Component {
	constructor(props) {
		super(props);
		this.state = {
			regionsSelect: "",
			selectedFile: "",
			loaded: 0,
			errorList: []
		};
	}
	componentDidMount = () => {
		this.props.ClearErrors();
	};

	componentWillUnmount = () => {
		this.props.ClearErrors();
	};
	componentDidUpdate = (prevProps, prevState) => {
		if (this.props.activites.activites !== prevProps.activites.activites) {
			if (this.props.activites.activites.errors !== undefined) {
				if (this.props.activites.activites.errors.length > 0)
					this.setState({ errorList: this.props.activites.activites.errors });
			}
		}
	};

	onSubmit = e => {
		e.preventDefault();
		if (this.state.regionsSelect === "" || this.state.selectedFile === "") {
			console.log("vide");
			return;
		}
		var data = new FormData();
		data.append("file", this.state.selectedFile, this.state.selectedFile.name);
		data.append("region", this.state.regionsSelect);

		this.props.ImporterAncienSysteme(data);
	};

	handleFile = e => {
		this.setState({ selectedFile: e.target.files[0] });
	};

	SetSelect = e => {
		this.setState({ [e.target.name]: e.target.value });
	};

	render() {
		const errors = this.props.errors;
		const errorsColumns = Object.keys(this.props.errors).map((key, index) => {
			return <li key={index}>{this.props.errors[key]}</li>;
		});

		const errorsMsg = this.state.errorList
			.sort((a, b) => {
				return a.line - b.line;
			})
			.map((msg, index) => {
				console.log(msg);
				if (msg !== undefined && msg !== null) {
					return (
						<li key={msg.line}>
							Ligne {msg.line} | {msg.msg}
						</li>
					);
				} else {
					return null;
				}
			});

		const alertAjout = (
			<div className="alert alert-info alert-dismissible fade show" role="alert">
				Le fichier contient plusieurs erreurs&nbsp;:
				<ul>{errorsColumns}</ul>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-label="Close"
					onClick={this.CacherModal}
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		);

		const alertErrors = (
			<div className="alert alert-info alert-dismissible fade show" role="alert">
				<p>L'importation s'est déroulée correctement.</p>
				<div>
					{errorsMsg && "Une ou plusieurs lignes n'ont pas pu être enregistrées."}
					<br />
					{errorsMsg && <ul>{errorsMsg}</ul>}
				</div>
				<button
					type="button"
					className="close"
					data-dismiss="alert"
					aria-label="Close"
					onClick={this.CacherModal}
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		);
		const listeRegions = this.props.auth.utilisateur.regions.map((region, index) => {
			return (
				<option key={region._id} value={region._id}>
					{region.nom}
				</option>
			);
		});

		return (
			<div className="container">
				<div className="row mt-3">
					<h1>Importation de données</h1>
				</div>
				<div className="row">{errorsColumns.length >= 1 && alertAjout}</div>
				<div className="row">{this.state.errorList.length >= 1 && alertErrors}</div>
				<hr />
				<div className="row mt-3">
					<div className="col md-12 mb-3">
						<h3>Ancien système statistique du Réseau (Extraction Excel)</h3>
						<h5>
							Vous pouvez importer les données précédemment saisies pour 00Watt et
							Envirovolt en utilisant l'extraction du fichier statistiques
						</h5>
						<p>
							<i className="fas fa-exclamation-triangle text-warning" /> Attention:
							pour le moment, le système ne vérifie pas si les informations sont
							entrées en double. Assurez-vous de ne pas réimporter des données déjà
							enregistrées.
						</p>
						<p>
							Il est possible que le système ne puisse pas importer certaines lignes
							de votre fichier s'il ne trouve pas l'école. Vous devrez entrer ces
							lignes manuellement.
						</p>
					</div>
				</div>
				<form id="exportRapportForm" noValidate onSubmit={this.onSubmit}>
					<div className="row form-group">
						<div className="col-md-6 offset-3">
							<label htmlFor="programmesSelect">
								Région pour laquelle vous importez les données
							</label>
							<select
								className={classnames("form-control", {
									"is-invalid": errors.regionsTab
								})}
								name="regionsSelect"
								id="regionsSelect"
								onChange={this.SetSelect}
								value={this.state.regionsSelect}
							>
								<option value="">Choisissez un organisme régional</option>
								{listeRegions}
							</select>
							{errors && (
								<div className="invalid-feedback">{errors.programmesTab}</div>
							)}
						</div>
					</div>
					<div className="row form-group">
						<div className="col-md-6 offset-3">
							<input
								onChange={this.handleFile}
								type="file"
								name="importFile"
								id="importationAncienSysteme"
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							/>
						</div>
					</div>
					<div className="row mb-5">
						<div className="col-md-6 offset-3">
							<button type="submit" className="btn btn-reseau btn-block">
								Importer
							</button>
						</div>
					</div>
				</form>
				{this.props.errors.msg && (
					<div className="row mt-1 mb-1">
						<div className="col mx-3 alert alert-danger" role="alert">
							{this.props.errors.msg}
							<button
								onClick={this.props.ClearErrors}
								type="button"
								className="close"
								data-dismiss="alert"
								aria-label="Close"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors,
	activites: state.activites
});
export default connect(
	mapStateToProps,
	{ ImporterAncienSysteme, ClearErrors }
)(importationAncienSysteme);
