import React, { Component } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import TextFieldGroup from '../common/TextFieldGroup'
import { RechercherRegroupementsParRegions } from '../../store/actions/regroupementsActions'
import {
    RechercherEcoleParCode,
    AjouterEtablissement,
} from '../../store/actions/etablissementsActions'
import { ClearErrors } from '../../store/actions/errorActions'
import { SetMessage } from '../../store/actions/messageActions'
import CheckboxInputGroup from '../common/CheckboxInputGroup'

class AjouterEcoleForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nom: '',
            type_etablissement: 'École',
            code_organisme: '',
            reseau_ens: '',
            regroupement: '',
            regroupement_type: '',
            contact_adresse: '',
            contact_ville: '',
            contact_code_postal: '',
            contact_province: 'Québec',
            contact_tel: '',
            contact_courriel: '',
            etablissementAjout: false,
            caract_niv_prescolaire: false,
            caract_niv_primaire: false,
            caract_niv_secondaire: false,
            caract_niv_professionnel: false,
            caract_niv_adulte: false,
            caract_niv_collegial: false,
            caract_niv_universitaire: false,
            caract_lang_francais: false,
            caract_lang_anglais: false,
            caract_lang_autre: false,

            etablissementTrouve: "",
            ecoleAjout: false
        }
        this.initialState = {
            nom: '',
            type_etablissement: 'École',
            code_organisme: '',
            reseau_ens: '',
            regroupement: '',
            regroupement_type: '',
            contact_adresse: '',
            contact_ville: '',
            contact_code_postal: '',
            contact_province: 'Québec',
            contact_tel: '',
            contact_courriel: '',
            caract_niv_prescolaire: false,
            caract_niv_primaire: false,
            caract_niv_secondaire: false,
            caract_niv_professionnel: false,
            caract_niv_adulte: false,
            caract_niv_collegial: false,
            caract_niv_universitaire: false,
            caract_lang_francais: false,
            caract_lang_anglais: false,
            caract_lang_autre: false,
            etablissementTrouve: "",
            ecoleAjout: false
        }
    }
    componentDidMount = () => {
        this.props.RechercherRegroupementsParRegions(
            this.props.auth.utilisateur.regions,
        )
    }

    componentWillUnmount = () => {
        this.props.ClearErrors()
    }

    componentDidUpdate = (prevProps, prevState) => {
    }

    OnSubmit = (e) => {
        e.preventDefault()
    }

    SetTextField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    SetCheckBox = (e) => {
        this.setState({ [e.target.name]: e.target.checked })
    }

    SetCode = (e) => {
        this.props.RechercherEcoleParCode(e.target.value);
        this.setState({ [e.target.name]: e.target.value })
    }

    SetSelectField = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    Enregistrer = () => {
        if (this.props.errors !== {}) {
            this.props.ClearErrors()
        }
        if (this.Validate()) {
            this.props.AjouterEtablissement(this.state);
            this.setState(this.initialState);
            document.querySelectorAll("input[type='checkbox']").forEach((element) => {
                element.checked = false;
            })

            this.setState({ "ecoleAjout": true });
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    CacherModal = () => {
        this.setState({ etablissementAjout: false })
    }

    RenderAvertissement = () => {
        if (this.props.etablissements.rechercheEtablissement === "") return null;

        return (
            <div className='col-md-12'><small>Attention, un établissement avec le même code du nom "{this.props.etablissements.rechercheEtablissement[0].nom}" existe déjà. Si vous enregistrez, deux écoles seront créées avec le même code.</small></div>
        )
    }

    Validate = () => {
        const {
            code_organisme,
            nom,
            regroupement,
            type_etablissement,
            contact_adresse,
            contact_code_postal,
            contact_province,
            contact_ville,
            contact_tel,
            contact_courriel
        } = this.state;

        const keysToCheck = {
            code_organisme,
            nom,
            regroupement,
            type_etablissement,
            contact_adresse,
            contact_code_postal,
            contact_province,
            contact_ville
        };
        const validationList = [];
        for (const key in keysToCheck) {
            if (keysToCheck[key] === "") {
                validationList.push(false);
            }
        }
        if (contact_courriel === "" && contact_tel === "") validationList.push(false);

        return validationList.includes(false) === false;
    }

    render() {
        const errors = this.props.errors
        const alertAjout = (
            <div
                className="alert alert-info alert-dismissible fade show"
                role="alert"
            >
                École ajoutée
                <button
                    type="button"
                    className="close"
                    data-dismiss="alert"
                    aria-label="Close"
                    onClick={this.CacherModal}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        )

        const RegroupementsOptions = this.props.etablissements.regroupements
            .sort((a, b) => {
                let nomA = a.nom.replace(/^(du\s|de\sl'|de\sla\s|des\s|de\s|)/, '')
                let nomB = b.nom.replace(/^(du\s|de\sl'|de\sla\s|des\s|de\s|)/, '')
                if (
                    a.type === 'regroupement des établissements privés' ||
                    b.type === 'regroupement des établissements privés'
                )
                    return -1
                return nomA.localeCompare(nomB)
            })
            .map((regroupement, index) => {
                //console.log(regroupement.type);
                if (
                    regroupement.type !== 'commission scolaire' &&
                    regroupement.type !== 'regroupement des établissements privés'
                ) {
                    return null
                }
                return (
                    <option
                        key={index}
                        value={regroupement._id}
                        data-type={regroupement.type}
                        data-code={regroupement.code_regroupement}
                    >
                        {regroupement.type === 'commission scolaire'
                            ? regroupement.nom.replace(
                                /^(du\s|de\sla\s|des\s|de\s|de\sl'|)/,
                                'CSS $1',
                            )
                            : regroupement.nom}
                    </option>
                )
            })

        const isValid = this.Validate();

        return (
            <div className="form-activite">
                <div className="container">
                    {this.state.ecoleAjout && alertAjout}
                    <div className="row">
                        <div className="col-md-8 m-auto py-5">
                            <h1 className="display-4 text-center mt-20">Ajouter une école</h1>
                            <div className="col-md-12">
                                {this.state.etablissementAjout && alertAjout}
                            </div>
                            <form onSubmit={this.OnSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h3>Informations sur l'établissement</h3>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="code_organisme"
                                        name="code_organisme"
                                        onChange={this.SetCode}
                                        value={this.state.code_organisme}
                                        label="Code l'organisme"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.code_organisme}
                                    />
                                    {this.RenderAvertissement()}
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="nom"
                                        name="nom"
                                        onChange={this.SetTextField}
                                        value={this.state.nom}
                                        label="Nom"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.nom}
                                    />
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <label htmlFor="regroupement_select">
                                            Sélectionner un centre de services scolaire
                                        </label>
                                        <select
                                            className={classnames('form-control custom-select', {
                                                'is-invalid': errors.regroupement,
                                            })}
                                            id="regroupement_select"
                                            onChange={this.SetSelectField}
                                            name="regroupement"
                                            value={this.state.regroupement}
                                        >
                                            <option value="">Choisir</option>
                                            {RegroupementsOptions}
                                        </select>
                                        {errors && (
                                            <div className="invalid-feedback">
                                                {errors.regroupement}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-12">
                                        <label htmlFor="regroupement_select">
                                            Sélectionner le type d'école
                                        </label>
                                        <select
                                            className={classnames('form-control custom-select', {
                                                'is-invalid': errors.type_etablissement,
                                            })}
                                            id="type_etablissement_select"
                                            onChange={this.SetSelectField}
                                            name="type_etablissement"
                                            value={this.state.type_etablissement}
                                        >
                                            <option value="École">École</option>
                                            <option value="Collège privé">Collège privé</option>
                                            <option value="Installation">Installation</option>
                                            <option value="École gouvernementale">
                                                École gouvernementale
                                            </option>
                                        </select>
                                        {errors && (
                                            <div className="invalid-feedback">
                                                {errors.type_etablissement}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <h3>Niveaux d'enseignement</h3>
                                </div>
                                <div className="form-group row">
                                    <CheckboxInputGroup
                                        id="caract_niv_prescolaire"
                                        name="caract_niv_prescolaire"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_prescolaire}
                                        label="Préscolaire"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_prescolaire}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_niv_primaire"
                                        name="caract_niv_primaire"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_primaire}
                                        label="Primaire"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_primaire}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_niv_secondaire"
                                        name="caract_niv_secondaire"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_secondaire}
                                        label="Secondaire"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_secondaire}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_niv_professionnel"
                                        name="caract_niv_professionnel"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_professionnel}
                                        label="Professionnel"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_professionnel}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_niv_adulte"
                                        name="caract_niv_adulte"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_adulte}
                                        label="Adulte"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_adulte}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_niv_collegial"
                                        name="caract_niv_collegial"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_collegial}
                                        label="Collégial"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_collegial}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_niv_universitaire"
                                        name="caract_niv_universitaire"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_niv_universitaire}
                                        label="Universitaire"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_niv_universitaire}
                                    />
                                </div>

                                <hr />
                                <div className="row">
                                    <h3>Langues d'enseignement</h3>
                                </div>
                                <div className="form-group row">
                                    <CheckboxInputGroup
                                        id="caract_lang_francais"
                                        name="caract_lang_francais"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_lang_francais}
                                        label="Français"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_lang_francais}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_lang_anglais"
                                        name="caract_lang_anglais"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_lang_anglais}
                                        label="Anglais"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_lang_anglais}
                                    />
                                    <CheckboxInputGroup
                                        id="caract_lang_autre"
                                        name="caract_lang_autre"
                                        onChange={this.SetCheckBox}
                                        value={this.state.caract_lang_autre}
                                        label="Autre"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.caract_lang_autre}
                                    />

                                </div>

                                <hr />
                                <div className="row">
                                    <h3>Informations de contact</h3>
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="contact_adresse"
                                        name="contact_adresse"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_adresse}
                                        label="Adresse"
                                        type="text"
                                        addclass="col-md-12"
                                        error={errors.contact_adresse}
                                    />
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="contact_ville"
                                        name="contact_ville"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_ville}
                                        label="Ville"
                                        type="text"
                                        addclass="col-md-6"
                                        error={errors.contact_ville}
                                    />
                                    <TextFieldGroup
                                        id="contact_code_postal"
                                        name="contact_code_postal"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_code_postal}
                                        label="Code postal"
                                        type="text"
                                        addclass="col-md-3"
                                        error={errors.contact_code_postal}
                                    />
                                    <TextFieldGroup
                                        id="contact_province"
                                        name="contact_province"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_province}
                                        label="Province"
                                        type="text"
                                        addclass="col-md-3"
                                        error={errors.contact_province}
                                    />
                                </div>
                                <div className="form-group row">
                                    <TextFieldGroup
                                        id="contact_tel"
                                        name="contact_tel"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_tel}
                                        label="Téléphone"
                                        type="text"
                                        addclass="col-md-6"
                                        error={errors.contact}
                                    />
                                    <TextFieldGroup
                                        id="contact_courriel"
                                        name="contact_courriel"
                                        onChange={this.SetTextField}
                                        value={this.state.contact_courriel}
                                        label="Courriel"
                                        type="text"
                                        addclass="col-md-6"
                                        error={errors.contact}
                                    />
                                </div>
                                <hr />
                                <div className="form-group row justify-content-around">
                                    <input
                                        type="submit"
                                        className="btn btn-reseau btn-block mt-4 col-md-3"
                                        value="Enregistrer"
                                        onClick={this.Enregistrer}
                                        disabled={!isValid}
                                    />
                                    {!isValid &&
                                        <div className="col-md-12 text-center">
                                            <small>
                                                Des informations sont manquantes
                                            </small>
                                        </div>
                                    }
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    regroupements: state.regroupements,
    etablissements: state.etablissements,
    errors: state.errors,
})
export default connect(mapStateToProps, {
    RechercherRegroupementsParRegions,
    RechercherEcoleParCode,
    AjouterEtablissement,
    SetMessage,
    ClearErrors,
})(AjouterEcoleForm)
