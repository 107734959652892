import React, {Component, Fragment} from 'react';
import BarChart from './Template/BarChart';
import fileSaver from 'file-saver';
import canvasToBlob from 'canvas-toBlob';
export default class NbActivitesParProgramme extends Component {
  constructor (props) {
    super (props);
    this.state = {
      data: {
        labels: [],
        datasets: [
          {
            label: "Nb d'activités",
            data: [],
            backgroundColor: [],
            fill: false,
            pointRadius: 10,
            lineTension: 0.25,
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        layout: {
          padding: 10,
        },
        plugins: {
          datalabels: {
            color: '#FFF',
            anchor: 'center',
            align: 'end',
            clamp: false,
            backgroundColor: context => {
              return context.dataset.backgroundColor;
            },
            borderRadius: 25,
            clip: false,
            font: {
              size: 11,
              weight: 'bolder',
            },
            padding: 6,
            borderRadius: 25,
            borderWidth: 2,
            borderColor: '#FFF',
            textAlign: 'center',
            formatter: (value, context) => {
              return (
                value +
                '\n' +
                (value / this.state.nb_total * 100).toFixed (0) +
                '%'
              );
            },
          },
        },
      },
    };
  }

  componentDidMount = () => {
    this.updateData ();
  };
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.activites !== this.props.activites) {
      this.updateData ();
    }
  }
  saveImage = () => {
    const canvas = document.querySelector ('#programmes');
    canvas.toBlobHD (blob => {
      fileSaver.saveAs (blob, 'nbActivitesParProgrammes.png');
    });
  };
  updateData = () => {
    let labels = [];
    let values = [];

    // Loop through all activities
    let nb_total = 0;
    const data = {};
    this.props.activites.map ((activite, index) => {
      if (!data.hasOwnProperty (activite.programme.programme_nom)) {
        data[activite.programme.programme_nom] = 0;
      }
      nb_total += activite.nb_activites;
      data[activite.programme.programme_nom] += activite.nb_activites;
      return true;
    });

    Object.keys (data).forEach (key => {
      labels.push (key);
      values.push (data[key]);
    });

    //SET COLOR FOR EACH ELEMENTS
    let colourBackground = values.map ((data, index) => {
      let background = '';

      let colorIndex = index % 3;
      if (colorIndex === 0) {
        background = 'rgb(34, 108, 172)';
      } else if (colorIndex === 1) {
        background = 'rgb(255, 152, 42)';
      } else if (colorIndex === 2) {
        background = '#d6dbf4';
      } else {
        background = 'rgb(34, 108, 172)';
      }

      return background;
    });

    const dataToUpdate = this.state.data;

    dataToUpdate.labels = labels;
    dataToUpdate.datasets[0].data = values;
    dataToUpdate.datasets[0].backgroundColor = colourBackground;

    this.setState ({data: dataToUpdate, nb_total});
  };

  render () {
    return (
      <Fragment>
        <h4 className="text-center">Nombre d'activités par programme</h4>
        <h6 className="text-center">
          Nombre total d'activités: {this.props.nb_activites}
          <span className="ml-5 btn btn-reseau" onClick={this.saveImage}>
            <i className=" fas fa-camera-retro" />
          </span>
        </h6>
        <BarChart
          id="programmes"
          data={this.state.data}
          height={60}
          options={this.state.options}
        />
      </Fragment>
    );
  }
}
