import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutUser } from '../../../store/actions/authActions';

class Nav extends Component {
  constructor(props) {
    super(props);
    this.onLogout = this.onLogout.bind(this);
  }

  onLogout() {
    this.props.logoutUser();
  }
  render() {
    const isAuthenticated = this.props.auth.isAuthenticated;
    const isAdmin = this.props.auth.utilisateur.isAdmin;
    const nouveauCompte = this.props.auth.utilisateur.nouveauCompte;

    const authenticatedNav = (
      <Fragment>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="menuEntrees"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-clipboard-list" /> Activités
          </Link>
          <div className="dropdown-menu" aria-labelledby="menuEntrees">
            <Link className="dropdown-item" to="/liste-activites">
              Voir toutes les activités
            </Link>
            <Link className="dropdown-item" to="/rapports">
              Graphiques et tableaux
            </Link>
            <Link className="dropdown-item" to="/ajouter-activite">
              Ajouter une activité
            </Link>
            <Link className="dropdown-item" to="/exportation">
              Exportation
            </Link>
            <Link className="dropdown-item" to="/bilan-hq">
              Exportations pour le bilan Hydro-Québec (Envirovolt &amp; 00Watt)
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="menuEtablissements"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-building" /> Écoles et établissements
          </Link>
          <div className="dropdown-menu" aria-labelledby="menuEtablissements">
            <Link className="dropdown-item" to="/liste-ecoles-publiques">
              Liste des écoles publiques
            </Link>
            <Link className="dropdown-item" to="/liste-ecoles-privees">
              Liste des écoles privées
            </Link>
            <Link className="dropdown-item" to="/liste-bibliotheques">
              Liste des bibliothèques
            </Link>
            <Link className="dropdown-item" to="/liste-municipalites">
              Liste des municipalités
            </Link>
            <Link className="dropdown-item disabled" to="#">
              <del>Liste des autres établissements</del> (disponible bientôt)
            </Link>

            <Link className="dropdown-item" to="/ajouter-etablissement">
              Ajouter un établissement
            </Link>
            {isAdmin && <Link className="dropdown-item" to="/ajouter-ecole">
              Ajout d'une école (Admin seulement)
            </Link>}
          </div>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/instructions">
            <i className="fas fa-question-circle" /> Aide
          </Link>
        </li>
      </Fragment>
    );

    const adminNav = (
      <Fragment>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="menuProfil"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-address-card" /> Utilisateurs
          </Link>
          <div className="dropdown-menu" aria-labelledby="menuProfil">
            <Link className="dropdown-item" to="/liste-utilisateurs">
              Voir les utilisateurs
            </Link>
            <Link className="dropdown-item" to="/ajouter-utilisateur">
              Ajouter un utilisateur
            </Link>
          </div>
        </li>
        <li className="nav-item dropdown">
          <Link
            className="nav-link dropdown-toggle"
            to="#"
            id="menuProfil"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-address-card" /> Mises à jour
          </Link>
          <div className="dropdown-menu" aria-labelledby="menuProfil">
            <Link className="dropdown-item" to="/maj-imse">
              Mise à jour | Ministère de l'Éducation
            </Link>

          </div>
        </li>
      </Fragment>
    );

    return (
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        {/*BOUTON MOBILE*/}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#menuPrincipal"
          aria-controls="menuPrincipal"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* MENU PRINCIPAL*/}
        <div className="collapse navbar-collapse container" id="menuPrincipal">
          <ul className="navbar-nav mr-auto">
            {isAuthenticated && !nouveauCompte && authenticatedNav}
            <ul className="nav navbar-nav">
              {isAdmin && !nouveauCompte && adminNav}
            </ul>
          </ul>
          <div className="pull-right">
            <ul className="nav navbar-nav">
              {isAuthenticated
                ? <li className="nav-item">
                  <Link className="nav-link" to="#" onClick={this.onLogout}>
                    <i className="fas fa-cog" /> Déconnexion
                  </Link>
                </li>
                : <li className="nav-item">
                  <Link className="nav-link" to="/connexion">
                    Connexion
                  </Link>
                </li>}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(mapStateToProps, { logoutUser })(Nav);
